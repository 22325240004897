import { Button, Col, Form, InputNumber, Popconfirm, Row, Select, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { MdLocalHotel } from 'react-icons/md';
import { HospedagemQuartoTipoEntity } from '../../../../entities/HospedagemQuartoTipoEntity';
import FormProps from '../../../../interfaces/FormProps';
import { HospedagemQuartoCommand } from '../../../../interfaces/hospedagem-quarto-command';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';



const HospedagemQuartoInserirForm: React.FC<FormProps> = ({
    data, onSuccess, onClose
 }) => {

    const app = useApp();
    const { Option, OptGroup } = Select;
    const [quartos, setQuartos] = useState<HospedagemQuartoTipoEntity[]>([]);
    const [quartosTbl, setQuartosTbl] = useState<HospedagemQuartoCommand[]>([]);
    const [formQuarto] = useForm();

    const TabelaQuartos: ColumnsType<HospedagemQuartoCommand> = [				
		{
			title:"Nº de Quartos",
			dataIndex: "quantidade"
		},
		{
			title:"Tipo de Quarto",
			dataIndex: ["hospedagemQuartoTipo", "nome"]
		},		
		{
			render: ((quarto: HospedagemQuartoCommand, record, index) => (
				<>
					<Popconfirm placement='topLeft'
						title={<span>Você confirma a EXCLUSÃO da quarto da lista<br /><strong>{quarto.hospedagemQuartoTipo.nome}</strong></span>}
						onConfirm={() => removerQuarto(index)}>
						<Button size='small' type='primary' danger className='button-icon'><FaTrash /></Button>
					</Popconfirm>					
				</>
			))
		}
	];

    const adiconaQuarto = useCallback((formData: any) => {
        let quarto: HospedagemQuartoTipoEntity | undefined = quartos.find((item: HospedagemQuartoTipoEntity) => {
            if (item.id === formData.hospedagemQuartoTipoId) {
                return true;
            }
            return false;
        })

        let novo: HospedagemQuartoCommand = {
            quantidade: formData.qtdeQuartos,
            hospedagemQuartoTipo: quarto
        };

        setQuartosTbl([...quartosTbl, novo]);
        if (onSuccess) {
            onSuccess([...quartosTbl, novo]);
        }
    }, [quartos, quartosTbl, onSuccess]);

    const removerQuarto = useCallback((item: number) => {
        setQuartosTbl((quartosTbl) => quartosTbl.filter((_,index) => index !== item));
        if (onSuccess) {
            onSuccess(quartosTbl.filter((_,index) => index !== item));
        }
    }, [onSuccess, quartosTbl])

    const carregarQuartoTipo = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `hospedagem/quarto/tipo`);
		if (result?.code === 200) {
            if (result.dados.length !== 0)
			    setQuartos(result.dados);
		}
	}, [app]);

    const submitQuarto = useCallback(() => {
        formQuarto.submit();
    }, [formQuarto]);

    useEffect(() => {        
		carregarQuartoTipo();                                   
	}, [carregarQuartoTipo]);

  return (
    <>
        <div>	
            <Form form={formQuarto} onFinish={adiconaQuarto}>
                <Row gutter={25}>
                    <Col md={8} >
                    <FormItem label="Tipo de Quarto" name="hospedagemQuartoTipoId"
                        rules={[{ required: true, message: "Selecione  o Tipo de Quarto"}]}>
                        <Select style={{ width: '100%' }}>
                            <OptGroup label="Tipo de Quarto">
                                {quartos.map((quarto: HospedagemQuartoTipoEntity) => (
                                    <Option key={quarto.id} value={quarto.id}>{quarto.nome}</Option>
                                ))}
                            </OptGroup>                            
                        </Select>
                    </FormItem> 
                    </Col>
                    <Col md={8} >
                    <FormItem label="Quatidade Quartos" name="qtdeQuartos" rules={[
                        {required: true, message: "Informe a quantidade de quartos"}  
                        ]}>
                        <InputNumber min={1} style={{width: '100%'}}/>                        
                    </FormItem>      
                    </Col>
                    <Col md={8} style={{textAlign: "right"}}>
                        <Button type='primary' className='button-primary' icon={<FaPlus />} onClick={submitQuarto}>Adicionar Quarto</Button>  
                    </Col>
                </Row>							
            </Form>            
        </div>
        <h2>
            <MdLocalHotel />
            Lista de Quartos da Hospedagem
        </h2>
        <Table size='small' pagination={{ pageSize: 100 }} columns={TabelaQuartos} dataSource={quartosTbl}></Table>
    </>        
  );
}

export default HospedagemQuartoInserirForm;