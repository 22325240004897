import React from 'react';
import { BsFillArrowUpRightSquareFill, BsFillArrowUpLeftSquareFill } from 'react-icons/bs';
import { Aereo } from '../entities/aereo';

interface AereoTipoProps {
	aereo: Aereo
}







const AereoTipo: React.FC<AereoTipoProps> = ({
	aereo
}) => {
	return (
		<>
			{aereo.aereoTipoId === 1 && (
				<div style={{ color: '#090', display: 'flex', alignItems: 'center' }}>
					<BsFillArrowUpRightSquareFill size={18} />
					<strong style={{ marginLeft: '7px' }}>IDA</strong>
				</div>
			)}
			
			{aereo.aereoTipoId === 2 && (
				<div style={{ color: '#900', display: 'flex', alignItems: 'center' }}>
					<BsFillArrowUpLeftSquareFill size={18} />
					<strong style={{ marginLeft: '7px' }}>VOLTA</strong>
				</div>
			)}
		</>
	);
}

export default AereoTipo;