import { Col, Input, Row } from "antd";
import React, { useCallback, useEffect } from "react";

import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
import TooltipForm from "../components/TooltipForm";
import { Result } from "../interfaces/Result";
import { useApp } from "../providers/AppProvider";

interface InputCepProps {
	value?: string;
	onChange?: (value: string) => void;
	disabled?: boolean;
	callbackCep?(dadosCep: any): Promise<any>;
}

const InputCep: React.FC<InputCepProps> = ({
	value,
	onChange,
	disabled = false,
	callbackCep,
}) => {
	const app = useApp();

	useEffect(() => {
		$(".maskCep").mask("00.000-000");
	}, []);

	const atualiza = useCallback(
		async (e: any) => {
			if (onChange) {
				onChange(e.target.value);

				if (callbackCep) {
					if (e.target.value.length === 10) {
						let result: Result | null = await app.ajaxApi(
							"GET",
							`consultaCep/${e.target.value}`
						);
						if (result?.code === 200) {
							let aux: any = {
								logradouro: result.dados.logradouro,
								complemento: result.dados.complemento,
								bairro: result.dados.bairro,
								cidade: result.dados.localidade,
								estado: result.dados.uf,
								pais: "BRASIL",
							};

							callbackCep(aux);
						}
					}
				}
			}
		},
		[onChange, app, callbackCep]
	);

	return (
		<>
			<Row gutter={1}>
				<Col md={20}>
					<Input disabled={disabled} className="maskCep" onChange={(e) => atualiza(e)} onBlur={(e) => atualiza(e)} value={value} onKeyUp={(e) => atualiza(e)} />
				</Col>
				<Col md={4} style={{ top: 7 }}>
					<TooltipForm descricao="Informe o CEP para buscar os dados na base do correio" />
				</Col>
			</Row>
		</>
	);
};

export default InputCep;
