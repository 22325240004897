import { Button, message, Modal, Popconfirm, Space, Tooltip } from 'antd';
import moment from 'moment';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaEdit, FaFileCsv, FaFilePdf, FaPlus, FaTrash, FaUsers } from 'react-icons/fa';
import { MdAirplaneTicket } from 'react-icons/md';
import AereoStatus from '../../../../components/AereoStatus';
import AereoTipo from '../../../../components/AereoTipo';
import { Aereo } from '../../../../entities/aereo';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import AereoEditar from '../../components/AereoEditar';
import AereoParticipantes from '../../components/AereoParticipantes';
import {CSVLink} from "react-csv"
import { useReactToPrint } from 'react-to-print';
import { isVisible } from '@testing-library/user-event/dist/utils';


const Aereos: React.FC = () => {

	const app = useApp();

	const [aereos, setAereos] = useState<Aereo[]>([]);
	const [aereoSelecionado, setAereoSelecionado] = useState<Aereo | null>(null);

	const [modalEditar, setModalEditar] = useState(false);
	const [modalAereoParticipantes, setModalAereoParticipante] = useState(false);
	const [aereoExp, setAereosExp] = useState<Aereo[]>([]);
	const [aereoExpParticipante, setAereosExpParticipante] = useState<Aereo[]>([]);
	const [desabilitaBotao, setDesabilitaBotao] = useState(true);
	const [desabilitaBotaoDetalhado, setDesabilitaBotaoDetalhado] = useState(true);

	const tabelaAereos: ColumnsType<Aereo> = [				
		{
			title: "Tipo",
			render: (aereo: Aereo) => (
				<AereoTipo aereo={aereo} />
			)
		},
		{
			title: "Companhia Aérea",
			render: (aereo: Aereo) => (
				<>
					{aereo.aereoCompanhia ? (
						<span>{aereo.aereoCompanhia.nome}</span>
					) : (
						<span>Não definida</span>
					)}
				</>
			)
		},
		{
			title: "Origem",
			render: (aereo: Aereo) => (
				<>
					{(aereo.origemCidade && aereo.origemAeroporto && aereo.destinoCidade && aereo.destinoAeroporto) ? (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<span style={{ display: 'flex', flexDirection: 'column', lineHeight: '16px', marginRight: '10px' }}>
								<strong>{aereo.origemAeroporto}</strong>
								<span style={{ fontSize: '12px' }}>{aereo.origemCidade}</span>
							</span>							
						</div>
					) : (
						<i>Não definido</i>
					)}					
				</>				
			)
		},
		{
			title: "Destino",
			render: (aereo: Aereo) => (
				<>
					{(aereo.origemCidade && aereo.origemAeroporto && aereo.destinoCidade && aereo.destinoAeroporto) ? (
						<div style={{ display: 'flex', alignItems: 'center' }}>							
							<span style={{ display: 'flex', flexDirection: 'column', lineHeight: '16px', marginLeft: '10px' }}>
								<strong>{aereo.destinoAeroporto}</strong>
								<span style={{ fontSize: '12px' }}>{aereo.destinoCidade}</span>
							</span>
						</div>
					) : (
						<i>Não definido</i>
					)}					
				</>				
			)
		},
		{
			title: "Nº do Voo",
			render: (aereo: Aereo) => (
				<>
					{aereo.numVoo ? (
						<span style={{ letterSpacing: '1px', fontWeight: 700, color: '#00F' }}>{aereo.numVoo}</span>
					) : (
						<i style={{ color: '#CCC' }}>Não disponível</i>
					)}
				</>
			)
		},
		{
			title: "Data de Embarque",
			render: (aereo: Aereo) => (
				<>
					{aereo.dataHoraEmbarque ? (
						<>{moment(aereo.dataHoraEmbarque).format("DD/MM/YYYY - HH:mm")}</>
					) : (
						<i style={{ color: '#CCC' }}>Não disponível</i>
					)}
				</>
			)
		},
		{
			title: "Lugares",
			render: (aereo: Aereo) => (
				<>
					<p style={{ fontSize: '11px', margin: 0, lineHeight: '13px' }}>Total: <strong>{aereo.lugares}</strong></p>
					<p style={{ fontSize: '11px', margin: 0, lineHeight: '13px' }}>
						Ocupados: 
						<strong style={{ color: '#00F' }}> {aereo.lugares - aereo.lugaresDisponiveis}</strong>
					</p>				
					<p style={{ fontSize: '11px', margin: 0, lineHeight: '13px' }}>
						Disponível: 
						{aereo.lugaresDisponiveis === 0 && (
							<strong style={{ color: '#F00' }}> {aereo.lugaresDisponiveis}</strong>
						)}
						{aereo.lugaresDisponiveis > 0 && (
							<strong style={{ color: '#090' }}> {aereo.lugaresDisponiveis}</strong>
						)}						
					</p>				
				</>
				
			)
		},		
		{
			title: "Status",
			render: (aereo: Aereo) => (
				<AereoStatus aereo={aereo} />
			)
		},
		{		
			render: (aereo: Aereo) => (
				<>
					<Button className='button-warning' icon={<FaUsers />} onClick={() => abreAereoParticipantes(aereo)}>Participantes</Button>
					<Button type='primary' icon={<FaEdit />} onClick={() => editarAereo(aereo)}>Editar</Button>

					<Popconfirm placement='topLeft'
						title={<span>Você confirma a EXCLUSÃO do aéreo Nº <br /><strong>{aereo.numVoo}</strong></span>}
						onConfirm={() => excluirAereo(aereo)}>
						<Button size='small' type='primary' danger className='button-icon'><FaTrash />Excluir</Button>
					</Popconfirm>
				</>
			)
		},
	];




	const carregaAereos = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", "aereo/listar");			
		if (result?.code === 200){			
			setDesabilitaBotao(false);
			setDesabilitaBotaoDetalhado(false);
			setAereos(result.dados);			
		}
		
	}, [app]);

	const excluirAereo = useCallback(async (aereo: Aereo) => {
		//console.log(aereo);
		let result: Result | null = await app.ajaxApi("DELETE", `aereo/cadastro/${aereo.id}`);
		if (result?.code === 200) {
			carregaAereos();			
		}
	}, [app, carregaAereos]);


	const novoAereo = useCallback(() => {
		setAereoSelecionado(null);
		setModalEditar(true);
	}, []);



	const editarAereo = useCallback((aereo: Aereo) => {
		setAereoSelecionado(aereo);
		setModalEditar(true);
	}, []);



	const fecharEditar = useCallback(() => {
		setModalEditar(false);
		setAereoSelecionado(null);
	}, []);



	const abreAereoParticipantes = useCallback((aereo: Aereo) => {
		setModalAereoParticipante(true);
		setAereoSelecionado(aereo)
	}, []);



	const FormatarData = useCallback((dataX: any)=> {
		var data = new Date(dataX),
			dia  = data.getDate().toString().padStart(2, '0'),
			mes  = (data.getMonth() + 1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
			ano  = data.getFullYear(),
			hora = data.getHours().toString(),
			min  = data.getMinutes().toString();
    	return dia + "/" + mes + "/" + ano + " " + hora + ":" + min;		
	}, [])

	// FUNÇÕES PARA EXPORTAR CSV E PDF     
    useMemo(async() => {
		//let result: Result | null = await app.ajaxApi("GET", "aereo/listar");			
		//let aereos: Aereo[] = result?.dados;						
		let lstAereosResumido: any = [];		
		//console.log(aereos);
		aereos?.forEach((a: Aereo) => {			
			let item: any = {};			
			item["Nº Voo"] = a.numVoo;
			item["Companhia"] = a.aereoCompanhia?.nome;
			item["Tipo Voo"] = a.aereoTipoNome;
			item["Status"] = a.aereoStatusNome;
			item["Origem"] = a.origemCidade && a.origemAeroporto ? `${a.origemCidade} - ${a.origemAeroporto}` : "Não definido'";
			item["Destino"] = a.destinoCidade && a.destinoAeroporto ? `${a.destinoCidade} - ${a.destinoAeroporto}`: "Não definido'";
			item["Nº Lugares"] = a.lugares;
			item["Nº Lugares Disp."] = a.lugaresDisponiveis;
			item["Status"] = a.aereoStatusNome;
			item["Data/Hora Embarque"] = a.dataHoraEmbarque ? FormatarData(a.dataHoraEmbarque) : "Não definido";
			item["Data/Hora Desembarque"] = a.dataHoraDesembarque ? FormatarData(a.dataHoraDesembarque) : "Não definido";			
			lstAereosResumido.push(item);			
		});		  

        setAereosExp(lstAereosResumido);

		//===========================================================AEREOS PARTICIPANTES===========================================================//
		let numTrechos: number = 0;
		let indiceInicial: number = 0;
		let result: Result | null = await app.ajaxApi("GET", "aereo/listarParticipanteAereos");			
		let aereosAux: Aereo[] = result?.dados;						
		let lstAereos: any = [];
		console.log(result);
		
		aereosAux?.forEach((x: any) => {
			if (numTrechos < x.participanteAereos.length) {
				numTrechos = x.participanteAereos.length;
			}
		});
		
		aereosAux?.forEach((a: any) => {
			let inicio: number = 1;						
			let item: any = {};						
			item["Nome Completo"] = a.nome;

			if (numTrechos !== a.participanteAereos.length) {
				indiceInicial = parseInt((numTrechos / a.participanteAereos.length).toString());				
								
				if(!(a.participanteAereos.length % indiceInicial === 0)) {										
					indiceInicial = indiceInicial - 1;
				}
				
				for (let count = 0; count < indiceInicial; count++) {						
					item[`Status Voo ${inicio}`] = "";				
					item[`Tipo Voo ${inicio}`] = "";
					item[`Data/Hora Embarque ${inicio}`] = "";
					item[`Data/Hora Desembarque ${inicio}`] = "";
					item[`Companhia ${inicio}`] = "";
					item[`Nº Voo ${inicio}`] = "";
					item[`Origem ${inicio}`] = "";
					item[`Destino ${inicio}`] = "";		
					inicio += 1;				
				}
			}  

			a.participanteAereos?.forEach((i: any) => {
				item[`Status Voo ${inicio}`] = i.participanteAereoStatusNome;				
				item[`Tipo Voo ${inicio}`] = i.aereo.aereoTipoNome			
				item[`Data/Hora Embarque ${inicio}`] = i.aereo.dataHoraEmbarque ? FormatarData(i.aereo.dataHoraEmbarque) : "Não definido";				
				item[`Data/Hora Desembarque ${inicio}`] = i.aereo.dataHoraDesembarque ? FormatarData(i.aereo.dataHoraDesembarque) : "Não definido";
				item[`Companhia ${inicio}`] = i.aereo.nomeCompanhia;
				item[`Nº Voo ${inicio}`] = i.aereo.numVoo;
				item[`Origem ${inicio}`] = i.aereo.origemCidade && i.aereo.origemAeroporto ? `${i.aereo.origemCidade} - ${i.aereo.origemAeroporto}` : "Não definido'";
				item[`Destino ${inicio}`] = i.aereo.destinoCidade && i.aereo.destinoAeroporto ? `${i.aereo.destinoCidade} - ${i.aereo.destinoAeroporto}`: "Não definido'";
				inicio += 1;
			});	
					
			lstAereos.push(item);			
			inicio = 1;
		});

		if (lstAereos.length > 0 && lstAereosResumido.length > 0) {
			setDesabilitaBotaoDetalhado(false);
		} else {
			setDesabilitaBotaoDetalhado(true);			
		}	

		if (lstAereosResumido.length > 0) {
			setDesabilitaBotao(false);
		} else {
			setDesabilitaBotao(true);			
		}	

		// console.log(lstAereos)
		// console.log(lstAereosResumido)
		// console.log(lstAereos.length)
		// console.log(lstAereosResumido.length)
		 
        setAereosExpParticipante(lstAereos);
    }, [FormatarData, aereos, app]);
    
	// const prepararCSV = useCallback(async () => {
	// 	let numTrechos: number = 0;
	// 	let indiceInicial: number = 0;
	// 	let result: Result | null = await app.ajaxApi("GET", "aereo/listarParticipanteAereos");			
	// 	let aereos: Aereo[] = result?.dados;						
	// 	let lstAereos: any = [];
		
	// 	aereos?.forEach((x: any) => {
	// 		if (numTrechos < x.participanteAereos.length) {
	// 			numTrechos = x.participanteAereos.length;
	// 		}
	// 	});
		
	// 	//console.log(result?.dados);
	// 	aereos?.forEach((a: any) => {
	// 		let inicio: number = 1;						
	// 		let item: any = {};						
	// 		item["Nome Completo"] = a.nome;

	// 		if (numTrechos !== a.participanteAereos.length) {
	// 			indiceInicial = parseInt((numTrechos / a.participanteAereos.length).toString());				
								
	// 			if(!(a.participanteAereos.length % indiceInicial === 0)) {										
	// 				indiceInicial = indiceInicial - 1;
	// 			}
				
	// 			for (let count = 0; count < indiceInicial; count++) {						
	// 				item[`Status Voo ${inicio}`] = "";				
	// 				item[`Tipo Voo ${inicio}`] = "";
	// 				item[`Data/Hora Embarque ${inicio}`] = "";
	// 				item[`Data/Hora Desembarque ${inicio}`] = "";
	// 				item[`Companhia ${inicio}`] = "";
	// 				item[`Nº Voo ${inicio}`] = "";
	// 				item[`Origem ${inicio}`] = "";
	// 				item[`Destino ${inicio}`] = "";		
	// 				inicio += 1;				
	// 			}
	// 		}  

	// 		a.participanteAereos?.forEach((i: any) => {
	// 			item[`Status Voo ${inicio}`] = i.participanteAereoStatusNome;				
	// 			item[`Tipo Voo ${inicio}`] = i.aereo.aereoTipoNome			
	// 			item[`Data/Hora Embarque ${inicio}`] = i.aereo.dataHoraEmbarque ? FormatarData(i.aereo.dataHoraEmbarque) : "Não definido";				
	// 			item[`Data/Hora Desembarque ${inicio}`] = i.aereo.dataHoraDesembarque ? FormatarData(i.aereo.dataHoraDesembarque) : "Não definido";
	// 			item[`Companhia ${inicio}`] = i.aereo.nomeCompanhia;
	// 			item[`Nº Voo ${inicio}`] = i.aereo.numVoo;
	// 			item[`Origem ${inicio}`] = i.aereo.origemCidade && i.aereo.origemAeroporto ? `${i.aereo.origemCidade} - ${i.aereo.origemAeroporto}` : "Não definido'";
	// 			item[`Destino ${inicio}`] = i.aereo.destinoCidade && i.aereo.destinoAeroporto ? `${i.aereo.destinoCidade} - ${i.aereo.destinoAeroporto}`: "Não definido'";
	// 			inicio += 1;
	// 		});	
					
	// 		lstAereos.push(item);			
	// 		inicio = 1;
	// 	});

	// 	if (lstAereos.length > 0) {
	// 		setDesabilitaBotao(false);
	// 	} else {
	// 		setDesabilitaBotao(true);			
	// 	}	

    //     setAereosExpParticipante(lstAereos);
	// 	//console.log(lstAereos);
    // }, [FormatarData, app]);


    const componentRef = useRef<any>();    
    const componentRodape = useRef<any>();    

    const handlePrint = useReactToPrint({    
        content: () => componentRef.current        
    });

    const preparaPDF = useCallback(() => {
        componentRodape.current(isVisible);
        handlePrint();
    }, [handlePrint]);
    // FIM FUNÇÕES

	const carregarDados = useCallback(() =>{
		carregaAereos();			
	},[carregaAereos]);

	const fecharAereoParticipantes = useCallback(() => {
		setModalAereoParticipante(false);
		carregarDados();
	}, [carregarDados]);

	// const RemoverTooltip = useCallback(() => {
	// 	setDesabilitaBotao(false);
	// }, [])

	useEffect(() => {
		//if (aereos.length === 0) {
			carregarDados();
			// carregaAereos();
			//prepararCSV();
			// prepararCSVResumido();			
		//}
	}, [carregarDados]);

	return (
		<>
			<h1>
				<MdAirplaneTicket />
				Aéreos
			</h1>

			<div className="submenu">
				<Button type='primary' className='button-success' onClick={novoAereo}>
					<FaPlus />
					Novo Voo
				</Button>

				<div style={{margin: '0 15px'}}>
					<Tooltip placement="topLeft" open={desabilitaBotao} title={"Habilita quando houver aéreos cadastrados. O CSV por participante habilita/exibe quando houver participantes cadastrados e estejam incluídos nos aéreos"} zIndex={0}>            						
						<Space style={{float:"right"}}>     
							<div style={{margin: '0 15px'}}>
								<Button type="primary" disabled={desabilitaBotao}>                        
									<CSVLink separator=";" filename={"AéreosResumido.csv"} data={aereoExp} className="btn btn-primary" onClick={()=>{ message.success("Baixando arquivo")}}>
										<FaFileCsv/>CSV Aéreo
									</CSVLink>                                 
								</Button>								
							</div>
						</Space>
					</Tooltip>
					<Space style={{float:"right"}}>     				
						<Button type="primary" disabled={desabilitaBotaoDetalhado}>                        
							<CSVLink separator=";" filename={"AéreosParticipante.csv"} data={aereoExpParticipante} className="btn btn-primary" onClick={()=>{ message.success("Baixando arquivo")}}>
								<FaFileCsv/>CSV Aéreo Participante
							</CSVLink>                                 
						</Button>						
					</Space>
					{/* <Button onClick={handlePrint} type="primary" danger><FaFilePdf /> Exportar PDF </Button> */}
				</div>
			</div>
			
			<div style={{width:"100%"}} >
				<div ref={componentRef}>
					{
						aereoExp &&						
						<Table size='small' columns={tabelaAereos} dataSource={aereos} defaultExpandAllRows={true} pagination={{pageSize: 100}} />
					}
				</div>
			</div>
			


			<Modal width={1100} closable maskClosable={false} footer={false} destroyOnClose open={modalEditar} onCancel={fecharEditar}>
				<AereoEditar onClose={fecharEditar} aereo={aereoSelecionado} onSuccess={carregarDados} />				
			</Modal>


			<Modal width={1100} closable maskClosable={false} footer={false} destroyOnClose open={modalAereoParticipantes} onCancel={fecharAereoParticipantes}>
				<AereoParticipantes aereo={aereoSelecionado} onClose={fecharAereoParticipantes} />				
			</Modal>
		</>
	);
}

export default Aereos;