import { Button, Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Password from 'antd/lib/input/Password';
import React, { useCallback, useState } from 'react';
import { FaBan, FaCheck, FaPlus } from 'react-icons/fa';
import { Cadastro } from '../../../entities/cadastro';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';


interface CadastroAdminNovoProps {
	onSuccess(cadastro: Cadastro): void,
	onClose?(): void
}


const CadastroAdminNovo: React.FC<CadastroAdminNovoProps> = ({
	onSuccess,
	onClose
}) => {


	const app = useApp();
	const [loading, setLoading] = useState(false);


	const salvar = useCallback(async(formData: any) => {
		setLoading(true);

		let dados = {
			...formData,
			admin: true
		};

		let result: Result | null = await app.ajaxApi("post", "cadastro", dados);
		if (result?.code === 200) {
			onSuccess(result.dados);

			if (onClose)
				onClose();
		}

		setLoading(false);
	}, [onSuccess, onClose, app]);



	return (
		<>
			<h2>
				<FaPlus />
				Novo Usuário do Sistema
			</h2>

			<Form layout='vertical' onFinish={salvar}>
				<FormItem label="Nome" name="nome" rules={[
					{ required: true, message: "Informe o nome" }
				]}>
					<Input />
				</FormItem>

				<FormItem label="E-mail" name="email" rules={[
					{ required: true, message: "Informe o e-mail" }
				]}>
					<Input />
				</FormItem>

				<hr />

				<FormItem label="Senha" name="senha" rules={[
					{ required: true, message: "Informe a senha" }
				]}>
					<Password />
				</FormItem>

				<FormItem label="Confirme a Senha" name="senhaConfirm" rules={[
					{ required: true, message: "Confirme a senha" }
				]}>
					<Password />
				</FormItem>

				<div className="acoes">
					{onClose && (
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}

					<Button type='primary' className='button-success' icon={<FaCheck />} htmlType='submit' loading={loading}>Salvar</Button>				
				</div>
			</Form>
		</>
	);
}

export default CadastroAdminNovo;