import { Button, Modal, Popconfirm } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { AdminCategoria } from '../../../entities/admin-categoria';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import AdminCategoriaEditar from './AdminCategoriaEditar';


const AdminCategoriaGerenciar: React.FC = () => {

	const app = useApp();

	const [categorias, setCategorias] = useState<AdminCategoria[]>([]);
	const [categoriaSelecionada, setCategoriaSelecionada] = useState<AdminCategoria | null>(null);
	const [modalEditar, setModalEditar] = useState(false);

	const tabela: ColumnsType<AdminCategoria> = [
		{
			title: "Nome",
			dataIndex: "nome"
		},
		{
			render: ((categoria: AdminCategoria) => (
				<>
					<Button type='primary' onClick={() => editarCategoria(categoria)}>
						<FaEdit />
						Editar
					</Button>

					<Popconfirm title={<span>Você confirma a exclusão da categoria: <strong>{categoria.nome}</strong>?</span>} onConfirm={() => excluir(categoria)}>
						<Button type='primary' danger className='button-icon'>
							<FaTrash />
						</Button>
					</Popconfirm>
				</>
			))
		}
	];


	const carregaCategorias = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `admin/categoria/listar`);
		if (result?.code === 200)
			setCategorias(result.dados);
	}, [app]);


	const novaCategoria = useCallback(() => {
		setModalEditar(true);
		setCategoriaSelecionada(null);
	}, []);


	const editarCategoria = useCallback((adminCategoria: AdminCategoria) => {
		setModalEditar(true);
		setCategoriaSelecionada(adminCategoria);
	}, []);


	const excluir = useCallback(async (adminCategoria: AdminCategoria) => {
		let result: Result | null = await app.ajaxApi("DELETE", `admin/categoria/excluir/${adminCategoria.id}`);
		if (result?.code === 200)
			carregaCategorias();
	}, [carregaCategorias, app]);



	useEffect(() => {
		carregaCategorias();
	}, [carregaCategorias]);


	return (
		<>
			<h2>Categorias de Usuário</h2>

			<div className="submenu" style={{ marginBottom: '15px' }}>
				<Button className='button-success' type='primary' onClick={novaCategoria}>
					<FaPlus />
					Nova Categoria
				</Button>
			</div>

			<Table size='small' columns={tabela} dataSource={categorias} />		

			<Modal maskClosable={false} footer={false} destroyOnClose closable open={modalEditar} onCancel={() => setModalEditar(false)}>
				<AdminCategoriaEditar adminCategoria={categoriaSelecionada} onSuccess={carregaCategorias} onClose={() => setModalEditar(false)} />
			</Modal>
		</>
	);
}

export default AdminCategoriaGerenciar;