import { Button, Checkbox, Form, Modal, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useCallback, useEffect, useState } from 'react';
import { FaCheck, FaLock } from 'react-icons/fa';
import { Admin } from '../../../entities/admin';
import { AdminAdminCategoria } from '../../../entities/admin-admin-categoria';
import { AdminCategoria } from '../../../entities/admin-categoria';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import AdminPermissaoAcessoEditar from './AdminPermissaoAcessoEditar';


interface AdminEditarProps {
	admin: Admin | null,
	onSuccess?(admin: Admin): void,
	onClose?(): void
}


const AdminEditar: React.FC<AdminEditarProps> = ({
	admin,
	onSuccess,
	onClose
}) => {
	
	const app = useApp();
	
	const [formAdmin] = useForm();
	const [adminCategoriasCheck, setAdminCategoriasCheck] = useState<any[]>([]);
	const [loadingAdmin, setLoadingAdmin] = useState(false);

	const [modalPermissoes, setModalPermissoes] = useState(false);


	const carregaAdminCategoria = useCallback(async () => {
		let listCategorias: any[] = [];
		let result: Result | null = await app.ajaxApi("GET", "admin/categoria/listar");
		if (result?.code === 200) {
			let categorias: AdminCategoria[] = result.dados;
			categorias.forEach((categoria: AdminCategoria) => {
				listCategorias.push({
					label: categoria.nome,
					value: categoria.id,
					key: categoria.id
				});
			});

			setAdminCategoriasCheck(listCategorias);
		}
	}, [app]);



	const salvarAdmin = useCallback(async (formData: any) => {
		setLoadingAdmin(true);

		let result: Result | null = await app.ajaxApi("PUT", `admin/${admin?.id}`, formData);
		if (result?.code === 200) {			
			notification.success({
				message: "Dados do usuário do sistema atualizados",
				placement: 'bottomRight'
			});

			if (onSuccess)
				onSuccess(result.dados);

			if (onClose)
				onClose();
		}
			

		setLoadingAdmin(false);
	}, [app, admin, onSuccess, onClose]);



	const atualizar = useCallback(() => {
		setModalPermissoes(false);

		if (onSuccess && admin) {
			onSuccess(admin);			
		}

	}, [onSuccess, admin]);


	
	useEffect(() => {
		carregaAdminCategoria();
		if (admin) {
			let dadosForm: any = {
				ativo: admin.ativo
			};

			let listCategorias: string[] = [];
			admin.adminCategorias.forEach((categoria: AdminAdminCategoria) => {
				listCategorias.push(categoria.adminCategoriaId);
			});
							
			dadosForm["adminCategorias"] = listCategorias;
			formAdmin.setFieldsValue(dadosForm);
		}
	}, [carregaAdminCategoria, admin, formAdmin]);




	
	return (
		<div className='box'>
			<h2>Usuário do Sistema</h2>

			{admin && (
				<>
					<Form form={formAdmin} layout='vertical' onFinish={salvarAdmin}>
						<FormItem name="ativo" valuePropName='checked'>
							<Checkbox>Ativo</Checkbox>
						</FormItem>

						<h3>Categoria</h3>
						<FormItem name="adminCategorias">
							<Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={adminCategoriasCheck} />
						</FormItem>

						<div className="acoes" style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Button type='ghost' className='button-success' onClick={() => setModalPermissoes(true)}>
								<FaLock />
								Permissões de Acesso
							</Button>

							<Button className='button-success' type='primary' icon={<FaCheck />} htmlType='submit' loading={loadingAdmin}>Salvar</Button>
						</div>
					</Form>

					<Modal width={1100} closable destroyOnClose maskClosable={false} footer={false} open={modalPermissoes} onCancel={atualizar}>
						<AdminPermissaoAcessoEditar admin={admin} onClose={atualizar} onSuccess={atualizar} />
					</Modal>
				</>
			)}
		</div>
	);
}

export default AdminEditar;