import { Alert, Button, Col, Form, Input, InputNumber, Popconfirm, Row, Table, Tabs } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table/Table";
import { parse } from "node:path/win32";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaArrowRight, FaBan, FaCheck, FaSearch, FaTrash } from "react-icons/fa";
import { Campo } from "../../../../entities/campo";
import { Participante } from "../../../../entities/participante";
import { ParticipanteCampo } from "../../../../entities/participante-campo";
import { ParticipanteCampoOpcao } from "../../../../entities/participante-campo-opcao";
import FormProps from "../../../../interfaces/FormProps";
import { Result } from "../../../../interfaces/Result";
import { useApp } from "../../../../providers/AppProvider";

let timeout: any = null;

const HospedagemParticipante: React.FC<FormProps> = ({
	data,
	onSuccess,
	onClose,
}) => {

    const app = useApp();
    const [form] = useForm();
	const { TabPane } = Tabs;
    const [tab, setTab] = useState<string>("1");
    const [loading, setLoading] = useState(false);
    const [tabelaParticipantes, setTabelaParticipantes] = useState<any>();
	const [tabelaParticipantesQuarto, setTabelaParticipantesQuarto] = useState<any>();
    const [participantes, setParticipantes] = useState<any>();
	const [participantesInclusos, setParticipantesInclusos] = useState<any>();
	const [qtdParticipantes, setQtdParticipantes] = useState<number>(0);

    const [busca, setBusca] = useState<string>("");	
	const [numCampos, setNumCampos] = useState<number>(0);
    //const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {		
	// 	setSelectedRowKeys(newSelectedRowKeys);
	// };

	// const rowSelection = {
	// 	selectedRowKeys,
	// 	onChange: onSelectChange,
	// };

    // ********** Carrega os todos os participantes e os presentes no quarto
	const carregaParticipantes = useCallback(async () => {

		// ********** PARTICIPANTES JÁ INCLUSOS NO QUARTO
		let inclusos: Participante[];
		
		let resultInclusos: Result | null = await app.ajaxApi("GET", `hospedagem/quartoParticipante/${data?.id}/participantes`);
		inclusos = resultInclusos?.dados;	
		setQtdParticipantes(inclusos.length);	

		let dadosTabelaInclusos: any[] = [];
		inclusos.forEach((p: any) => {
			let item: any = {};
			
			item["key"] = p.id;
			item["nome"] = p.cadastro.nome;
			item["email"] = p.cadastro.email;			
			p.participanteCampos.forEach((participanteCampo: ParticipanteCampo) => {
				if (participanteCampo.valor) {					
					item[participanteCampo.campoId] = participanteCampo.valor
				} else {
					let valor: string = "";
					participanteCampo.participanteCampoOpcoes.forEach((opcao: ParticipanteCampoOpcao, index: number) => {
						valor += opcao.campoOpcao.nome;
						if (participanteCampo.participanteCampoOpcoes.length > index + 1)
							valor += " | ";
					});
					item[participanteCampo.campoId] = valor;
				}
			});

			dadosTabelaInclusos.push(item);			
		});
		
		setParticipantesInclusos(dadosTabelaInclusos);


		let result: Result | null = await app.ajaxApi("POST", "participante/listar", { busca });
		let part: Participante[] = result?.dados;

		
		let dadosTabela: any[] = [];
		part.forEach((p: Participante) => {
			let item: any = {};
			
			item["key"] = p.id;
			item["nome"] = p.cadastro.nome;
			item["email"] = p.cadastro.email;
			item["hospedado"] = false;
			p.participanteCampos.forEach((participanteCampo: ParticipanteCampo) => {
				if (participanteCampo.valor) {					
					item[participanteCampo.campoId] = participanteCampo.valor
				} else {
					let valor: string = "";
					participanteCampo.participanteCampoOpcoes.forEach((opcao: ParticipanteCampoOpcao, index: number) => {
						valor += opcao.campoOpcao.nome;
						if (participanteCampo.participanteCampoOpcoes.length > index + 1)
							valor += " | ";
					});
					item[participanteCampo.campoId] = valor;
				}
			});
			

			if (inclusos) {				
				let rsIncluso: Participante | undefined = inclusos.find((itemRs: Participante) => {
					if (itemRs.id === p.id){						
						return true;
					}
					else
						return false;			
				});
				if (rsIncluso)
					item.hospedado = true;
				else
					item.hospedado = false;
				
				dadosTabela.push(item);					
			} else {														
			 	dadosTabela.push(item);
			}		
		});
		
		setParticipantes(dadosTabela);
		
	}, [app, data, busca]);

    
	const excluirQuartoParticipante = useCallback(async(participanteId: string) => {
		setLoading(true);
		let result: Result | null = await app.ajaxApi("DELETE", `hospedagem/quartoParticipante/${participanteId}/quarto/${data?.id}`);
		if (result?.code === 200)            
		{
			carregaParticipantes();				
			setTab("1");
			setLoading(false);
		}
		setLoading(false);

	}, [app, data, carregaParticipantes]); 

    
	const buscar = useCallback((busca: string) => {				
		if (busca.length >= 3) {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				setBusca(busca);				
			}, 250);	
		}
		if (busca.length === 0) {
			setBusca("");			
		}
	}, []);

	const salvar = useCallback(async (participante: any) => {
		setLoading(true);
		
			let dados = {				
				participanteId: participante,
				quartoId: data?.id			
			};

			let result: Result | null = await app.ajaxApi("POST", "hospedagem/quartoParticipante", dados, true);			
			if (result?.code === 200) {
				carregaParticipantes();				
			} 
			setLoading(false);			
		
	}, [app, data, carregaParticipantes]);


    // ********** Configura a estrutura das tabelas
	const configTabelas = useCallback(async () => {
		let resultCampos: Result | null = await app.ajaxApi("GET", 'campo/listar/participante');
	 	let campos: Campo[] = resultCampos?.dados;	
		
		// Configura a tabela de participantes
		let tabelaParticipantes: ColumnsType<any> = [
			{
				title: "Nome",
				dataIndex: "nome",
				fixed: 'left'
			},
			{
				title: "E-mail",
				dataIndex: "email"				
			}
			
		];

		let num = 0;
		campos.forEach((campo: Campo) => {
			if (campo.visivelAdmin) {
				num++;
				tabelaParticipantes.push({
					title: campo.nome,
					dataIndex: campo.id,					
				});
			}
		});

		tabelaParticipantes.push({			
			render: ((item: any) => (
				<>				
					{item.hospedado === false ? (
						<Popconfirm placement='topLeft'
							title={<span>Você confirma a INCLUSÂO da participante no quarto<br /></span>} onConfirm={() => salvar(item.key)}>
							<Button size='small' type='primary' className='button-icon'>
								Hospedar
								<FaArrowRight style={{ marginLeft: '10px' }} />
							</Button>
						</Popconfirm>						
					) : (
						<i className="vazio">Hospedado</i>
					)}
				</>
			)),
			fixed: 'right',
			width: 100			
		});
		setTabelaParticipantes(tabelaParticipantes);
		setNumCampos(num);


		// Configura a tabela de participantes - Inclusos no quarto
		let tabelaParticipantesQuarto: ColumnsType<any> = [
			{
				title: "Nome",
				dataIndex: "nome",
				fixed: 'left'
			},
			{
				title: "E-mail",
				dataIndex: "email"				
			}
		];
		campos.forEach((campo: Campo) => {
			if (campo.visivelAdmin) {
				tabelaParticipantesQuarto.push({
					title: campo.nome,
					dataIndex: campo.id,						
				});
			}
		});
		
		tabelaParticipantesQuarto.push({
			render: ((item: any) => (								
				<Popconfirm placement='topLeft' title={<span>Você confirma a remoção do participante <strong>{item.nome}</strong> deste quarto?</span>} onConfirm={() => excluirQuartoParticipante(item.key)}>
					<Button danger type='primary' className='button-icon'>
						<FaTrash />							
					</Button>
				</Popconfirm>
			)),
			fixed: 'right',
			width: 40
		})
		setTabelaParticipantesQuarto(tabelaParticipantesQuarto);

	}, [app, excluirQuartoParticipante, salvar]);
	
	const loadPage = useCallback(async() => {
		await configTabelas();		
		await carregaParticipantes();

	}, [carregaParticipantes, configTabelas]);

	useEffect(() => {		
		if (data) 
        {            
			loadPage();			
            form.setFieldsValue(data.hospedagemQuartoTipo);  			
        }        
	}, [loadPage, data, form]);

	return (
		<>
			<h2>
                Hospedagem de Participante
            </h2>

            <div style={{ marginBottom: '25px' }}>
				<Form form={form} layout="vertical">
					<Row gutter={25}>
						<Col md={8}>
							<FormItem label="Nº do Quarto" name="numero">
								<InputNumber style={{width: '100%'}} disabled  readOnly={true}/>
							</FormItem>
						</Col>
						<Col md={8}>
							{/* numPessoas */}
							<FormItem label="Nº de Pessoas" name="numPessoas"> 
								<InputNumber style={{width: '100%'}} disabled readOnly={true}/>
							</FormItem>
						</Col>
						<Col md={8}>
							{/* nome */}
							<FormItem label="Tipo do Quarto" name="nome">
								<InputNumber style={{width: '100%'}} disabled readOnly={true}/>
							</FormItem>
						</Col>
					</Row>
				</Form>

				{qtdParticipantes == data.hospedagemQuartoTipo.numPessoas && (					
					<Alert style={{ marginTop: '25px' }} type='warning' description="Este Quarto não possui mais lugares disponíveis" showIcon />
				)}
			</div>

			<Tabs activeKey={tab} size='large' onChange={(key) => setTab(key)}>
				<TabPane tab={<span><FaCheck /> Hospedados neste quarto</span>} key="1">
					<Table size='small' columns={tabelaParticipantesQuarto} dataSource={participantesInclusos} scroll={{ x: (numCampos * 400) }} loading={loading} />
				</TabPane>

				<TabPane tab={<span><FaSearch /> Procurar participantes</span>} key="2">
					<Input style={{ marginBottom: '5px' }} size='large' prefix={<FaSearch />} placeholder='Pesquisar participante' onChange={(e) => buscar(e.target.value)} />
					<Table  size='small' columns={tabelaParticipantes} dataSource={participantes} scroll={{ x: (numCampos * 400) }} loading={loading} 
							rowClassName={(item: any) => {				
								switch (item.hospedado) {
									case true:								
										return "operacao-inserir";
									default:
										return "operacao-default";
								}				
							}} />					
					<div className='legenda-table'>
						<div className='item'>
							<div className="legenda operacao-inserir"></div>
							<p>Participante já hospedado</p>
						</div>
						<div className='item'>
							<div className="legenda operacao-default"></div>
							<p>Disponível</p>
						</div>
					</div>
				</TabPane>
			</Tabs>

			{onClose && (
				<div className="acoes">
					<Button icon={<FaBan />} type='primary' className='button-default' onClick={onClose}>Fechar</Button>
				</div>
			)}
		</>
	);
};

export default HospedagemParticipante;
