import { Button, Modal, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaList, FaPlus, FaUserShield } from 'react-icons/fa';
import { Cadastro } from '../../../../entities/cadastro';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import CadastroAdminNovo from '../../components/CadastroAdminNovo';
import { Admin } from '../../../../entities/admin';
import AdminCategoriaGerenciar from '../../components/AdminCategoriaGerenciar';


const CadastrosAdmin: React.FC = () => {
	
	const app = useApp();
	const [modalVisible, setModalVisible] = useState(false);	
	const [modalGerenciarCategoria, setModalGerenciarCategoria] = useState(false);
	const [cadastros, setCadastros] = useState<Admin[]>([]);


	// ***** Estrutura da tabela
	const tableCadastros: ColumnsType<Admin> = [		
		{
			title: "Nome",
			render: (admin: Admin) => (
				<span>{admin.cadastro.nome}</span>
			)
		},
		{
			title: "E-mail",
			render: (admin: Admin) => (
				<span>{admin.cadastro.email}</span>
			)
		},
		{
			title: "Último Acesso",
			render: (admin: Admin) => (
				<>
					{admin.cadastro.cadastroLogin?.ultimoAcesso ? (
						moment(admin.cadastro.cadastroLogin.ultimoAcesso).format("DD/MM/YYYY - HH:mm:ss")
					) : (
						<i style={{ color: '#AAA' }}>Nenhum acesso</i>
					)}
				</>
			)
		},
		{
			title: "Status",
			render: (admin: Admin) => (
				<>
					{admin?.ativo ? (
						<Tag color="green">Ativo</Tag>
					) : (
						<Tag color="red">Bloqueado</Tag>
					)}
				</>
			)
		},
		{
			title: "",
			render: (admin: Admin) => (
				<>
					<Button size='small' type='primary' onClick={() => app.toUrl(`/admin/cadastro/detalhes/${admin.cadastro.id}`)}>
						<FaEdit />
						Editar
					</Button>
				</>
			)
		}
	];


	// ***** Carrega cadastros
	const carregaCadastros = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("get", "admin");		
		if (result?.code === 200){
			console.log(result?.dados);
			setCadastros(result.dados);
		}
	}, [app]);


	// ***** Abre o modal para um novo cadastro
	const novoCadastro = useCallback(() => {		
			setModalVisible(true);					
	}, []);


	// ***** Função executada ao realizar um cadastro com sucesso
	const cadastroSuccess = useCallback((cadastro: Cadastro) => {
		setModalVisible(false);
		carregaCadastros();
	}, [carregaCadastros]);


	
	// ***** Inicializa página
	useEffect(() => {
		if (cadastros.length === 0) {
			carregaCadastros();
		}
	}, [cadastros, carregaCadastros]);



  return (
	<>
	  	<h1>
			<FaUserShield />
			Usuários do Sistema
		</h1>

		<div className='submenu'>
			<nav>
				<Button onClick={novoCadastro} className='button-success' type='primary' icon={<FaPlus />}>Novo Cadastro</Button>
			</nav>

			<nav>
				<Button onClick={() =>setModalGerenciarCategoria(true)} className='button-default' type='primary' icon={<FaList />}>Categorias de Usuário</Button>
			</nav>
		</div>

		{
			cadastros &&
			<Table size='small' columns={tableCadastros} dataSource={cadastros} />
		}

		<Modal maskClosable={false} destroyOnClose open={modalVisible} closable footer={false} onCancel={() => setModalVisible(false)}>			
			<CadastroAdminNovo onClose={() => setModalVisible(false)} onSuccess={cadastroSuccess} />
		</Modal>


		<Modal maskClosable={false} footer={false} destroyOnClose closable open={modalGerenciarCategoria} onCancel={() => setModalGerenciarCategoria(false)}>
			<AdminCategoriaGerenciar />
		</Modal>
	  </>
  );
}

export default CadastrosAdmin;