import { Button, Modal, Popconfirm, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineNotification } from 'react-icons/ai';
import { FaEye, FaPlus, FaTrash } from 'react-icons/fa';
import FollowUpStatus from './FollowUpStatus';
import { FollowUp } from '../../../../entities/follow-up';
import { useApp } from '../../../../providers/AppProvider';
import { Result } from '../../../../interfaces/Result';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { ImDownload2 } from 'react-icons/im';
import FollowUpForm from '../../forms/FollowUp/FollowUpForm';


const FollowUps: React.FC = () => {

	const app = useApp();
	const [modalStatus, setModalStatus] = useState(false);
	const [modalCadastro, setModalCadastro] = useState(false);
	const [followUp, setFollowUp] = useState<FollowUp | null>(null);
	const [followUps, setFollowUps] = useState<FollowUp[]>([]);

	const tabelaFollowUp: ColumnsType<FollowUp> = [
		{ title: "Título", dataIndex: "titulo" },
		{
			title: "Categoria",
			render: ((followUp: FollowUp) => (
				<>
					{followUp.adminCategoria ? (
						<Tag>{followUp.adminCategoria.nome}</Tag>
					) : (
						<i className='vazio'>Não informada</i>
					)}
				</>
			))
		},
		{
			title: "Responsável",
			render: ((followUp: FollowUp) => (
				<>
					{followUp.cadastroResponsavel ? (
						<strong>{followUp.cadastroResponsavel?.nome}</strong>
					) : (
						<i className='vazio'>Não definido</i>
					)}
				</>

			))
		},
		{
			title: "Status",
			render: ((followUp: FollowUp) => (
				<Tag color={followUp.followUpStatus?.cor}>{followUp.followUpStatus?.nome}</Tag>
			))
		},
		{
			title: "Previsão",
			render: (followUp: FollowUp) => (
				<>
					{followUp.dataPrevisao ? (
						<>
							{moment(followUp.dataPrevisao).format("DD/MM/YYYY")}
						</>) : (
						<i style={{ color: '#CCC' }}>Sem Previsão</i>
					)}
				</>
			)
		},
		{
			title: "Conclusão",
			render: (followUp: FollowUp) => (
				<>
					{followUp.dataConclusao ? (
						<>
							{moment(followUp.dataConclusao).format("DD/MM/YYYY")}
						</>) : (
						<i style={{ color: '#CCC' }}>Inconcluído</i>
					)}
				</>
			)
		},
		// {
		// 	render: (followUp: FollowUp) => (
		// 		<>
		// 			<Button size='small' type='primary' onClick={() => editarFollow(followUp)}>
		// 				<FaEdit />
		// 				Editar
		// 			</Button>
		// 			
		// 		</>
		// 	)
		// }
		{
			render: ((follow: FollowUp) => (
				<>
					{follow.followUpStatus.exibirInserir ? (
						<Button type='primary' icon={<ImDownload2 />}>Assumir</Button>
					) : (
						<Button onClick={() => app.toUrl(`/follow-up/detalhes/${follow.id}`)} icon={<FaEye />}>Visualizar</Button>
						// <Button icon={<FaEye />}>Visualizar</Button>
					)}

					<Popconfirm placement='topLeft'
						title={<span>Você confirma a EXCLUSÃO do follu-up<br /><strong>{follow.titulo}</strong></span>}
						onConfirm={() => excluirFollowup(follow)}>
						<Button size='small' type='primary' danger className='button-icon'><FaTrash /></Button>
					</Popconfirm>
				</>
			))
		}
	];


	const carregaFollowup = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `follow-up`);
		if (result?.code === 200) {
			setFollowUps(result.dados);
		}

		console.log(result?.dados);

	}, [app]);



	const excluirFollowup = useCallback(async (followUp: FollowUp) => {
		let result: Result | null = await app.ajaxApi("DELETE", `follow-up/${followUp.id}`);
		if (result?.code === 200) {
			carregaFollowup();
		}
	}, [app, carregaFollowup]);


	const novoFollow = useCallback(() => {
		setFollowUp(null);
		setModalCadastro(true);
	}, []);


	const editarFollow = useCallback((followUp: FollowUp) => {
		setFollowUp(followUp);
		setModalCadastro(true);
	}, []);


	useEffect(() => {
		carregaFollowup();
	}, [carregaFollowup]);

	return (
		<>
			<h1>
				<AiOutlineNotification />
				Follow Up
			</h1>

			<div className="submenu">
				<Button type='primary' className='button-success' onClick={novoFollow}><FaPlus />Novo</Button>
				<Button type='primary' className='button-default' onClick={() => setModalStatus(true)}><FaPlus />Gerenciar Status</Button>
			</div>

			<Table size='small' pagination={{ pageSize: 100 }} columns={tabelaFollowUp} dataSource={followUps}></Table>

			<Modal width={1000} open={modalStatus} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalStatus(false)}>
				<FollowUpStatus />
			</Modal> 

			<Modal width={1000} open={modalCadastro} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalCadastro(false)}>
				<FollowUpForm data={followUp} onClose={() => setModalCadastro(false)} onSuccess={carregaFollowup} />
			</Modal>
		</>
	);
}

export default FollowUps;