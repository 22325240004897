import { Col, Row } from "antd";
import { FaHome } from "react-icons/fa";
import ArquivoInfo from "../../../../components/ArquivoInfo";
import DashboardAereo from "../../components/dashboard/DashboardAereo";
import DashboardFollowUp from "../../components/dashboard/DashboardFollowUp";
import DashboardHospedagem from "../../components/dashboard/DashboardHospedagem";
import DashboardParticipante from "../../components/dashboard/DashboardParticipante";

// const Painel: React.FC = () => {

// 	const data = [
// 		{
// 		  type: '分类一',
// 		  value: 27,
// 		},
// 		{
// 		  type: '分类二',
// 		  value: 25,
// 		},
// 		{
// 		  type: '分类三',
// 		  value: 18,
// 		},
// 		{
// 		  type: '分类四',
// 		  value: 15,
// 		},
// 		{
// 		  type: '分类五',
// 		  value: 10,
// 		},
// 		{
// 		  type: '其他',
// 		  value: 5,
// 		},
// 	  ];
// 	  const config = {
// 		appendPadding: 10,
// 		data,
// 		angleField: 'value',
// 		colorField: 'type',
// 		radius: 0.9,
// 		label: {
// 		  type: 'inner',
// 		  offset: '-30%',
// 		  content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
// 		  style: {
// 			fontSize: 14,
// 			textAlign: 'center',
// 		  },
// 		},
// 		interactions: [
// 		  {
// 			type: 'element-active',
// 		  },
// 		],
// 	  };

// 	return (
// 		<>
// 			<h1>
// 				<FaHome />
// 				Painel de Controle
// 			</h1>

// 			<Row gutter={25}>
// 				<Col span={24} md={12}>
// 					<DashboardFollowUp />
// 				</Col>

// 				<Col span={24} md={12}>
// 					<ArquivoInfo />
// 				</Col>
// 			</Row>
// 			<Row gutter={25}>
// 				<Col span={24} md={12}>
// 					<Pie {...config} />
// 				</Col>
// 			</Row>
// 		</>
// 	);
// };

const Painel = () => {

	return (
		<>
			<h1>
				<FaHome />
				Painel de Controle
			</h1>
			<Row gutter={25} style={{marginBottom: "15px"}}>
				<Col span={24} md={8}>
					<DashboardFollowUp />
				</Col>

				<Col span={24} md={8}>
					<ArquivoInfo />
				</Col>

				<Col span={24} md={8}>					
					<DashboardParticipante />					
				</Col>	
			</Row>
			<Row gutter={25}>
				<Col span={24} md={12}>					
					<DashboardHospedagem />					
				</Col>
				<Col span={24} md={12}>					
					<DashboardAereo />					
				</Col>
			</Row>			
		</>
	);
};

export default Painel;
