import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { MdAppRegistration } from 'react-icons/md';
import { Log } from '../../../../entities/log';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';

import moment from 'moment';
import { Button, Modal } from 'antd';
import { FaSearch } from 'react-icons/fa';
import LogDetalhe from '../../components/LogDetalhe';



const Logs: React.FC = () => {

	const app = useApp();

	const [modalDetalhe, setModalDetalhe] = useState(false);
	const [logSelecionado, setLogSelecionado] = useState<Log | null>(null);

	const [pagina, setPagina] = useState<number>(0);
	const [registros, setRegistros] = useState<number>(100);

	const [logs, setLogs] = useState<Log[]>([]);



	const tabelaLogs: ColumnsType<Log> = [
		{
			title: "Nome",
			dataIndex: "cadastroNome"
		},
		{
			title: "Descrição",
			dataIndex: "descricao"
		},
		{
			title: "Data e Hora",
			render: ((log: Log) => (
				<>
					<span>{moment(log.created).format("DD/MM/YYYY - HH:mm:ss")}</span>
				</>
			))
		},
		{		
			render: ((log: Log) => (
				<>
					<Button type='primary' className='button-default' onClick={() => abreDetalhe(log)}>
						<FaSearch />
						Detalhes
					</Button>
				</>
			))
		}
	];



	const abreDetalhe = useCallback((log: Log) => {
		setModalDetalhe(true);
		setLogSelecionado(log);
	}, []);



	const carregaLogs = useCallback(async () => {
		let consulta = {
			pagina,
			numeroRegistros: registros
		};

		let result: Result | null = await app.ajaxApi("POST", "log/buscar", consulta);
		if (result?.code === 200)
			setLogs(result.dados);

	}, [app, pagina, registros]);


	useEffect(() => {
		carregaLogs();
	}, [carregaLogs]);


	return (
		<>
			<h1>
				<MdAppRegistration />
				Logs do Sistema
			</h1>

			<Table rowClassName={(log: Log) => {				
				switch (log.operacao) {
					case 2:
						return "operacao-inserir";
					
					case 3:
						return "operacao-alterar";
					
					case 4:
						return "operacao-excluir";
					
					default:
						return "operacao-info";
				}				
			}}
			size='small' columns={tabelaLogs} dataSource={logs} />

			<div className='legenda-table'>
				<div className='item'>
					<div className="legenda operacao-info"></div>
					<p>Informações de sistema</p>
				</div>

				<div className='item'>
					<div className="legenda operacao-inserir"></div>
					<p>Inserção de registro</p>
				</div>

				<div className='item'>
					<div className="legenda operacao-alterar"></div>
					<p>Alteração de registro</p>
				</div>

				<div className='item'>
					<div className="legenda operacao-excluir"></div>
					<p>Exclusão de registro</p>
				</div>
			</div>


			<Modal width={1100} closable maskClosable={false} destroyOnClose footer={false} open={modalDetalhe} onCancel={() => setModalDetalhe(false)}>
				<LogDetalhe id={logSelecionado?.id} />
			</Modal>
		</>
	);
}

export default Logs;