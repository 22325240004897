import { Button, Modal, Radio, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineNotification } from 'react-icons/ai';
import { ImDownload2 } from 'react-icons/im';
import { FaEye, FaPlus } from 'react-icons/fa';
import { FollowUp } from '../../../../entities/follow-up';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import FollowUpForm from '../../forms/FollowUp/FollowUpForm';
import FollowUpAssumirForm from '../../forms/FollowUp/FollowUpAssumirForm';

import moment from 'moment';

import './MeusFollowUps.css';

const MeusFollowUps: React.FC = () => {

	const app = useApp();	
	
	const [followUpsTotal, setFollowUpsTotal] = useState<FollowUp[]>([]);
	
	const [followUps, setFollowUps] = useState<FollowUp[]>([]);
	const [followUpsMeus, setFollowUpsMeus] = useState<FollowUp[]>([]);

	const [modalCadastro, setModalCadastro] = useState(false);
	const [followUp, setFollowUp] = useState<FollowUp | null>(null);

	const [filtroFollow, setFiltroFollow] = useState<"aberto" | "concluido">("aberto");
	const [filtroFollowMeus, setFiltroFollowMeus] = useState<"aberto" | "concluido">("aberto");

	const [modalAssumir, setModalAssumir] = useState(false);



	const tabelaFollowUps: ColumnsType<FollowUp> = [
		{
			title: "Título",
			dataIndex: "titulo"
		},
		{
			title: "Categoria",
			render: ((followUp: FollowUp) => (
				<>
					{followUp.adminCategoria ? (
						<Tag>{followUp.adminCategoria.nome}</Tag>
					) : (
						<i className='vazio'>Não informada</i>
					)}
				</>
			))
		},
		{
			title: "Responsável",
			render: ((followUp: FollowUp) => (
				<>
					{followUp.cadastroResponsavel ? (
						<strong>{followUp.cadastroResponsavel?.nome}</strong>
					) : (
						<i className='vazio'>Não definido</i>
					)}
				</>

			))
		},
		{
			title: "Status",
			render: ((followUp: FollowUp) => (
				<Tag color={followUp.followUpStatus?.cor}>{followUp.followUpStatus?.nome}</Tag>
			))
		},
		{
			title: "Previsão de Entrega",
			render: ((followUp: FollowUp) => (
				<>
					{!followUp.dataPrevisao && !followUp.dataConclusao && (
						<i className='vazio'>Não informada</i>
					)}

					{followUp.dataPrevisao && !followUp.dataConclusao && (
						<p style={{ margin: 0, lineHeight: '14px', fontSize: '13px', fontWeight: 700 }}>
							{moment(followUp.dataPrevisao).format("DD/MM/YYYY")}
							<i style={{ display: 'block', fontSize: '11px', fontWeight: 'normal' }}>{moment(followUp.dataPrevisao).fromNow()}</i>
						</p>
					)}

					{followUp.dataConclusao && (
						<p style={{ margin: 0, color: '#999', lineHeight: '14px', fontSize: '13px', fontWeight: 700 }}>
							Concluído em
							<i style={{ display: 'block', fontSize: '11px', fontWeight: 'normal' }}>{moment(followUp.dataConclusao).format("DD/MM/YYYY - HH:mm:ss")}</i>
						</p>
					)}
				</>
				
			))
		},
		{
			title: "Solicitante",
			render: ((followUp: FollowUp) => (
					<strong>{followUp.solicitante?.nome}</strong>
			))
		},
		{
			render: ((follow: FollowUp) => (
				<>
					{follow.followUpStatus.exibirInserir && follow.cadastroCreated !== app.auth?.id ? (
						<Button onClick={() => assumir(follow)} type="primary" icon={<ImDownload2 />}>Assumir</Button>
					) : (
						<Button onClick={() => app.toUrl(`/follow-up/detalhes/${follow.id}`)} icon={<FaEye />}>Visualizar</Button>
					)}
				</>
			))
		}
	];


	
	const carregaFollowUps = useCallback(async() => {
		let result: Result | null = await app.ajaxApi("get", "follow-up/usuario");		
		if (result) {
			let follows: FollowUp[] = [];
			result.dados.forEach((item: FollowUp) => {
				if (item.cadastroIdResponsavel == null || item.cadastroIdResponsavel === app.auth?.id || item.cadastroCreated === app.auth?.id)
					follows.push(item);
			});
			setFollowUpsTotal(follows);			
		}
	}, [app]);


	const novoFollow = useCallback(() => {
		setFollowUp(null);
		setModalCadastro(true);
	}, []);


	// ***** Lista - MINHAS TAREFAS
	useMemo(() => {		
		let follows: FollowUp[] = [];
		followUpsTotal.forEach((item: FollowUp) => {
			if (filtroFollow === "concluido") {
				if (item.cadastroCreated !== app.auth?.id && item.followUpStatus.concluir)
					follows.push(item);
			} else {
				if (item.cadastroCreated !== app.auth?.id && !item.followUpStatus.concluir)
					follows.push(item);
			}			
		});

		setFollowUps(follows);
	}, [followUpsTotal, app, filtroFollow]);



	// ***** Lista - MINHAS SOLICITAÇÕES
	useMemo(() => {
		let follows: FollowUp[] = [];
		followUpsTotal.forEach((item: FollowUp) => {
			if (filtroFollowMeus === "concluido") {
				if (item.cadastroCreated === app.auth?.id && item.followUpStatus.concluir)
					follows.push(item);
			} else {
				if (item.cadastroCreated === app.auth?.id && !item.followUpStatus.concluir)
					follows.push(item);
			}			
		});

		setFollowUpsMeus(follows);
	}, [followUpsTotal, app, filtroFollowMeus]);

	
	


	const assumir = useCallback((followUp: FollowUp) => {
		setModalAssumir(true);
		setFollowUp(followUp);
	}, []);


	const assumirSuccess = useCallback((dados: any) => {
		app.toUrl(`/follow-up/detalhes/${dados.id}`);
	}, [app]);





	useEffect(() => {
		carregaFollowUps();
	}, [carregaFollowUps]);


	return (
		<>
			<h1>
				<AiOutlineNotification />
				Follow Ups
			</h1>

			<div className="submenu">
				<div></div>
				<div>
					<Radio.Group size='small' defaultValue={filtroFollow} buttonStyle="solid" onChange={(e: any) => setFiltroFollow(e.target.value)}>
						<Radio.Button value="aberto">Em aberto</Radio.Button>
						<Radio.Button value="concluido">Concluídos</Radio.Button>
					</Radio.Group>	
				</div>	
			</div>

			<div style={{ minHeight: 230 }}>
				<Table className='table-follows' size='small' columns={tabelaFollowUps} dataSource={followUps} rowClassName={(follow: FollowUp) => {	
					if (follow.followUpStatus.exibirInserir)
						return "follow-novo";

					if (follow.followUpStatus.exibirAlterar) {
						
						if (app.notificationFollow?.destaque) {
							let destaque = app.notificationFollow.destaque.find((item: string) => {
								if (item === follow.id)
									return true;

								return false;
							});

							if (destaque)
								return "follow-destaque";
						}
					}					

					return "";
				}} />
			</div>

			<h1>
				<AiOutlineNotification />
				Minhas Solicitações
			</h1>

			<div className="submenu">
				<Button type='primary' className='button-success' onClick={novoFollow}><FaPlus />Nova Solicitação</Button>	

				<div>
					<Radio.Group size='small' defaultValue={filtroFollowMeus} buttonStyle="solid" onChange={(e: any) => setFiltroFollowMeus(e.target.value)}>
						<Radio.Button value="aberto">Em aberto</Radio.Button>
						<Radio.Button value="concluido">Concluídos</Radio.Button>
					</Radio.Group>	
				</div>			
			</div>
			
			<div style={{ minHeight: 230 }}>
				<Table className='table-follows' size='small' columns={tabelaFollowUps} dataSource={followUpsMeus} rowClassName={(follow: FollowUp) => {	
					if (follow.followUpStatus.exibirAlterar) {
						
						if (app.notificationFollow?.destaque) {
							let destaque = app.notificationFollow.destaque.find((item: string) => {
								if (item === follow.id)
									return true;

								return false;
							});

							if (destaque)
								return "follow-destaque";
						}
					}					

					return "";
				}} />
			</div>

			<Modal width={1000} open={modalCadastro} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalCadastro(false)}>
				<FollowUpForm data={followUp} onClose={() => setModalCadastro(false)} onSuccess={carregaFollowUps} />
			</Modal>

			<Modal width={450} open={modalAssumir} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalAssumir(false)}>
				<FollowUpAssumirForm onClose={() => setModalAssumir(false)} data={followUp} onSuccess={assumirSuccess} />
			</Modal>

			<div className='legenda-table'>
				<div className='item'>
					<div className="legenda follow-novo"></div>
					<p>Novo follow up</p>
				</div>

				<div className='item'>
					<div className="legenda follow-destaque"></div>
					<p>Follow up aguardando seu retorno</p>
				</div>
			</div>
		</>
	);
}

export default MeusFollowUps;