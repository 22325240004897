import axios from 'axios';
import rateLimit from 'axios-rate-limit';

export interface Http {
	success: boolean;
	message: string;
	data: object;
}

export const api = rateLimit(
	axios.create({				
		baseURL: process.env.REACT_APP_URL_API,
	}),
	{
		maxRequests: 2,
		perMilliseconds: 1000,
	},
);