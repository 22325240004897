import { Button, Input, message, Modal, Popconfirm, Space, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaEdit, FaFileCsv, FaFilePdf, FaPlus, FaSearch, FaTrash, FaUsers } from 'react-icons/fa';
import { MdAirplaneTicket, MdOutlineDashboardCustomize } from 'react-icons/md';
import { Participante } from '../../../../entities/participante';
import { ParticipanteCampo } from '../../../../entities/participante-campo';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import NovoParticipante from '../../components/NovoParticipante';
import ParticipanteAereo from '../../components/ParticipanteAereos';
import { Campo } from '../../../../entities/campo';
import { ParticipanteCampoOpcao } from '../../../../entities/participante-campo-opcao';
import ParticipanteExcluido from '../../components/ParticipanteExcluido';
import {CSVLink} from "react-csv"
import { useReactToPrint } from 'react-to-print';
import { isVisible } from '@testing-library/user-event/dist/utils';

let timeout: any = null;


const CadastrosParticipantes: React.FC = () => {

	const app = useApp();


	const [participantes, SetParticipantes] = useState<any[]>([]);
	const [participanteIdSelecionado, setParticipanteIdSelecionado] = useState<string | null>(null);

	const [modalEditar, setModalEditar] = useState(false);
	const [modalAereos, setModalAereos] = useState(false);
	const [modalLixeira, setModalLixeira] = useState(false);

	const [tabelaParticipantes, setTabelaParticipantes] = useState<ColumnsType<Participante>>([]);
	const [busca, setBusca] = useState<string>("");
	const [participantesExp, setParticipantesExp] = useState<Participante[]>([]);
	const [desabilitaBotao, setDesabilitaBotao] = useState(true);
	// const [lstCompleta, setLstCompletaExp] = useState<Participante[]>([]);







	const novoParticipante = useCallback(() => {
		setModalEditar(true);
		setParticipanteIdSelecionado(null);
	}, []);


	const editarParticipante = useCallback((id: string) => {
		setModalEditar(true);
		setParticipanteIdSelecionado(id);
	}, []);


	const gerenciarAereos = useCallback((id: string) => {
		setParticipanteIdSelecionado(id);
		setModalAereos(true)
	}, []);

	const fecharAereos = useCallback(() => {
		setParticipanteIdSelecionado(null);
		setModalAereos(false)
	}, []);

	const excluirLixeira = useCallback(() => {
		setModalLixeira(true);		
	},[]);





	const carregaParticipantes = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("POST", "participante/listar", { busca });
		let part: Participante[] = result?.dados;
		
		let dadosTabela: any[] = [];
		part.forEach((p: Participante) => {
			let item: any = {};

			item["key"] = p.id;
			item["nome"] = p.cadastro.nome;
			item["email"] = p.cadastro.email;
			p.participanteCampos.forEach((participanteCampo: ParticipanteCampo) => {
				if (participanteCampo.valor) {
					item[participanteCampo.campoId] = participanteCampo.valor
				} else {
					let valor: string = "";
					participanteCampo.participanteCampoOpcoes.forEach((opcao: ParticipanteCampoOpcao, index: number) => {
						valor += opcao.campoOpcao.nome;
						if (participanteCampo.participanteCampoOpcoes.length > index + 1)
							valor += " | ";
					});
					item[participanteCampo.campoId] = valor;
				}
			});

			dadosTabela.push(item);
		});

		SetParticipantes(dadosTabela);
	}, [app, busca]);


	const buscar = useCallback((busca: string) => {
		if (busca.length >= 3) {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				setBusca(busca);
				carregaParticipantes();
			}, 500);
		}
		if (busca.length === 0) {
			setBusca(busca);
			carregaParticipantes();
		}
	}, [carregaParticipantes]);
	

	// FUNÇÕES PARA EXPORTAR CSV E PDF     
    const prepararCSV = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("POST", "participante/listarCSV", { busca });			
		let part: Participante[] = result?.dados;						
		let lstParticipantes: any = [];		

		part?.forEach((p: Participante) => {
			let item: any = {};
			
			item["nome"] = p.cadastro.nome;
			item["email"] = p.cadastro.email;
			p.participanteCampos.forEach((participanteCampo: ParticipanteCampo) => {
				if (participanteCampo.valor) {					
					item[participanteCampo?.campo?.nome] = participanteCampo.valor
				} else {
					let valor: string = "";					
					participanteCampo.participanteCampoOpcoes.forEach((opcao: ParticipanteCampoOpcao, index: number) => {
						//item[opcao.campoOpcao.campo.nome] = participanteCampo.valor
						valor += opcao.campoOpcao.nome;
						if (participanteCampo.participanteCampoOpcoes.length > index + 1)
						valor += " | ";
					});
					item[participanteCampo.campo.nome] = valor;
				}
			});
						
			lstParticipantes.push(item);
			//setParticipantesExp(lstParticipantes)
		});

		// console.log(lstParticipantes);  
		if (lstParticipantes.length > 0) {
			setDesabilitaBotao(false);
		} else {
			setDesabilitaBotao(true);			
		}      
        setParticipantesExp(lstParticipantes);
    }, [app, busca]);


    const componentRef = useRef<any>();    
    const componentRodape = useRef<any>();    

    const handlePrint = useReactToPrint({    
        content: () => componentRef.current        
    });


	const excluir = useCallback(async(id: string) => {		
		let result: Result | null = await app.ajaxApi("PUT", `participante/excluir/${id}`);		
		if (result?.code === 200) {
			carregaParticipantes();
			prepararCSV();	
		}
	}, [app, carregaParticipantes, prepararCSV]);


	const configuraTabela = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", 'campo/listar/participante');
		let campos: Campo[] = result?.dados;

		let tabela: ColumnsType<any> = [
			{
				title: "Nome",
				dataIndex: "nome"
			},
			{
				title: "E-mail",
				dataIndex: "email"
			}
		];

		campos.forEach((campo: Campo) => {
			if (campo.visivelAdmin && !campo.oculto) {
				tabela.push({
					title: campo.nome,
					dataIndex: campo.id
				})
			}
		});

		tabela.push({
			render: ((item: any) => (
				<>
					<Button onClick={() => gerenciarAereos(item.key)}><MdAirplaneTicket />Aéreos</Button>
					<Button type='primary' className='button-icon' onClick={() => editarParticipante(item.key)}><FaEdit /></Button>

					<Popconfirm placement='topLeft'
						title={<span>Você confirma a EXCLUSÃO do participante<br /><strong>{item.nome}</strong></span>}
						onConfirm={() => excluir(item.key)}>
						<Button type='primary' danger className='button-icon'><FaTrash /></Button>
					</Popconfirm>
				</>
			)
			)
		});

		setTabelaParticipantes(tabela);
	}, [app, editarParticipante, excluir, gerenciarAereos]);
	

    const preparaPDF = useCallback(() => {
        componentRodape.current(isVisible);
        handlePrint();
    }, [handlePrint]);
    // FIM FUNÇÕES


	const loadPage = useCallback(async () => {
		// Define configuração da tabela
		await configuraTabela();
		carregaParticipantes();
		prepararCSV();			
	}, [carregaParticipantes, configuraTabela, prepararCSV]);


	useEffect(() => {
		// if (participantes.length === 0 && participantesExp.length === 0) {
		// }
		loadPage();
	}, [loadPage]);



	return (
		<>
			<h1>
				<FaUsers />
				Participantes
			</h1>

			<div className="submenu">
				<Button type='primary' className='button-success' onClick={novoParticipante}>
					<FaPlus />
					Novo Participante
				</Button>

				<div style={{ width: '100%', margin: '0 15px' }}>
					<Input size='large' prefix={<FaSearch />} placeholder='Pesquisar participante' onChange={(e) => buscar(e.target.value)} />
				</div>


				<div style={{margin: '0 15px'}}>
					<Space style={{float:"right"}}>   
						<Tooltip placement="topLeft" open={desabilitaBotao} zIndex={0}	title={"Habilita quando houver participantes cadastrados"}>        
							<Button type="primary" disabled={desabilitaBotao}>                        
								<CSVLink separator=";" filename={"Participantes.csv"} data={participantesExp} className="btn btn-primary" onClick={()=>{ message.success("Baixando arquivo")}}>
									<FaFileCsv/>Exportar CSV 
								</CSVLink>                                 
							</Button>
						</Tooltip>
						{/* <Button onClick={handlePrint} type="primary" danger><FaFilePdf /> Exportar PDF </Button> */}
					</Space>
				</div>


				<div style={{margin: '0 15px'}}>
					<Button type='primary' className='button-default' onClick={() => app.toUrl("/admin/campos/participantes")}>
						<MdOutlineDashboardCustomize />
						Campos Personalizados
					</Button>
				</div>

				<div>
					<Button type='primary' className='button-danger' danger onClick={excluirLixeira}>						
						<FaTrash />
						Lixeira
					</Button>
				</div>
			</div>

			<div style={{width:"100%"}} >
				<div ref={componentRef}>
					{
						participantesExp &&
						<Table size='small' columns={tabelaParticipantes} dataSource={participantes} defaultExpandAllRows={true} pagination={{pageSize: 100}}/>						
					}
				</div>
			</div>



			<Modal width={1000} closable maskClosable={false} footer={false} destroyOnClose open={modalEditar} onCancel={() => setModalEditar(false)}>
				<NovoParticipante participanteId={participanteIdSelecionado} onClose={() => setModalEditar(false)} onSuccess={loadPage} />
			</Modal>

			<Modal width={1000} closable maskClosable={false} footer={false} destroyOnClose open={modalLixeira} onCancel={() => setModalLixeira(false)}>
				<ParticipanteExcluido onSuccess={loadPage} onClose={() => setModalLixeira(false)}></ParticipanteExcluido>
			</Modal>


			<Modal width={1100} closable maskClosable={false} footer={false} destroyOnClose open={modalAereos} onCancel={fecharAereos}>
				{/* {participanteSelecionado && (
					<GerenciarAereo participante={participanteSelecionado} onClose={fecharAereos} />
				)} */}
				<ParticipanteAereo participanteId={participanteIdSelecionado} onClose={fecharAereos} />
			</Modal>


		</>
	);
}

export default CadastrosParticipantes;