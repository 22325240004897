import { Pie } from "@ant-design/charts";
import React, { useCallback, useEffect, useState } from "react";
import { FaHotel } from "react-icons/fa";
import { Result } from "../../../../interfaces/Result";
import { useApp } from "../../../../providers/AppProvider";

const DashboardHospedagem: React.FC = () => {
	const app = useApp();
	const [config, setConfig] = useState<any | null>(null);
	const [hospedagem, setHospedagem] = useState<any | null>();
		
	const CarregarDashboardHospedagem = useCallback(async () => {
		let auxData: any = [];
		let result: Result | null = await app.ajaxApi("GET", "dashboard/hospedagem");
		let lstHospedagem: any = [];			
		if (result?.code === 200) {						
			
			// console.log(result.dados.length);
			if (result.dados.length !== undefined) {
				result.dados.forEach((item: any) => {				
					//console.log(item);
					let hospItem: any = null;
					hospItem = {
						hotel: item[0].nome,
						totalPessoas: item[0].totalPessoas,
						totalQuartos: item[0].totalQuartos,
						valorTotalHosp: item[0].valorTotalHosp						
					};					
					lstHospedagem.push(hospItem);
				});				
			}
			
			//console.log(lstHospedagem);
			setHospedagem(lstHospedagem);
			//console.log(hospedagem);

			lstHospedagem.forEach((item: any) => {				
				auxData.push(
					{
						type: item.hotel,
						value: item.valorTotalHosp,					
					}
				);			
			});

			//console.log(auxData);

			if (auxData.length === 0) {
				auxData.push({
					type: 'Sem Dados',								
					value: 0,					
				});	
			}
		}
		
		var aux = {
			appendPadding: 10,
			data: auxData,
			angleField: "value",
			colorField: "type",
			radius: 1,
			innerRadius: 0.6,
			label: {
				type: "inner",
				offset: "-50%",
				content: "{value}",
				style: {
					textAlign: "center",
					fontSize: 14,
				},
			},
			interactions: [
				{
					type: "element-selected",
				},
				{
					type: "element-active",
				},
			],
			statistic: {
				title: false,
				content: {
					style: {
						whiteSpace: "pre-wrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
						fontSize: 20
					},
					content: auxData[0].value === 0 ? "Sem Dados" : "Hospedagens",
				},
			},
			
		};

		setConfig(aux);
	}, [app]);

	
	// const CarregarDashboardHospedagem = useCallback(async () => {
	// 	function renderStatistic(containerWidth: any, text: any, style: any) {
    //         const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    //         const R = containerWidth / 2; 
        
    //         let scale = 1;
        
    //         if (containerWidth < textWidth) {
    //           scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    //         }
        
    //         const textStyleStr = `width:${containerWidth}px;`;
    //         return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    //       }
        
	// 	let auxData: any = [];
	// 	let result: Result | null = await app.ajaxApi("GET", "dashboard/hospedagem");
	// 	let lstHospedagem: any = [];			
	// 	if (result?.code === 200) {			
	// 		//console.log(result.dados);
	// 		result.dados.forEach((item: any) => {				
	// 			let hospedagem: any = null;
	// 			hospedagem = {
	// 				hotel: item[0].nome,
	// 				totalPessoas: item[0].totalPessoas,
	// 				totalQuartos: item[0].totalQuartos,
	// 				valorTotalHosp: item[0].valorTotalHosp						
	// 			};					
	// 			lstHospedagem.push(hospedagem);
	// 		});
			
	// 		console.log(lstHospedagem);
	// 		setHospedagem(lstHospedagem);
			
	// 		lstHospedagem.forEach((item: any) => {
	// 			//console.log(item);
	// 			auxData.push(
	// 				{
	// 					type: item.hotel,
	// 					value: item.totalQuartos,					
	// 				}
	// 			);			
	// 		});
	// 	}
          
    // 	const aux = {
    //         appendPadding: 10,
    //         auxData,
    //         angleField: 'value',
    //         colorField: 'type',
    //         radius: 1,
    //         innerRadius: 0.64,
    //         meta: {
    //           value: {
    //             formatter: (v: any) => `${v} Nº Quartos: `,
    //           },
    //         },
    //         label: {
    //           type: 'inner',
    //           offset: '-50%',
    //           style: {
    //             textAlign: 'center',
    //           },
    //           autoRotate: false,
    //           content: '{value}',
    //         },
    //         statistic: {
    //           title: {
    //             offsetY: -4,
    //             customHtml: (container: any, view: any, datum: any) => {
    //               const { width, height } = container.getBoundingClientRect();
    //               const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
    //               const text = datum ? datum.type : 'Hotel';
    //               return renderStatistic(d, text, {
    //                 fontSize: 18,
    //               });
    //             },
    //           },
    //           content: {
    //             offsetY: 4,
    //             style: {
    //               fontSize: '20px',
    //             },
    //             customHtml: (container: any, view: any, datum: any, data: any) => {
    //               const { width } = container.getBoundingClientRect();
    //               const text = datum ? `Nº Quartos:  ${datum.value}` : ``;
    //               return renderStatistic(width, text, {
    //                 fontSize: 32,
    //               });
    //             },
    //           },
    //         },            
    //         interactions: [
    //           {
    //             type: 'element-selected',
    //           },
    //           {
    //             type: 'element-active',
    //           },
    //           {
    //             type: 'pie-statistic-active',
    //           },
    //         ],
    //       };

    //       setConfig(aux);
	// }, [app]);

	useEffect(() => {
		if (!hospedagem)
			CarregarDashboardHospedagem();		
	}, [CarregarDashboardHospedagem, hospedagem]);

	return (
		<>
			<div className="box">
				<h2>		
					<FaHotel />				
					Detalhes Hospedagens
				</h2>
				{
					config && <Pie tooltip={{customContent(title, data) {
						//console.log(title);
						let item: any = hospedagem.find((i: any) => {
							if (i.hotel === title) {
								return true;
							}
							else{
								return false;							
							}
						})
						return (
							<>
								<p>{title}</p>	
								<p>Valor: {item?.valorTotalHosp}</p>	
								<p>Nº Quartos: {item?.totalQuartos}</p>	
								<p>Nº Pessoas: {item?.totalPessoas}</p>	

							</>
						)
					},}} {...config} />
				}
			</div>
		</>
	);
};

export default DashboardHospedagem;
