import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { Cadastro } from '../../../../entities/cadastro';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import AlterarSenha from '../../forms/AlterarSenha';
import MeusDadosForm from '../../forms/MeusDadosForm';

const MeusDados: React.FC = () => {

	const app = useApp();
	const [cadastro, setCadastro] = useState<Cadastro>();


	const carregaDados = useCallback(async () => {
		let result: Result | null = await app.ajaxApi('get', `cadastro/meus-dados`);
		if (result?.code === 200) {
			setCadastro(result.dados);
		}
	}, [app]);


	useEffect(() => {
		carregaDados();
	}, [carregaDados]);


	return (
		<>
			<h1>
				<FaUser />
				Meus Dados
		  	</h1>

			<Row gutter={25}>
				<Col md={16}>
					<div className="box">
						<MeusDadosForm data={cadastro} />
					</div>
				</Col>

				<Col md={8}>
					<div className="box">
						<AlterarSenha />
					</div>
				</Col>
			</Row>
		</>
	);
}

export default MeusDados;