import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit, FaPlus } from 'react-icons/fa';
import TooltipForm from '../../../components/TooltipForm';
import { Campo } from '../../../entities/campo';
import { CampoOpcao } from '../../../entities/campo-opcao';
import { Participante } from '../../../entities/participante';
import { ParticipanteCampo } from '../../../entities/participante-campo';
import { Result } from '../../../interfaces/Result';
import InputCnpj from '../../../masks/InputCnpj';
import InputCpf from '../../../masks/InputCpf';
import InputFloat from '../../../masks/InputFloat';
import InputHora from '../../../masks/InputHora';
import InputRg from '../../../masks/InputRg';
import InputTelefone from '../../../masks/InputTelefone';
import { useApp } from '../../../providers/AppProvider';

import moment from 'moment';
import { ParticipanteCampoOpcao } from '../../../entities/participante-campo-opcao';


interface NovoParticipanteProps {
	participanteId: string | null,
	onSuccess(participante: Participante): void,
	onClose?(): void
}



const NovoParticipante: React.FC<NovoParticipanteProps> = ({
	participanteId,
	onSuccess,
	onClose
}) => {

	const [form] = useForm();
	const { Option} = Select;

	const app = useApp();
	const [campos, setCampos] = useState<Campo[]>([]);
	const [loading, setLoading] = useState(false);
	
	const carregaCampos = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", 'campo/listar/participante');
		let resultParticipante: Result | null = null;

		if (participanteId)
			resultParticipante = await app.ajaxApi("GET", `participante/${participanteId}`);

		let participante: Participante = resultParticipante?.dados;
		
		if (result?.code === 200)
			setCampos(result.dados);

		if (participante) {
			let dados: any = {
				nome: participante.cadastro.nome,
				email: participante.cadastro.email
			}
			participante.participanteCampos.forEach((campo: ParticipanteCampo) => {
				let campoField: Campo = result?.dados.find((item: Campo) => {
					if (item.id === campo.campoId)
						return true;

					return false;
				});

				switch(campoField.tipo) {
					case 3: // DATE
						dados[campo.campoId] = campo.valor ? moment(campo.valor) : '';
						break;

					case 4:
						dados[campo.campoId] = campo.valor ? moment(campo.valor) : '';
						break;

					case 5:
						dados[campo.campoId] = campo.valor ? moment(campo.valor) : '';
						break;
					
					case 7:
						dados[campo.campoId] = campo.participanteCampoOpcoes.length > 0 ? campo.participanteCampoOpcoes[0].campoOpcaoId : '';
						break;
					
					case 8:
						let ids: string[] = [];
						if (campo.participanteCampoOpcoes.length > 0) {
							campo.participanteCampoOpcoes.forEach((opcao: ParticipanteCampoOpcao) => {								
								ids.push(opcao.campoOpcaoId);
								dados[campo.campoId] = ids;
								
							});
						}					
						break;

					case 9:
						dados[campo.campoId] = campo.valor === "true" ? [ true ] : [];
						break;
					
					default:
						dados[campo.campoId] = campo.valor
				}
					
			});
			
			form.setFieldsValue(dados);			
		}
	}, [app, participanteId, form]);


	const salvar = useCallback(async (formData: any) => {
		setLoading(true);

		let result: Result |  null;
		let camposDados: { campoId: string; valor: any; }[] = [];
		campos.forEach((campo: Campo) => {
			if (formData[campo.id]) {
				let opcao: any = {};

				opcao["campoId"] = campo.id;
				switch(campo.tipo)
				{
					case 8:
						opcao["valor"] = formData[campo.id].map((id: string) => id).join(",");
						break;

					case 9:
						opcao["valor"] = formData[campo.id].map((id: boolean) => id).join(",");
						break;
					
					default:
						opcao["valor"] = formData[campo.id];
				}	

				camposDados.push(opcao);
			}
		});

		let dados = {
			nome: formData.nome,
			email: formData.email,
			campos: camposDados
		};

		//console.log(dados)
		
		if (participanteId) {
			// ***** ALTERAR			
			result = await app.ajaxApi("PUT", `participante/alterar/${participanteId}`, dados);
		} else {
			// ***** INSERIR
			result = await app.ajaxApi("POST", `participante`, dados);
		}

		if (result?.code === 200) {
			onSuccess(result.dados);

			if (onClose)
				onClose();
		}
	
		setLoading(false);

	}, [campos, app, onSuccess, onClose, participanteId]);


	useEffect(() => {
		carregaCampos();
	}, [carregaCampos]);

	return (
		<>
			<h2>
				{participanteId ? (
					<>
						<FaEdit />
						Editar Participante	
					</>
				) : (
					<>
						<FaPlus />
						Novo Participante	
					</>
				)}
			</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<Row gutter={25}>
					<Col md={12}>
						<FormItem label="Nome" name="nome" rules={[
							{ required: true, message: "Informe o nome" }
						]}>
							<Input />
						</FormItem>
					</Col>

					<Col md={12}>
						<FormItem label="E-mail" name="email" rules={[
							{ required: true, message: "Informe o e-mail" }
						]}>
							<Input />
						</FormItem>
					</Col>
				</Row>

				
				
				<Row gutter={25}>
					{campos.map((campo: Campo) => (
						!campo.oculto && (
							<Col md={(24 / campo.largura)} key={campo.id}>
								{campo.tipo === 1 && ( // ***** TEXTO
									<FormItem label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} name={campo.id} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<Input />
									</FormItem>
								)}


								{campo.tipo === 2 && ( // ***** NUMÉRICO
									<FormItem label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} name={campo.id} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<InputNumber />
									</FormItem>
								)}


								{campo.tipo === 3 && ( // ***** DATA
									<FormItem label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} name={campo.id} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
									</FormItem>
								)}			


								{campo.tipo === 4 && ( // ***** HORA
									<FormItem label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} name={campo.id} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<InputHora />
									</FormItem>
								)}		


								{campo.tipo === 5 && ( // ***** DATA e HORA
									<FormItem label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} name={campo.id} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<DatePicker format="DD/MM/YYYY HH:mm" showTime style={{ width: '100%' }} />
									</FormItem>
								)}		


								{campo.tipo === 6 && ( // ***** MOEDA
									<FormItem label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} name={campo.id} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<InputFloat />
									</FormItem>
								)}			



								{campo.tipo === 7 && ( // ***** SELECT
									<FormItem label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} name={campo.id} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<Select style={{ width: '100%' }}>
											{campo.opcoes.map((opcao: CampoOpcao) => (
												<Option key={opcao.id} value={opcao.id}>
													{opcao.nome}
												</Option>
											))}
										</Select>
									</FormItem>
								)}

								{campo.tipo === 8 && ( // ***** LISTA DE CHECKBOXs
									<FormItem name={campo.id} label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} rules={[
										{ required: campo.obrigatorio, message: "Selecione pelo menos uma opção" }
									]}>
										<Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={campo.opcoes}/>
									</FormItem>
								)}


								{campo.tipo === 9 && (	// ***** CHECKBOX (Boleano)
									<FormItem name={campo.id} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<Checkbox.Group style={{ marginTop: '35px', marginBottom: '4px' }} options={[{ label: campo.nome, value: true }]} />
									</FormItem>	
								)}	


								{campo.tipo === 10 && (	 // ***** TELEFONE
									<FormItem name={campo.id} label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<InputTelefone />
									</FormItem>	
								)}		


								{campo.tipo === 11 && (	 // ***** CPF
									<FormItem name={campo.id} label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<InputCpf />
									</FormItem>	
								)}		


								{campo.tipo === 12 && (	 // ***** CNPJ
									<FormItem name={campo.id} label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<InputCnpj />
									</FormItem>	
								)}	


								{campo.tipo === 13 && (	 // ***** RG
									<FormItem name={campo.id} label={<span>{campo.nome} <TooltipForm descricao={campo.descricao} /></span>} rules={[
										{ required: campo.obrigatorio, message: "Campo obrigatório" }
									]}>
										<InputRg />
									</FormItem>	
								)}		
							</Col>
						)				
					))}
				</Row>
					
				

				<div className="acoes">
					{onClose && (
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>	
					)}
					<Button type='primary' className='button-success' htmlType='submit' icon={<FaCheck />} loading={loading}>Salvar</Button>
				</div>

			</Form>
		</>
  );
}

export default NovoParticipante;