import { Alert, Button, Col, Input, InputNumber, message, Row, Select, Tag } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import Form, { useForm } from 'antd/lib/form/Form';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState, useCallback } from 'react';
import { FaBan, FaCheck, FaEdit, FaPlus } from 'react-icons/fa';
import { MdLocalHotel } from 'react-icons/md';
import { HospedagemEntity } from '../../../../entities/HospedagemEntity';
import { HospedagemQuartoTipoEntity } from '../../../../entities/HospedagemQuartoTipoEntity';
import { HospedagemStatusEntity } from '../../../../entities/HospedagemStatusEntity';
import { Hotel } from '../../../../entities/Hotel';
import { HospedagemQuartoCommand } from '../../../../interfaces/hospedagem-quarto-command';
import { Result } from '../../../../interfaces/Result';
import InputFloat from '../../../../masks/InputFloat';
import { useApp } from '../../../../providers/AppProvider';
import HospedagemQuartoInserirForm from './HospedagemQuartoInserirForm';


interface HospedagemProps {
	data: HospedagemEntity | null,
	onSuccess?(hospedagem: HospedagemEntity): void,
	onClose?(): void
}

const HospedagemForm: React.FC<HospedagemProps> = ({
    data, onClose, onSuccess
}) => {
    
    const app = useApp();
    const [loading, setLoading] = useState(false);
    const [form] = useForm();
    const { Option, OptGroup } = Select;
	const { TextArea } = Input;
    const [hoteis, setHoteis] = useState<Hotel[]>([]);
	const [status, setStatus] = useState<HospedagemStatusEntity[]>([]);
	const [quartosTbl, setQuartosTbl] = useState<HospedagemQuartoCommand[]>([]);    
    const [mensagem, setMensagem] = useState(true);

    const salvar = useCallback(async (formData: any) => {        
		setLoading(true);
		let result: Result | null;
        let lstQuarto: any =  [];
        let auxQuarto: any = null;

        quartosTbl.forEach(function(item){
            auxQuarto = {
                Quantidade: item.quantidade,
                HospedagemQuartoTipoId: item.hospedagemQuartoTipo.id
            }
            lstQuarto.push(auxQuarto);
        });
        formData.Quartos = lstQuarto;      
        
		if (data)
			result = await app.ajaxApi("PUT", `hospedagem/${data.id}`, formData);
		else
			result = await app.ajaxApi("POST", `hospedagem`, formData);

		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);

			if (onClose)
				onClose();
		}
		setLoading(false);
	}, [app, onClose, onSuccess, quartosTbl, data]);

    const carregarHospedagemStatus = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `hospedagem/status`);
		if (result?.code === 200) {
            
            if (result.dados.length > 0) {
			    setStatus(result.dados);
                setMensagem(true);                
            } else {
                setMensagem(false);
            }

			if (!data) {
				let statusList: HospedagemStatusEntity[] = result.dados;
				let opcoes: string[] = [];				
				if (opcoes.length > 0)
					form.setFieldsValue({ hospedagemStatusId: opcoes[0] });
			}
		}
	}, [app, data, form]);

	const carregarHoteis = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `hotel/listar`);
		if (result?.code === 200) {
            
            if (result.dados.length > 0) {
			    setHoteis(result.dados);
                setMensagem(true);
            } else {
                setMensagem(false);
            }            
		}
	}, [app]);
    
    const carregaTblQuarto = useCallback((itens: HospedagemQuartoCommand[]) => {           
        let numPessoas : number = 0;
        let numQuartos : number = 0;
        
        itens.forEach(function(quarto) {            
            numQuartos += quarto.quantidade;
            numPessoas += (quarto.quantidade * quarto.hospedagemQuartoTipo.numPessoas);
        });
        
        var auxData = {
            numPessoas: numPessoas,
            numQuartos: numQuartos
        }

        form.setFieldsValue(auxData);    
    }, [form]);
    
    const atualizaQuartosTbl = useCallback((itens: HospedagemQuartoCommand[]) => {        
        carregaTblQuarto(itens);
        setQuartosTbl(itens);            
    } , [carregaTblQuarto]);

    useEffect(() => {
        if (status.length === 0) {
            carregarHospedagemStatus();            
        }

        if (hoteis.length === 0) {
		    carregarHoteis();
        }
        
        if (data) 
			form.setFieldsValue(data);             
        
	}, [data, form, carregarHoteis, carregarHospedagemStatus, status, hoteis]);

  return (
    <>
        <h2>
            <FaEdit />
            Editar Hospedagem
        </h2>
    
        <Form form={form} layout="vertical" >
            {
                !mensagem &&
                <Alert type='warning' message="Para realizar o cadastro é necessário ter hotéis, status e tipos de quarto cadastrados. " showIcon />
            }
            
            <Row gutter={25}>
                <Col md={12}>
                    <FormItem label="Nome Hospedagem" name="nomeHospedagem"
                        rules={[{ required: true, message: "Informe o nome da hospedagem" }]}>
                        <Input />
                    </FormItem>
                    <FormItem label="Hotel" name="hotelId"
                        rules={[{ required: true, message: "Informe o hotel" }]}>
                        <Select style={{ width: '100%' }}>
                            <OptGroup label="Hotel">
                                {hoteis.map((hotel: Hotel) => (
                                    <Option key={hotel.id} value={hotel.id}>{hotel.nome}</Option>
                                ))}
                            </OptGroup>                            
                        </Select>
                    </FormItem>

                    <FormItem label="Status" name="hospedagemStatusId"
                        rules={[{ required: true, message: "Informe o Status da hospedagem" }]}>
                        <Select style={{ width: '100%' }}>                        
                            <OptGroup label="Hotel">
                                {status.map((status: HospedagemStatusEntity) => (
                                    <Option key={status.id} value={status.id}>
                                        <Tag color={status.cor}>
                                            {status.nome}                                                
                                        </Tag>
                                    </Option>
                                ))}
                            </OptGroup>                            
                        </Select>
                    </FormItem>
                </Col>

                <Col md={12}>
                    <FormItem label="Observação" name="observacao"
                        rules={[{ required: true, message: "Informe o observação" }]}>
                        <TextArea style={{ height: '205px' }} />
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={25}>
                <Col md={8}>
                <FormItem label="Valor" name="valor">
                    <InputFloat />
                </FormItem>
                </Col>
                <Col md={8}>
                    <FormItem label="Nº Pessoas" name="numPessoas">
                        <InputNumber style={{width: '100%'}} disabled />
                    </FormItem>
                </Col>
                <Col md={8}>
                    <FormItem label="Nº Quartos" name="numQuartos">
                        <InputNumber style={{width: '100%'}} disabled />
                    </FormItem>
                </Col>
            </Row>

            <div className="box">
                <HospedagemQuartoInserirForm onSuccess={atualizaQuartosTbl}/>
            </div>
        </Form>

        <Form form={form} layout="vertical" onFinish={salvar} >
            <div className="submenu">								                                        
                {onClose && (
                    <Button id="teste" type='primary' className='button-default' icon={<FaBan />} onClick={onClose} >Cancelar</Button>
                )}
                <Button htmlType='submit' type='primary' className='button-success' icon={<FaCheck />} loading={loading}>Salvar</Button>							                    
            </div>
        </Form>    
    </>
  )
}

export default HospedagemForm;