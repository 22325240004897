import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { MdAppRegistration } from 'react-icons/md';
import TextInfo from '../../../components/TextInfo';
import { Log } from '../../../entities/log';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';

import moment from 'moment';


interface LogDetalheProps {
	id: string | null | undefined,
	onClose?(): void
}


const LogDetalhe: React.FC<LogDetalheProps> = ({
	id,
	onClose
}) => {


	const app = useApp();
	const [log, setLog] = useState<Log | null>(null);

	const carregaLog = useCallback(async () => {		
		let result: Result | null = await app.ajaxApi("get", `log/${id}`);
		if (result?.code === 200)
			setLog(result.dados);
	}, [app, id]);


	useEffect(() => {
		if (id)
			carregaLog();
	}, [carregaLog, id]);


	return (
		<>
			<h2>
				<MdAppRegistration />
				Detalhes do Log
			</h2>

			<Row gutter={25}>
				<Col lg={8}>
					<div style={{ marginBottom: '15px' }}>
						<TextInfo label='Nome' text={log?.cadastroNome} />
					</div>					

					<div style={{ marginBottom: '15px' }}>
						<TextInfo label='Descrição' text={log?.descricao} />
					</div>

					<div style={{ marginBottom: '15px' }}>
						<TextInfo label='Data e Hora' text={moment(log?.created).format("DD/MM/YYYY - HH:mm:ss")} />
					</div>
				</Col>

				<Col lg={16}>
					<strong>Estrutura dos Dados</strong>
					{log?.serialize ? 
						<p style={{ whiteSpace: 'pre', wordWrap: 'break-word' }}>{JSON.stringify(JSON.parse(log?.serialize), null, "\t")}</p> : 
						<p style={{ fontStyle: 'italic' }}>Nenhuma estrutura informada</p>
					}
				</Col>
			</Row>
		</>
	);
}

export default LogDetalhe;