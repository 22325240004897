import React from 'react';

interface LayoutBlankProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const LayoutBlank: React.FC<LayoutBlankProps> = ({ children }) => {
  return (
	  <>
		  {children}
	  </>
  );
}

export default LayoutBlank;