import { Alert, Button, Form, Select, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa';
import { IoArchiveSharp } from 'react-icons/io5';
import { FollowUpStatus } from '../../../../entities/follow-up-status';
import FormProps from '../../../../interfaces/FormProps';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';


const FollowUpConcluirForm: React.FC<FormProps> = ({
	data, onSuccess, onClose
}) => {

	const app = useApp();
	const [form] = useForm();
	const { Option } = Select;

	const [followUpStatus, setFollowUpStatus] = useState<FollowUpStatus[]>([]);
	const [loading, setLoading] = useState(false);


	const carregaStatus = useCallback(async() => {
		let result: Result | null = await app.ajaxApi("get", 'follow-up/status');
		if (result) {
			setFollowUpStatus(result.dados);
			
			let ids: any[] = [];
			result.dados.forEach((item: FollowUpStatus) => {
				if (item.concluir)
					ids.push(item.id);
			});

			if (ids.length > 0)
				form.setFieldsValue({ followUpStatusId: ids[0] });
		}
	}, [app, form]);


	const salvar = useCallback(async(formData: any) => {
		setLoading(true);

		let result: Result | null = await app.ajaxApi('put', `follow-up/${data.id}/concluir`, formData);
		if (result) {
			app.calculaFollowUps();
			if (onSuccess)
				onSuccess(result.dados);
			
			setLoading(false);
			if (onClose)
				onClose();
		}

		setLoading(false);
	}, [app, data, onSuccess, onClose]);


	useEffect(() => {
		carregaStatus();
	}, [carregaStatus]);


	return (
		<>
			<h2>
				<IoArchiveSharp />
				Concluir Tarefa
			</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<FormItem label="Status" name="followUpStatusId">
					<Select>
						{followUpStatus.map((status: FollowUpStatus) => {
							if (status.concluir) {
								return (
									<Option value={status.id} key={status.id}>
										<Tag color={status.cor}>{status.nome}</Tag>
									</Option>
								);
							}
							
							return null;
						})}						
					</Select>
				</FormItem>

				<FormItem label="Descrição" name="descricao" rules={[
					{ required: true, message: "Informe a descrição" }
				]}>
					<TextArea rows={10} />
				</FormItem>

				<Alert type='info' message="Ao concluir a tarefa esta não poderá mais ser editada" showIcon />

				<div className="acoes">
					{onClose && (
						<Button icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}
					
					<Button loading={loading} icon={<FaCheck />} htmlType="submit" className='button-success' type='primary'>Salvar</Button>
				</div>
			</Form>
		</>
	);
}

export default FollowUpConcluirForm;