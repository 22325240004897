import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { Campo } from '../../../entities/campo';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';

interface CampoEditarProps {
	tabela: "Participante" | "Aereo",
	data?: Campo | null,
	onSuccess(campo: Campo): void,
	onClose?(): void
}

const CampoEditar: React.FC<CampoEditarProps> = ({
	data,
	tabela,
	onSuccess,
	onClose
}) => {

	const { Option } = Select;
	const [form] = useForm();
	const app = useApp();

	const [obrigatorio, setObrigatorio] = useState(false);
	const [loading, setLoading] = useState(false);



	const salvar = useCallback(async (formData: any) => {
		setLoading(true);
		let dados = {
			...formData,			
			tabela
		};

		let result: Result | null;
		if (data) {
			// ALTERAR
			result = await app.ajaxApi("PUT", `campo/${data.id}`, dados);
		} else {
			// INSERIR
			result = await app.ajaxApi("POST", "campo", dados);
		}
		
		if (result?.code === 200) {
			onSuccess(result.dados);

			if (onClose)
				onClose();
		}

		setLoading(false);
	}, [app, onSuccess, onClose, tabela, data]);



	useEffect(() => {
		if (data) {			
			form.setFieldsValue(data);
			setObrigatorio(data.obrigatorio);
		} else {
			form.setFieldsValue({ largura: 2 });
		}
	}, [form, data]);


	return (
		<>
			<h2>
				<FaEdit />
				Editar Campo
			</h2>

			<Form layout='vertical' onFinish={salvar} form={form}>
				<Row gutter={25}>
					<Col md={12}>
						<FormItem label="Nome" name="nome" rules={[
							{ required: true, message: "Informe o nome do campo" }
						]}>
							<Input />
						</FormItem>	

						<FormItem label="Tipo" name="tipo" rules={[
							{ required: true, message: "Informe o tipo de dado" }
						]}>
							<Select style={{ width: '100%' }} disabled={data ? true : false}>
								<Option value={1}>Texto</Option>
								<Option value={2}>Número</Option>
								<Option value={3}>Data</Option>
								<Option value={4}>Hora</Option>
								<Option value={5}>Data e Hora</Option>
								<Option value={6}>Moeda</Option>
								<Option value={7}>Select Box</Option>
								<Option value={8}>Checkbox</Option>
								<Option value={9}>Boleano</Option>
								<Option value={10}>Telefone</Option>
								<Option value={11}>Cpf</Option>
								<Option value={12}>Cnpj</Option>
							</Select>
						</FormItem>	
						
						<FormItem label="Descrição" name="descricao">
							<TextArea style={{ height: '120px' }} />
						</FormItem>							
					</Col>

					<Col md={12}>
						<FormItem label="Largura" name="largura" rules={[
							{ required: true, message: "Informe o tipo de dado" }
						]}>
							<Select style={{ width: '100%' }} defaultValue={2}>
								<Option value={1}>100%</Option>
								<Option value={2}>50%</Option>
								<Option value={3}>33%</Option>								
							</Select>
						</FormItem>	
						
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<FormItem name="obrigatorio" valuePropName='checked' style={{ margin: 0 }}>
								<Checkbox>
									Obrigatório
								</Checkbox>
							</FormItem>

							<hr />

							<FormItem name="visivelAdmin" valuePropName='checked' style={{ margin: 0 }}>
								<Checkbox>
									Visível no Admin
								</Checkbox>
							</FormItem>

							<FormItem name="visivelRelatorio" valuePropName='checked' style={{ margin: 0 }}>
								<Checkbox>
									Visível em Relatório
								</Checkbox>
							</FormItem>

							<FormItem name="visivelFormulario" valuePropName='checked' style={{ margin: 0 }}>
								<Checkbox>
									Visível no Formulário
								</Checkbox>
							</FormItem>

							<FormItem name="visivelMobile" valuePropName='checked' style={{ margin: 0 }}>
								<Checkbox>
									Visível no Mobile
								</Checkbox>
							</FormItem>

							<hr />

							<FormItem name="oculto" valuePropName='checked' style={{ margin: 0 }}>
								<Checkbox>
									Oculto
								</Checkbox>
							</FormItem>
						</div>
						
					</Col>
				</Row>

				<div className="acoes">
					{onClose && (
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}
					<Button type='primary' className='button-success' icon={<FaCheck />} htmlType='submit' loading={loading}>Salvar</Button>					
				</div>
				
			</Form>
		</>
	);
}

export default CampoEditar;