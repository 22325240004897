import { Button, Form, Select, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa';
import { FollowUpStatus } from '../../../../entities/follow-up-status';
import FormProps from '../../../../interfaces/FormProps';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';

const FollowUpAssumirForm: React.FC<FormProps> = ({
	data,
	onSuccess,
	onClose
}) => {

	const app = useApp();

	const { Option } = Select;
	const [form] = useForm();

	const [status, setStatus] = useState<FollowUpStatus[]>([]);
	const [loading, setLoading] = useState(false);

	const carregaStatus = useCallback(async() => {
		

		let result: Result | null = await app.ajaxApi("get", "follow-up/status");
		if (result) {
			setStatus(result.dados);

			if (result.dados) {
				let ids: any = [];
				result.dados.forEach((item: FollowUpStatus) => {
					if (item.exibirAlterar)
						ids.push(item.id);
				});

				if (ids.length > 0)
					form.setFieldsValue({ followUpStatusId: ids[0] });
			}
		}
		
		
	}, [app, form]);


	const salvar = useCallback(async (formData: any) => {
		setLoading(true);
		let result: Result | null = await app.ajaxApi("put", `follow-up/${data.id}/assumir`, formData);
		if (result) {
			app.calculaFollowUps();
			
			if (onSuccess)
				onSuccess(result.dados);
			
			setLoading(false);
			if (onClose)
				onClose();
		}

		setLoading(false);
	}, [app, data, onSuccess, onClose]);


	useEffect(() => {
		carregaStatus();
	}, [carregaStatus]);


	return (
		<>
			<h2>Deseja assumir esta tarefa?</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<FormItem label="Status" name="followUpStatusId" rules={[
					{
						required: true, message: "Informe o status"
					}
				]}>
					<Select>
						{status.map((item: FollowUpStatus) => {
							if (item.exibirAlterar) {
								return (
									<Option key={item.key} value={item.id}>
										<Tag color={item.cor}>
											{item.nome}
										</Tag>			
									</Option>					
								);
							}
							
							return null;
						})}
					</Select>
				</FormItem>

				<div className="acoes">
					{onClose && (
						<Button className='button-default' onClick={onClose} icon={<FaBan />}>Cancelar</Button>
					)}					
					<Button loading={loading} className='button-success' htmlType='submit' type='primary' icon={<FaCheck />}>Assumir Tarefa</Button>
				</div>
			</Form>
		</>
	);
}

export default FollowUpAssumirForm;