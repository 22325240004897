import { Button, Modal, Popconfirm } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { IoAirplane } from 'react-icons/io5';
import { AereoCompanhia } from '../../../../entities/aereo-companhia';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import AereoCompanhiaEditarForm from '../../forms/AereoCompanhia/AereoCompanhiaEditarForm';


const AereoCompanhias: React.FC = () => {

	const app = useApp();
	const [companhias, setCompanhias] = useState<AereoCompanhia[]>([]);
	const [modal, setModal] = useState(false);
	const [companhia, setCompanhia] = useState<AereoCompanhia | null>(null);


	const tabelaCompanhias: ColumnsType<AereoCompanhia> = [
		{
			title: "Nome",
			dataIndex: "nome"
		},
		{
			render: (companhia: AereoCompanhia) => (
				<>
					<Popconfirm placement='topLeft' title={<span>Você confirma a EXCLUSÃO da companhia<br /><strong>{companhia.nome}</strong></span>} onConfirm={() => excluir(companhia)}>
						<Button size='small' type='primary' danger icon={<FaTrash />}>Excluir</Button>	
					</Popconfirm>
					<Button size='small' type='primary' icon={<FaEdit />} onClick={() => editarCompanhia(companhia)}>Editar</Button>
				</>
			)
		}
	];

	
	const carregaCompanhias = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("get", `aereo/companhia`);		
		if (result?.code === 200) {
            if (result.dados.length !== 0) {
				setCompanhias(result.dados);                
            } 
		}
	},[app]);



	const excluir = useCallback(async (companhia: AereoCompanhia) => {
		let result: Result | null = await app.ajaxApi("DELETE", `aereo/companhia/${companhia.id}`);
		if (result?.code === 200) {			
			carregaCompanhias();
		}
	}, [app, carregaCompanhias]);



	const novaCompanhia = useCallback(() => {
		setModal(true);
		setCompanhia(null);
	}, []);


	const editarCompanhia = useCallback((companhia: AereoCompanhia) => {
		setModal(true);
		setCompanhia(companhia);
	}, []);


	


	useEffect(() => {
		if (companhias.length === 0) {
			carregaCompanhias();			
		}
	}, [carregaCompanhias, companhias]);



	return (
		<>
			<h1>
				<IoAirplane />
				Companhias Aéreas
			</h1>

			<div className="submenu">
				<Button type='primary' className='button-success' onClick={novaCompanhia}>
					<FaPlus />
					Nova Companhia Aérea
				</Button>
			</div>

			<Table size='small' pagination={{ pageSize: 100 }} columns={tabelaCompanhias} dataSource={companhias} />

			<Modal open={modal} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModal(false)}>
				<AereoCompanhiaEditarForm data={companhia} onClose={() => setModal(false)} onSuccess={carregaCompanhias} />
			</Modal>
		</>
	);
}

export default AereoCompanhias;