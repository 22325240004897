import { Button, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Password from 'antd/lib/input/Password';
import React, { useCallback, useState } from 'react';
import { FaCheck, FaLock } from 'react-icons/fa';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';



const AlterarSenha: React.FC = () => {

	const app = useApp();
	const [form] = useForm();
	const [loading, setLoading] = useState(false);


	const salvar = useCallback(async (dataForm: any) => {
		setLoading(true);

		let result: Result | null = await app.ajaxApi('put', 'cadastro/alterar-senha', dataForm);
		if (result?.code === 200) {
			notification.success({
				message: "Senha alterada",
				duration: 5,
				placement: "bottomRight"
			});

			form.resetFields();
		}

		setLoading(false);
	}, [app, form]);



	return (
		<>
			<h2>
				<FaLock />
				Alterar a Senha
			</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<FormItem label="Nova Senha" name="novaSenha" rules={[
					{ required: true, message: "Informe a nova senha" }
				]}>
					<Password />
				</FormItem>

				<FormItem label="Confirme a Nova Senha" name="novaSenhaConfirm" rules={[
					{ required: true, message: "Confirme a nova senha" }
				]}>
					<Password />
				</FormItem>

				<hr />

				<FormItem label="Senha Atual" name="senhaAtual" rules={[
					{ required: true, message: "Informe sua senha atual" }
				]}>
					<Password />
				</FormItem>

				<div className="acoes">
					<Button type='primary' className='button-success' htmlType='submit' icon={<FaCheck />} loading={loading}>						
						Alterar Senha
					</Button>
				</div>
			</Form>
		</>
	);
}

export default AlterarSenha;