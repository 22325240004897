import { Alert, Button, Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Password from 'antd/lib/input/Password';
import React, { useCallback, useState } from 'react';
import { FaArrowLeft, FaCheck, FaLock } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import BoxError from '../../../../components/BoxError';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';



const RecuperaSenha: React.FC = () => {

	const app = useApp();
	const [loading, setLoading] = useState(false);
	const [alterada, setAlterada] = useState(false);
	const [error, setError] = useState<Result | null>(null);

	const { token } = useParams();



	const submit = useCallback(async (formData: any) => {
		setLoading(true);
		setError(null);
		setAlterada(false);

		try {
			let result: Result | null = await app.recuperaSenha(formData.novaSenha, formData.novaSenhaConfirm, formData.codigo, token);
			if (result?.code === 200) {
				setAlterada(true);
			}
		} catch (e: any) {
			setError(e.response.data);			
		}

		setLoading(false);
	}, [app, token]);



	return (
		<>
			<h1>
				<FaLock />
				Criar nova senha
			</h1>

			<BoxError result={error} />

			{!alterada ? (
				<Form layout='vertical' onFinish={submit}>
					<FormItem label="Nova Senha" name="novaSenha" rules={[
						{ required: true, message: "Informe a nova senha" }
					]}>
						<Password />
					</FormItem>

					<FormItem label="Confirme a Nova Senha" name="novaSenhaConfirm" rules={[
						{ required: true, message: "Confirme a nova senha" }
					]}>
						<Password />
					</FormItem>

					<hr />

					<FormItem label="Código de Verificação" name="codigo" rules={[
						{ required: true, message: "Informe o código recebido por e-mail" }
					]}>
						<Input />
					</FormItem>

					<div className="acoes">
						<Button type='primary' className='button-success' htmlType='submit' icon={<FaCheck />} loading={loading}>Criar Senha</Button>
					</div>
				</Form>
			) : (
				<>
					<Alert style={{ marginBottom: '20px' }} message="Senha alterada" description="Realize o login novamente com a nova senha" type='success' showIcon  />
					<div className="acoes" style={{ textAlign: 'right' }}>
						<Button type='primary' onClick={() => app.toUrl("/")} icon={<FaArrowLeft />}>Ir para o Login</Button>
					</div>
				</>
			)}
			
		</>
	);
}

export default RecuperaSenha;