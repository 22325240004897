import { Button, Modal, Popconfirm, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { AiFillSignal } from 'react-icons/ai';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { HospedagemStatusEntity } from '../../../../entities/HospedagemStatusEntity';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import HospedagemStatusForm from '../../forms/Hospedagem/HospedagemStatusForm';

const HospedagemStatus: React.FC = () => {
    const app = useApp();
    const [hospedagemStatusLst, setHospedagemStatusLst] = useState<HospedagemStatusEntity[]>([]);
    const [modal, setModal] = useState(false);
    const [hospedagemStatusObj, setHospedagemStatusObj] = useState<HospedagemStatusEntity | null>(null);

    //DEFINIÇÃO DAS COLUNAS
    const tabelaHospedagemStatus: ColumnsType<HospedagemStatusEntity> = [
        {
            title: "Nome",
            dataIndex: "nome"
        },        
        {
            title: "Cor",
            dataIndex: "cor",
            render: (_, { cor }) => (
                <>                    
                    <Tag color={cor}>
                        {cor}
                    </Tag>
                </>
            ),
        },        
        {            
            render: (hospedagemStatusSel: HospedagemStatusEntity) => (
                <>
                    <Popconfirm placement='topLeft'
                        title={<span>Você confirma a EXCLUSÃO do status<br /><strong>{hospedagemStatusSel.nome}</strong></span>}
                        onConfirm={() => excluir(hospedagemStatusSel)}>
                        <Button size='small' type='primary' danger icon={<FaTrash />}>Excluir</Button>
                    </Popconfirm>

                    <Button size='small' type='primary' icon={<FaEdit />} onClick={() => editarHospedagemStatus(hospedagemStatusSel)}>Editar</Button>
                </>
            )
        }
    ];

    const carregaHospedagemStatus = useCallback(async () => {
        let result: Result | null = await app.ajaxApi("GET", `hospedagem/status`);
        if (result?.code === 200) {
            setHospedagemStatusLst(result.dados);
        }
    }, [app]);

    const excluir = useCallback(async (hospedagemStatusSel: HospedagemStatusEntity) => {
        let result: Result | null = await app.ajaxApi("DELETE", `hospedagem/status/${hospedagemStatusSel.id}`);

        if (result?.code === 200) {
            carregaHospedagemStatus();
        }
    }, [app, carregaHospedagemStatus]);

    const editarHospedagemStatus = useCallback(async (hospedagemStatusSel: HospedagemStatusEntity) => {
        setModal(true);
        setHospedagemStatusObj(hospedagemStatusSel);
    }, []);

    const novoHospedagemStatus = useCallback(() => {
        setModal(true);
        setHospedagemStatusObj(null);

    }, []);

    useEffect(() => {
        carregaHospedagemStatus();
    }, [carregaHospedagemStatus]);

    return (
        <>
            <h1>
                <AiFillSignal />
                Gerenciar Status de Hospedagem
            </h1>

            <div className="submenu">
                <Button type='primary' className='button-success' onClick={novoHospedagemStatus}>
                    <FaPlus />Novo Status
                </Button>
            </div>

            <Table size='small' pagination={{ pageSize: 100 }} columns={tabelaHospedagemStatus} dataSource={hospedagemStatusLst}></Table>

            <Modal open={modal} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModal(false)}>
                <HospedagemStatusForm data={hospedagemStatusObj} onClose={() => setModal(false)} onSuccess={carregaHospedagemStatus} />
            </Modal>
        </>
    )
}

export default HospedagemStatus;