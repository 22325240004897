import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppProvider } from './providers/AppProvider';
import Routes from './routes/Routes';



function App() {

	// useEffect(() => {
	// 	let root = document.documentElement;	
	// 	root.style.setProperty('--cor-1', "#FFFF00");
	// }, []);


  return (
		<BrowserRouter>
			<AppProvider>
				<Routes />
			</AppProvider>
		</BrowserRouter>
  );
}

export default App;
