import React from 'react';
import { Route, Routes as RoutesDOM } from "react-router-dom";
import LayoutAdmin from '../layouts/Admin/LayoutAdmin';
import LayoutBlank from '../layouts/Blank/LayoutBlank';
import Arquivos from '../areas/admin/pages/Arquivos/Arquivos';
import CadastroDetalhes from '../areas/admin/pages/Cadastros/CadastroDetalhes';
import MeusDados from '../areas/global/pages/MeusDados/MeusDados';
import Painel from '../areas/admin/pages/Painel/Painel';
import Login from '../areas/global/pages/Auth/Login';
import RenderPage from './RenderPage';
import Configuracoes from '../areas/admin/pages/Configuracoes/Configuracoes';
import Areas from '../areas/global/pages/Auth/Areas';
import AcessoNegado from '../areas/global/pages/AcessoNegado/AcessoNegado';
import EsqueciMinhaSenha from '../areas/global/pages/Auth/EsqueciMinhaSenha';
import LayoutLogin from '../layouts/Login/LayoutLogin';
import RecuperaSenha from '../areas/global/pages/Auth/RecuperaSenha';
import AereoCompanhias from '../areas/admin/pages/AereoCompanhias/AereoCompanhias';
import CadastrosAdmin from '../areas/admin/pages/Cadastros/CadastrosAdmin';
import CadastrosParticipantes from '../areas/admin/pages/Cadastros/CadastrosParticipantes';
import CamposParticipantes from '../areas/admin/pages/Campos/CamposParticipantes';
import Aereos from '../areas/admin/pages/Aereos/Aereos';
import Proibido from '../areas/global/pages/Proibido/Proibido';
import Logs from '../areas/admin/pages/Logs/Logs';
import FollowUp from '../areas/admin/pages/FollowUp/FollowUp';
import Hoteis from '../areas/admin/pages/Hotel/Hoteis';
import Hospedagem from '../areas/admin/pages/Hospedagem/Hospedagens';
import MeusFollowUps from '../areas/admin/pages/MeusFollowUps/MeusFollowUps';
import FollowUpDetalhes from '../areas/admin/pages/FollowUp/FollowUpDetalhes';




const Routes: React.FC = () => {

	return (
		<RoutesDOM>
			<Route path='/' element={<RenderPage component={Login} layout={LayoutLogin} />}></Route>
			<Route path='/auth/areas' element={<RenderPage component={Areas} layout={LayoutBlank} isPrivate />}></Route>
			<Route path='/auth/esqueci-minha-senha' element={<RenderPage component={EsqueciMinhaSenha} layout={LayoutLogin} />}></Route>
			<Route path='/auth/recupera-senha/:token' element={<RenderPage component={RecuperaSenha} layout={LayoutLogin} />}></Route>
			

			{/** ********** ADMIN ********** */}
			<Route path='/follow-up' element={<RenderPage component={MeusFollowUps} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/follow-up/detalhes/:id' element={<RenderPage component={FollowUpDetalhes} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			
			
			
			<Route path='/admin/painel' element={<RenderPage component={Painel} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/configuracoes' element={<RenderPage component={Configuracoes} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/arquivos' element={<RenderPage component={Arquivos} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>			
			
			<Route path='/admin/cadastros/sistema' element={<RenderPage component={CadastrosAdmin} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/cadastros/participantes' element={<RenderPage component={CadastrosParticipantes} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>


			<Route path='/admin/campos/participantes' element={<RenderPage component={CamposParticipantes} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			
			
			
			<Route path='/admin/cadastro/detalhes/:id' element={<RenderPage component={CadastroDetalhes} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>

			<Route path='/admin/aereo/companhias' element={<RenderPage component={AereoCompanhias} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/aereo' element={<RenderPage component={Aereos} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			
			
			<Route path='/admin/meus-dados' element={<RenderPage component={MeusDados} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>

			<Route path='/admin/logs' element={<RenderPage component={Logs} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/follow-up' element={<RenderPage component={FollowUp} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			
			<Route path='/admin/hotel' element={<RenderPage component={Hoteis} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			<Route path='/admin/hospedagem' element={<RenderPage component={Hospedagem} isPrivate areas={[ "Admin" ]} layout={LayoutAdmin} />}></Route>
			


			<Route path='/acesso-negado' element={<RenderPage component={AcessoNegado} layout={LayoutBlank} />}></Route>
			<Route path='/proibido' element={<RenderPage component={Proibido} layout={LayoutBlank} />}></Route>
			



		</RoutesDOM>
	);
}

export default Routes;