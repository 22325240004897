import { Button, Col, Popconfirm, Row, Tag, UploadProps } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import convertSize from "convert-size";
import { MdStorage } from 'react-icons/md';
import { Arquivo } from '../../../../entities/arquivo';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import { FaCloudUploadAlt, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import ArquivoInfo from '../../../../components/ArquivoInfo';
import Dragger from 'antd/lib/upload/Dragger';
import Modal from 'antd/lib/modal/Modal';
import ArquivoAlteraNomeForm from '../../forms/Arquivo/ArquivoAlteraNomeForm';

const Arquivos: React.FC = () => {


	// ***** Configurações da tabela
	const columns: ColumnsType<Arquivo> = [
		{
			title: "Nome",
			render: (arquivo: Arquivo) => (
				<div className='table-field-edit'>
					{arquivo.privado ? <Tag color="red">Privado</Tag> : <Tag color="green">Público</Tag>}
					{arquivo.nome}
					<button onClick={() => alteraNome(arquivo)}>
						<FaEdit />
					</button>
				</div>
			)
		},		
		{
			title: "Tipo",
			dataIndex: "tipo"
		},
		{
			title: "Tamanho",
			render: (arquivo: Arquivo) => (
				<>
					{convertSize(arquivo.tamanho,{ accuracy: 2 })}
				</>
			)
		},
		{
			title: "",
			render: (arquivo: Arquivo) => (
				<>
					<Popconfirm  placement="topRight" title="Você confirma a EXCLUSÃO deste arquivo?" onConfirm={() => excluirArquivo(arquivo)}>
						<Button size='small' danger type='primary'>
							<FaTrash />
							Excluir
						</Button>
					</Popconfirm>

					<Button onClick={() => visualizar(arquivo)}>
						<FaEye />
						Visualizar
					</Button>
				</>
			)
		}
	];


	// ***** Configurações do upload PRIVADO
	const propsPrivado: UploadProps = {
		name: 'arquivo',		
		multiple: true,				
		action: `${process.env.REACT_APP_URL_API}/arquivo/upload`,
		headers: { 
			"Tenant": process.env.REACT_APP_TENANT ? process.env.REACT_APP_TENANT : ``,
			"Authorization": `Bearer ${sessionStorage.getItem("token")}`
		},
		data: { privado: true }		
	  };


	// ***** COnfiguração do upload PÚBLICO
	const propsPublico: UploadProps = {
		name: 'arquivo',		
		multiple: true,				
		action: `${process.env.REACT_APP_URL_API}/arquivo/upload`,
		headers: { 
			"Tenant": process.env.REACT_APP_TENANT ? process.env.REACT_APP_TENANT : ``,
			"Authorization": `Bearer ${sessionStorage.getItem("token")}`
		},
		data: { privado: false }		
	};

	
	// ***** Providers
	const app = useApp();


	// ***** States
	const [arquivos, setArquivos] = useState<Arquivo[]>();
	const [arquivo, setArquivo] = useState<Arquivo | null>(null);
	const [modalAlteraNome, setModalAlteraNome] = useState(false);



	// ***** FUNÇÕES
	const carregaArquivos = useCallback(async() => {
		let result: Result | null = await app.ajaxApi("get", "arquivo");
		setArquivos(result?.dados);		
	}, [app]);


	// Visualizar arquivo
	const visualizar = useCallback(async (arquivo: Arquivo) => {
		if (!arquivo.privado) {
			// ***** Arquivo PÚBLICO
			window.open(`${process.env.REACT_APP_CLOUDFRONT}/${process.env.REACT_APP_TENANT}/${arquivo.source}`, "_blank");
		} else {
			// ***** Arquivo PRIVADO
			let result: Result | null = await app.ajaxApi("get", `arquivo/privado/${arquivo.id}`);
			if (result?.code === 200) {
				window.open(`${result.dados.url}`, "_blank");						
			}			
		}
	}, [app]);


	// Função para exlcuir o arquivo
	const excluirArquivo = useCallback(async (arquivo: Arquivo) => {
		let result: Result | null = await app.ajaxApi("delete", `/arquivo/${arquivo.id}`);
		if (result?.code === 200) {
			carregaArquivos();
		}
	}, [app, carregaArquivos]);


	// Função chamada a cada upload de arquivo com sucesso, para incluir o item na tabela
	const atualizaLista = useCallback((info:any) => {		
		carregaArquivos();
	}, [carregaArquivos]);


	// Abre o modal para alteração no nome
	const alteraNome = useCallback((arquivo: Arquivo) => {
		setModalAlteraNome(true);
		setArquivo(arquivo);
	}, []);


	// Função chamada quando o nome é alterado com sucesso, e alteramos o valor do item na tabela
	const alteraNomeSuccess = useCallback((arquivo: Arquivo) => {
		let listaArquivos = arquivos;
		let arq = listaArquivos?.find((item: Arquivo) => {
			if (item.id === arquivo.id)
				return true;

			return false;
		});

		if (arq?.nome)
			arq.nome = arquivo.nome;
		
		setArquivos(listaArquivos);
	}, [arquivos]);




	// ***** HOOKS
	useEffect(() => {
		carregaArquivos();
	}, [carregaArquivos]);



	return (
		<>
			<h1>
				<MdStorage />
				Gerenciador de Arquivos
			</h1>

			<Row gutter={15} style={{ marginBottom: "25px" }}>
				<Col md={12}>
					<Dragger {...propsPublico} height={100} onChange={atualizaLista} style={{ backgroundColor: "#f8ffee" }}>
						<div>
						<FaCloudUploadAlt size={40} color="#95ce5a" />
						<h2 style={{ color: "#95ce5a" }}>Arquivos Públicos</h2>
						</div>
						<p>Clique aqui ou arraste o arquivo para esta área para fazer upload</p>						
					</Dragger>

					<Dragger {...propsPrivado} height={100} onChange={atualizaLista} style={{ backgroundColor: "#fdf1f0", marginTop: "15px" }}>
						<div>
							<FaCloudUploadAlt size={40} color="#e26d64" />
							<h2 style={{ color: "#e26d64" }}>Arquivos Privados</h2>
						</div>
						<p>Clique aqui ou arraste o arquivo para esta área para fazer upload</p>						
					</Dragger>

					
				</Col>

				<Col md={12}>
					<ArquivoInfo showGerenciarArquivos={false} />
				</Col>
			</Row>

			<Table columns={columns} dataSource={arquivos} rowKey="id" />

			<Modal footer={false} destroyOnClose closable maskClosable={false} open={modalAlteraNome} onCancel={() => setModalAlteraNome(false)}>
				<ArquivoAlteraNomeForm data={arquivo} onClose={() => setModalAlteraNome(false)} onSuccess={alteraNomeSuccess} />
			</Modal>
		</>
	);
}

export default Arquivos;