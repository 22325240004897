import { Tag } from 'antd';
import React from 'react';
import { ParticipanteAereo } from '../entities/participante-aereo';

interface ParticipanteAereoStatusProps {
	participanteAereo: ParticipanteAereo
}

const ParticipanteAereoStatus: React.FC<ParticipanteAereoStatusProps> = ({
	participanteAereo
}) => {
	return (
		<>
			{participanteAereo.participanteAereoStatusId === 1 && <Tag color="warning">{participanteAereo.participanteAereoStatusNome}</Tag>}
			{participanteAereo.participanteAereoStatusId === 2 && <Tag color="green">{participanteAereo.participanteAereoStatusNome}</Tag>}
			{participanteAereo.participanteAereoStatusId === 3 && <Tag color="red">{participanteAereo.participanteAereoStatusNome}</Tag>}
			{participanteAereo.participanteAereoStatusId === 10 && <Tag>{participanteAereo.participanteAereoStatusNome}</Tag>}
		</>
	);
}

export default ParticipanteAereoStatus;