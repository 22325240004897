import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import { Button, message, Modal, Popconfirm, Space, Tooltip } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, {	useCallback,useEffect,useMemo,useRef,useState,} from "react";
import {FaEdit,	FaFileCsv,FaFilePdf,FaHotel,FaPlus,	FaTrash,} from "react-icons/fa";
import { Hotel } from "../../../../entities/Hotel";
import { useApp } from "../../../../providers/AppProvider";
import { Result } from "../../../../interfaces/Result";
import HotelEditar from "../../components/HotelEditar";
import { isVisible } from "@testing-library/user-event/dist/utils";

const Hoteis: React.FC = () => {
	const app = useApp();
	const [hoteis, setHoteis] = useState<Hotel[]>([]);
	const [hotelSel, setHotelSel] = useState<Hotel | null>(null);
	const [modalEditar, setModalEditar] = useState(false);
	const [hoteisExp, setHoteisExp] = useState<Hotel[]>([]);
	const [desabilitaBotao, setDesabilitaBotao] = useState(true);

	const tabelaHoteis: ColumnsType<Hotel> = [
		{
			title: "Nome",
			dataIndex: "nome",
		},
		{
			title: "Cidade",
			dataIndex: "cidade",
		},
		{
			title: "UF",
			dataIndex: "estado",
		},
		{
			render: (hotel: Hotel) => (
				<>
					<Button
						type="primary"
						icon={<FaEdit />}
						onClick={() => editarHotel(hotel)}
					>
						Editar
					</Button>
					<Popconfirm
						placement="topLeft"
						title={
							<span>
								Você confirma a EXCLUSÃO do hotel
								<br />
								<strong>{hotel.nome}</strong>
							</span>
						}
						onConfirm={() => excluirHotel(hotel)}
					>
						<Button
							size="small"
							type="primary"
							danger
							className="button-icon"
						>
							<FaTrash />
						</Button>
					</Popconfirm>
				</>
			),
		},
	];

	const carregarHoteis = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", "hotel/listar");
		if (result?.code === 200) {
			//setDesabilitaBotao(false);
			setHoteis(result.dados);
		}
	}, [app]);

	const editarHotel = useCallback((hotel: Hotel) => {
		setHotelSel(hotel);
		setModalEditar(true);
	}, []);

	const novoHotel = useCallback(async () => {
		setHotelSel(null);
		setModalEditar(true);
	}, []);

	// FUNÇÕES PARA EXPORTAR CSV E PSF
	useMemo(() => {
		let lstHoteis: any = [];

		hoteis?.forEach((item) => {
			let aux = {
				Nome: item.nome,
				Logradouro: `${item.logradouro} Nª ${item.numero}`,
				Bairro: item.bairro,
				Cidade: item.cidade,
				uf: item.estado,
				Pais: item.pais,
				CEP: item.cep,
			};
			lstHoteis.push(aux);
		});

		if (lstHoteis.length > 0) {
			setDesabilitaBotao(false);
		} else {
			setDesabilitaBotao(true);			
		}
		setHoteisExp(lstHoteis);			
	}, [hoteis]);

	const componentRef = useRef<any>();
	const componentRodape = useRef<any>();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const preparaPDF = useCallback(() => {
		componentRodape.current(isVisible);
		handlePrint();
	}, [handlePrint]);
	// FIM FUNÇÕES

	const carregarDados = useCallback(() => {
		carregarHoteis();
	}, [carregarHoteis]);

	const excluirHotel = useCallback(
		async (hotel: Hotel) => {
			let result: Result | null = await app.ajaxApi("DELETE",`hotel/${hotel.id}`);
			if (result?.code === 200) {
				carregarDados();
			}
		},
		[app, carregarDados]
	);

	useEffect(() => {
		carregarDados();
	}, [carregarDados]);

	return (
		<>
			<div>Hoteis</div>
			<h1>
				<FaHotel />
				Hotéis
			</h1>

			<div className="submenu">
				<Button type="primary" className="button-success" onClick={novoHotel}>
					<FaPlus />
					Novo
				</Button>
				<Space style={{ float: "right" }}>
					<Tooltip placement="topLeft" zIndex={0} open={desabilitaBotao}	title={"Habilita quando houver hotéis cadastrados"}>
						<Button type="primary" disabled={desabilitaBotao}>
							<CSVLink
								separator=";"
								filename={"Hotéis.csv"}
								data={hoteisExp}
								className="btn btn-primary"
								onClick={() => {
									message.success("Baixando arquivo");
								}}
							>
								<FaFileCsv />
								Exportar CSV
							</CSVLink>
						</Button>
					</Tooltip>
					{/* <Button onClick={handlePrint} type="primary" danger><FaFilePdf /> Exportar PDF </Button> */}
				</Space>
			</div>

			<div style={{ width: "100%" }}>
				<div ref={componentRef}>
					{hoteis && (
						<Table
							columns={tabelaHoteis}
							dataSource={hoteis}
							defaultExpandAllRows={true}
							size="small"
							pagination={{ pageSize: 100 }}
						/>
					)}

					<div style={{ display: "none" }}>
						<h2>rodapé</h2>
					</div>
				</div>
			</div>

			{/* <Table defaultExpandAllRows={true} size='small' pagination={{pageSize: 100}} columns={tabelaHoteis} dataSource={hoteis}></Table> */}

			<Modal
				width={1000}
				open={modalEditar}
				footer={false}
				closable
				destroyOnClose
				onCancel={() => setModalEditar(false)}
			>
				<HotelEditar
					data={hotelSel}
					onClose={() => setModalEditar(false)}
					onSuccess={carregarDados}
				/>
			</Modal>
		</>
	);
};

export default Hoteis;
