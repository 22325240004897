import { Input } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { InputCustomProps } from '../interfaces/InputCustomProps';



const InputHora: React.FC<InputCustomProps> = ({
	value,
	onChange
}) => {

	useEffect(() => {		
		$(".maskHora").mask("00:00");
	}, []);


	const atualiza = useCallback((e: any) => {
		if (onChange)
			onChange(e.target.value);
			
	}, [onChange]);
	
	return (
		<>
			<Input className='maskFloat' onChange={(e) => atualiza(e)} onBlur={(e) => atualiza(e)} value={value} onKeyUp={(e) => atualiza(e)} />
		</>
	);
}

export default InputHora;