import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { AdminCategoria } from '../../../entities/admin-categoria';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';


interface AdminCategoriaEditarProps {
	adminCategoria?: AdminCategoria | null, 
	onSuccess?(categoria: AdminCategoria): void,
	onClose?(): void,
}


const AdminCategoriaEditar: React.FC<AdminCategoriaEditarProps> = ({
	adminCategoria,
	onSuccess,
	onClose
}) => {

	const app = useApp();
	const [form] = useForm();

	const [loading, setLoading] = useState(false);


	const salvar = useCallback(async (dataForm: any) => {
		setLoading(true);
		let result: Result | null

		if (adminCategoria) {
			// ***** ALTERAR
			result = await app.ajaxApi("PUT", `admin/categoria/${adminCategoria.id}`, dataForm);
		} else {
			// ***** INSERIR
			result = await app.ajaxApi("POST", `admin/categoria`, dataForm);
		}

		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);

			if (onClose)
				onClose();
		}

		setLoading(false);
		
	}, [onSuccess, onClose, adminCategoria, app]);



	useEffect(() => {
		if (adminCategoria)
			form.setFieldsValue(adminCategoria);
	}, [adminCategoria, form]);



	return (
		<>
			<h2>
				<FaEdit />
				Editar Categoria
			</h2>	

			<Form form={form} onFinish={salvar}>
				<FormItem label="Nome" name="nome" rules={[
					{ required: true, message: "Informe o nome da categoria" }
				]}>
					<Input />
				</FormItem>

				<div className="acoes">
					{onClose && (
						<Button className='button-default' type='primary' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}

					<Button className='button-success' type='primary' icon={<FaCheck />} htmlType='submit' loading={loading}>Salvar</Button>
				</div>
			</Form>
		</>
	);
}

export default AdminCategoriaEditar;