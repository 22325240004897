import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineNotification } from "react-icons/ai";
import { useApp } from "../../../../providers/AppProvider";
import { Result } from "../../../../interfaces/Result";
import { Pie } from "@ant-design/charts";

// import { Container } from './styles';

const DashboardFollowUp: React.FC = () => {

	const app = useApp();		
	const [config, setConfig] = useState<any | null>(null);	

	const CarregarDashboardFollowup = useCallback(async () => {		
		let auxData: any = [];
		let result: Result | null = await app.ajaxApi("GET", "dashboard/followups");
		if (result?.code === 200) {
			result.dados?.forEach((item: any) => {
				auxData.push({
					type: item[0].followUpStatus.nome,								
					value: item.length,					
				});		
			});
			
			if (auxData.length === 0) {
				auxData.push({
					type: 'Sem Dados',								
					value: 0,					
				});	
			}
			//console.log(auxData);
		}
		
		const aux = {
			appendPadding: 10,
			data: auxData,																
			angleField: 'value',
			colorField: 'type',
			radius: 0.66,							
			interactions: [
				{
					type: 'element-active',
				},
			],
		};
		setConfig(aux);			
	}, [app]);

	useEffect(() => {
		if (!config) {
			CarregarDashboardFollowup();											
		}					
	}, [CarregarDashboardFollowup, config])

	return (
		<>
			<div className="box">
				<h2>
					<AiOutlineNotification />
					Follow Ups
				</h2>
				{ 
					config && 
					<Pie {...config} /> 
				}
			</div>
		</>
	);
};

export default DashboardFollowUp;
