import { Pie } from "@ant-design/charts";
import React, { useCallback, useEffect, useState } from "react";
import { MdAirplaneTicket } from "react-icons/md";
import { Result } from "../../../../interfaces/Result";
import { useApp } from "../../../../providers/AppProvider";

const DashboardAereos: React.FC = () => {
	const app = useApp();
	const [config, setConfig] = useState<any | null>(null);
	const [aereos, setAereos] = useState<any | null>(null);

	const CarregarDashboardAereo = useCallback(async () => {
		let auxData: any = [];
		let result: Result | null = await app.ajaxApi("GET", "dashboard/aereos");
		let lstAereos: any = [];			
		if (result?.code === 200) {									
			result.dados?.forEach((item: any) => {				
				let aereo: any = null;
				aereo = {
					aereoCompanhia: item.aereoCompanhia.nome + ' - Voo de ' + item.aereoTipoNome,
	  				numVoo: item.numVoo,
	 				lugares: item.lugares,
	 				lugaresDisponiveis: item.lugaresDisponiveis,	
					aereoStatusNome: item.aereoStatusNome,
					origem: item.origemAeroporto + ' - ' + item.origemCidade,
					destino: item.destinoAeroporto + ' - ' + item.destinoCidade,
					aereoTipoNome: item.aereoTipoNome,
					embarque: item.dataHoraEmbarque,
					desembarque: item.dataHoraDesembarque,
				};					
				lstAereos.push(aereo);
			});
			
			//console.log(lstHospedagem);
			setAereos(lstAereos);
			
			lstAereos.forEach((item: any) => {				
				auxData.push(
					{
						type: item.numVoo,
  			            value: parseInt(item.numVoo),				
					}
				);			
			});

			if (auxData.length === 0) {
				auxData.push({
					type: 'Sem Dados',								
					value: 0,					
				});	
			}
		}
		
		var aux = {
			appendPadding: 10,
			data: auxData,
			angleField: "value",
			colorField: "type",
			radius: 1,
			innerRadius: 0.6,
			label: {
				type: "inner",
				offset: "-50%",
				content: "{value}",
				style: {
					textAlign: "center",
					fontSize: 14,
				},
			},
			interactions: [
				{
					type: "element-selected",
				},
				{
					type: "element-active",
				},
			],
			statistic: {
				title: false,
				content: {
					style: {
						whiteSpace: "pre-wrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
						fontSize: 20
					},
					content: auxData[0].value === 0 ? "Sem Dados" : "Aéreos - Nº Voos",
				},
			},
			
		};

		setConfig(aux);
	}, [app]);

	useEffect(() => {
		if (!aereos) {
			CarregarDashboardAereo();			
		}
	}, [CarregarDashboardAereo, aereos]);

	return (
		<>
			<div className="box">
				<h2>		
					<MdAirplaneTicket />				
					Detalhes Aereos
				</h2>			
				{
					config && <Pie tooltip={{customContent(title, data) {					
						let item: any = aereos.find((i: any) => {						
							if (i.numVoo === title) {
								return i.numVoo ;
							}
							else{
								return false;							
							}
						})
						return (
							<>									
								<p>Voo Nº {title}</p>	
								<p>{item?.aereoCompanhia}</p>	
								<p>Nº Voo: {item?.numVoo}</p>	
								<p>Nº Lugares: {item?.lugares}</p>	
								<p>Nº Disponíveis: {item?.lugaresDisponiveis}</p>	
								<p>Origem: {item?.origem}</p>	
								<p>Destino: {item?.destino}</p>	
								<p>Status: {item?.aereoStatusNome}</p>	
								<p>Embarque: {item?.embarque}</p>	
								<p>Desembarque: {item?.desembarque}</p>	
							</>
						)
					},}} {...config} />
				}
			</div>
		</>
	);
};

export default DashboardAereos;
