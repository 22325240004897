import React from 'react';

import './LayoutLogin.css';

interface LayoutLoginProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const LayoutLogin: React.FC<LayoutLoginProps> = ({ children }) => {
  return (
	  <>
	  	<div className="auth-login">

			<div className="logo">
				LOGO EMPRESA
			</div>

			<div className="box-login">
				{children}
			</div>
		</div>
	  </>
  );
}

export default LayoutLogin;