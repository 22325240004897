/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { FaChevronCircleLeft, FaChevronCircleRight, FaCogs, FaHotel, FaSignOutAlt, FaUser, FaUsers, FaUserShield } from 'react-icons/fa';
import { IoAirplane } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { useApp } from '../../providers/AppProvider';

import './LayoutAdmin.css';
import { AiOutlineClockCircle, AiOutlineDashboard, AiOutlineNotification } from 'react-icons/ai';
import { MdAirplaneTicket, MdAppRegistration, MdLocalHotel } from 'react-icons/md';
import { Badge } from 'antd';


interface LayoutAdminProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}


const LayoutAdmin: React.FC<LayoutAdminProps> = ({ children }) => {

	const app = useApp();
	const navigate = useNavigate();


	const [recolhe, setRecolhe] = useState(false);


	const logout = useCallback(() => {
		app.logout();
		navigate("/");
	}, [app, navigate]);


	useEffect(() => {
		app.calculaFollowUps();	
	}, []);


	return (
		<div className='admin'>
			<header>
				<div className='logo'>
					Logado como: <span>{app.auth?.nome}</span>
				</div>

				<nav>	
					<Badge size='small' count={app.notificationFollow?.badge === "andamento" ? <AiOutlineClockCircle style={{ color: '#ffb052' }} /> : app.notificationFollow?.badge } style={{ marginRight: 10 }}>
						<Link to="/follow-up">
							<AiOutlineNotification />							
							Follow Up							
						</Link>
					</Badge>

					<Link to="/admin/configuracoes">
						<FaCogs />
						Configurações
					</Link>

					<Link to="/admin/meus-dados">
						<FaUser />
						Meus Dados
					</Link>

					<a onClick={logout}>
						<FaSignOutAlt />
						Sair
					</a>
				</nav>
			</header>

			<main>
				<nav className={!recolhe ? 'open' : 'close'}>
					<div className="logo">
						{!recolhe ? `LOGO EMPRESA` : `L`}
					</div>

					<a className='bt-recolhe' onClick={() => setRecolhe(!recolhe)}>
						{!recolhe ? <FaChevronCircleLeft /> : <FaChevronCircleRight />}
						<span>Recolher</span>
					</a>

					<Link to="/admin/painel" title='Dashboard'>						
						<AiOutlineDashboard />
						<span>Dashboard</span>
					</Link>		
					
					{(app.verificaAcao("cadastros-admin-visualizar") || app.verificaAcao("cadastros-participantes-visualizar")) && (
						<h2>Cadastros</h2>
					)}

					{ app.verificaAcao("cadastros-admin-visualizar") && (
						<Link to="/admin/cadastros/sistema" title='Usuários do Sistema'>
							<FaUserShield />
							<span>Usuários do Sistema</span>
						</Link>	
					)}					

					{ app.verificaAcao("cadastros-participantes-visualizar") && (
						<Link to="/admin/cadastros/participantes" title='Participantes'>
							<FaUsers />
							<span>Participantes</span>
						</Link>
					)}


						


					<h2>Aéreos</h2>

					<Link to="/admin/aereo/companhias" title='Companhias Aéreas'>
						<IoAirplane />
						<span>Companhias Aéreas</span>
					</Link>		

					<Link to="/admin/aereo" title='Aéreos'>
						<MdAirplaneTicket />
						<span>Aéreos</span>
					</Link>

					<h2>Hotéis</h2>

					<Link to="/admin/hotel" title='Hotéis'>
						<FaHotel />
						<span>Hotéis</span>
					</Link>

					<Link to="/admin/hospedagem" title='Hospedagens'>
						<MdLocalHotel />
						<span>Hospedagens</span>
					</Link>

					<br /><br />
					<Link to="/admin/follow-up">
						<AiOutlineNotification />
						<span>Follow Up</span>
					</Link>

					{ app.verificaAcao("sistema-configuracoes-logs") && (
						<Link to="/admin/logs" title='Logs do Sistema'>
							<MdAppRegistration />
							<span>Logs do Sistema</span>
						</Link>
					)}
					
				</nav>

				<section>
					{children}
				</section>
			</main>

			<footer>
				Motu Inteligência Digital &copy; 2022
			</footer>			
		</div>
	);
}

export default LayoutAdmin;