import { Button, Modal, notification, Popconfirm, Table, Tag } from 'antd';
import update from 'immutability-helper';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { FaArrowLeft, FaEdit, FaList, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { Campo } from '../../../../entities/campo';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import CampoEditar from '../../components/CampoEditar';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CampoOpcoes from '../../components/CampoOpcoes';



interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	index: number;
	moveRow: (dragIndex: number, hoverIndex: number) => void;
}

// ***** Define constantes
const type = 'DraggableBodyRow';



const CamposParticipantes: React.FC = () => {

	const app = useApp();
	const [campos, setCampos] = useState<Campo[]>([]);
	const [modalEditar, setModalEditar] = useState(false);
	const [modalOpcoes, setModalOpcoes] = useState(false);
	const [loadingPosicao, setLoadingPosicao] = useState(false);
	const [campoSelecionado, setCampoSelecionado] = useState<Campo | null>(null);



	// ***** Configura o arrasta e solta para a ordenação na tabela
	const DraggableBodyRow = ({
		index,
		moveRow,
		className,
		style,
		...restProps
	}: DraggableBodyRowProps) => {

		const ref = useRef<HTMLTableRowElement>(null);
		const [{ isOver, dropClassName }, drop] = useDrop({
		  accept: type,
		  collect: monitor => {
			const { index: dragIndex } = monitor.getItem() || {};
			if (dragIndex === index) {
			  return {};
			}
			return {
			  isOver: monitor.isOver(),
			  dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
			};
		  },
		  drop: (item: { index: number }) => {
			moveRow(item.index, index);
		  },
		});
		const [, drag] = useDrag({
		  type,
		  item: { index },
		  collect: monitor => ({
			isDragging: monitor.isDragging(),
		  }),
		});
		drop(drag(ref));
	  
		return (
		  <tr
			ref={ref}
			className={`${className}${isOver ? dropClassName : ''}`}		
			style={{ cursor: 'move' }}	
			{...restProps}
		  />
		);
	};

	



	// ***** Função para salvar as posições das etapas definidas na tabela
	const salvaPosicao = useCallback(async () => {
		setLoadingPosicao(true);
		
		let ids: string[] = [];
		campos.forEach((campo: Campo) => {
			ids.push(campo.id);
		});

		let result: Result | null = await app.ajaxApi("put", `campo/salvar-posicao`, {
			tabela: "Participante",
			ids
		});
		
		if (result?.code === 200) {
			notification.success({
				message: "Posições salvas",
				duration: 5,
				placement: "bottomRight"
			});
		}

		setLoadingPosicao(false);		
	}, [campos, app]);






	// ***** Função executada quando é alterado a ordem de um item na tabela
	const moveRow = useCallback(
		(dragIndex: number, hoverIndex: number) => {
		  const dragRow = campos[dragIndex];
		  setCampos(
			update(campos, {
			  $splice: [
				[dragIndex, 1],
				[hoverIndex, 0, dragRow],
			  ],
			}),
		  );		  
	}, [campos]);



	// Configura TABELA
	const tabelaCampos: ColumnsType<Campo> = [
		{			
			width: 50,
			render: (campo: Campo, record, index) => (
				<div style={{ textAlign: 'center' }}>
					{index + 1}
				</div>
			)
		},
		{
			title: "Nome",
			dataIndex: "nome"
		},
		{
			title: "Tipo de Dado",
			dataIndex: "tipoNome"
		},
		{
			title: "Obrigatório",
			render: (campo: Campo) => (
				<>
					{campo.obrigatorio ? (
						<Tag color="green">Sim</Tag>
					) : (
						<Tag color="red">Não</Tag>
					)}
				</>
			)
		},
		{
			render: (campo: Campo) => (
				<>
					{(campo.tipo === 7 || campo.tipo === 8) && (
						<Button type='primary' className='button-default' icon={<FaList />} onClick={() => editarOpcoes(campo)}>Opções</Button>
					)}
					<Popconfirm title={<span>Você confirma a EXCLUSÃO do campo?<br /><strong>{campo.nome}</strong></span>} placement='topLeft' onConfirm={() => excluir(campo)}>
						<Button type='primary' danger icon={<FaTrash />}>Excluir</Button>
					</Popconfirm>

					<Button type='primary' icon={<FaEdit />} onClick={() => editarCampo(campo)}>Editar</Button>
				</>
			)
		}
	];



	const carregaCampos = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", "campo/listar/participante");
		if (result?.code === 200) 
			setCampos(result.dados);
	}, [app]);



	const excluir = useCallback(async (campo: Campo) => {
		let result: Result | null = await app.ajaxApi("DELETE", `campo/${campo.id}`);
		if (result?.code === 200) {
			carregaCampos();
			notification.success({
				message: "Campo excluído",
				placement: 'bottomRight',
				duration: 5
			});
		}
	}, [carregaCampos, app]);



	const novoCampo = useCallback(() => {
		setModalEditar(true);
		setCampoSelecionado(null);
	}, []);



	const editarCampo = useCallback((campo: Campo) => {
		setModalEditar(true);
		setCampoSelecionado(campo);
	}, []);	



	const editarOpcoes = useCallback((campo: Campo) => {
		setModalOpcoes(true);
		setCampoSelecionado(campo);
	}, []);	


	const fecharModalOpcoes = useCallback(() => {
		setModalOpcoes(false);
		carregaCampos();
	}, [carregaCampos]);	



	useEffect(() => {
		carregaCampos();
	}, [carregaCampos]);



	return (
		<>
			<h1>
				<MdOutlineDashboardCustomize />
				Campos Personalizados - Participantes
			</h1>

			<div className="submenu">
				<nav>
					<Button type='primary' className='button-default' onClick={app.goBack}>
						<FaArrowLeft />
						Voltar
					</Button>

					<Button type='primary' className='button-success' onClick={novoCampo}>
						<FaPlus />
						Novo Campo
					</Button>
				</nav>
			</div>


			<DndProvider backend={HTML5Backend}>
				<Table size='small' pagination={false} columns={tabelaCampos} dataSource={campos} 
					components={{
						body: {
						  row: DraggableBodyRow,
						},
					}} 
					onRow={(_, index) => {
						const attr = {
							index,
							moveRow,
						};
						return attr as React.HTMLAttributes<any>;
					}}
					rowClassName={(campo: Campo) => {
						if (campo.oculto) {
							return "excluido";
						}
						return "";
					}}
				/>	
			</DndProvider>

			<div style={{ marginTop: '10px' }}>
				<Button type='primary' className='button-success' icon={<FaSave />} loading={loadingPosicao} onClick={salvaPosicao}>Salvar Posições</Button>
			</div>

			<Modal width={700} closable destroyOnClose maskClosable={false} open={modalEditar} onCancel={() => setModalEditar(false)} footer={false}>
				<CampoEditar data={campoSelecionado} tabela='Participante' onSuccess={carregaCampos} onClose={() => setModalEditar(false)} />
			</Modal>

			<Modal width={700} closable destroyOnClose maskClosable={false} open={modalOpcoes} footer={false} onCancel={fecharModalOpcoes}>
				<CampoOpcoes campo={campoSelecionado} onSuccess={carregaCampos} onClose={fecharModalOpcoes} />
			</Modal>
		</>
	);
}

export default CamposParticipantes;