import { Alert, Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { Aereo } from '../../../entities/aereo';
import { AereoCompanhia } from '../../../entities/aereo-companhia';
import { Participante } from '../../../entities/participante';
import { Result } from '../../../interfaces/Result';
import InputFloat from '../../../masks/InputFloat';
import { useApp } from '../../../providers/AppProvider';
import InputInteiro from '../../../masks/InputInteiro';



interface AereoEditarProps {
	aereo: Aereo | null,
	participante?: Participante,
	onSuccess(aereo: Aereo): void,
	onClose?(): void
}


const AereoEditar: React.FC<AereoEditarProps> = ({
	aereo,
	participante,
	onSuccess,
	onClose
}) => {

	const [form] = useForm();
	const { Option } = Select;
	const app = useApp();


	const [companhias, setCompanhias] = useState<AereoCompanhia[]>([]);
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState<number | null>(null);
	const [mensagem, setMensagem] = useState(true);



	const carregaCompanhias = useCallback(async() => {
		let result: Result | null = await app.ajaxApi("GET", 'aereo/companhia');
		if (result?.code === 200) {						
			if (result.dados.length > 0) {
				setCompanhias(result.dados);			    
                setMensagem(true);                
            } else {
                setMensagem(false);
            }
		}
	}, [app]);



	const salvar = useCallback(async(formData: any) => {
		//console.log(formData);
		setLoading(true);
		let result: Result | null = null;

		if (aereo) {
			// ***** ALTERAR
			result = await app.ajaxApi("PUT", `aereo/${aereo.id}`, formData);
		}
		else 
		{
			// ***** INSERIR
			if (participante)
				formData["participanteId"] = participante.id;

			result = await app.ajaxApi("POST", `aereo/participante`, formData);
		}
		
		if (result?.code === 200) {
			onSuccess(result.dados);

			if (onClose)
				onClose();
		}

		setLoading(false);
	}, [app, participante, onClose, onSuccess, aereo]);



	useEffect(() => {
		if (companhias.length === 0) {            
			carregaCompanhias();
        }
		if (aereo) {			
			let dados = {
				...aereo,
				dataHoraEmbarque: aereo.dataHoraEmbarque ? moment(aereo.dataHoraEmbarque) : null,
				dataHoraDesembarque: aereo.dataHoraDesembarque ? moment(aereo.dataHoraDesembarque) : null
			}
			form.setFieldsValue(dados);
		}
		else		
			form.setFieldsValue({ aereoStatusId: 1 });
	}, [carregaCompanhias, form, aereo, companhias.length]);


	return (
		<>
			<h2>
				<FaEdit />
				Editar Aéreo
			</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				{
					!mensagem &&
					<Alert type='warning' message="Para realizar o cadastro é necessário ter companhias aéreas cadastrados. " showIcon />
				}
				
				<Row gutter={25}>
					<Col md={6}>
						<FormItem label="Tipo" name="aereoTipoId" rules={[
							{ required: true, message: "Informe o sentido" }
						]}>
							<Select style={{ width: '100%' }}>
								<Option value={1}>Ida</Option>
								<Option value={2}>Volta</Option>
							</Select>
						</FormItem>	
					</Col>

					<Col md={6}>
						<FormItem label="Companhia Aérea" name="aereoCompanhiaId" rules={[
							{ required: true }
						]}>
							<Select style={{ width: '100%' }}>
								{companhias.map((companhia: AereoCompanhia) => (
									<Option key={companhia.key} value={companhia.id}>{companhia.nome}</Option>
								))}
							</Select>
						</FormItem>	
					</Col>

					<Col md={6}>
						<FormItem label="Número de Lugares" name="lugares" rules={[
							{ required: true, message: "Informe o número de lugares" }
						]}>
							<InputInteiro />
						</FormItem>	
					</Col>

					<Col md={6}>
						<FormItem label="Status" name="aereoStatusId" rules={[
							{ required: true, message: "Informe o status" }
						]}>
							<Select style={{ width: '100%' }} onChange={(value) => setStatus(value)}>
								<Option value={1}>Pendente</Option>
								<Option value={2}>Confirmado</Option>
								<Option value={3}>Cancelado</Option>
								<Option value={4}>Reemitido</Option>								
							</Select>
						</FormItem>	
					</Col>



					<Col md={4}>
						<FormItem label="Aeroporto de Origem" name="origemAeroporto" rules={[
							{ required: (status === 2), message: "Informe a cidade de origem" }
						]}>
							<Input />
						</FormItem>	
					</Col>

					<Col md={8}>
						<FormItem label="Cidade de Origem" name="origemCidade" rules={[
							{ required: (status === 2), message: "Informe a cidade de origem" }
						]}>
							<Input />
						</FormItem>	
					</Col>

					
					<Col md={4}>
						<FormItem label="Aeroporto de Destino" name="destinoAeroporto" rules={[
							{ required: (status === 2), message: "Informe a cidade de origem" }
						]}>
							<Input />
						</FormItem>	
					</Col>

					<Col md={8}>
						<FormItem label="Cidade de Destino" name="destinoCidade" rules={[
							{ required: (status === 2), message: "Informe a cidade de origem" }
						]}>
							<Input />
						</FormItem>	
					</Col>

					



					<Col md={8}>
						<FormItem label="Embarque (Data e Hora)" name="dataHoraEmbarque" rules={[
							{ required: (status === 2), message: "Informe a cidade de origem" }
						]}>
							<DatePicker format="DD/MM/YYYY HH:mm" style={{ width: '100%' }} showTime />
						</FormItem>	
					</Col>

					<Col md={8}>
						<FormItem label="Desembarque (Data e Hora)" name="dataHoraDesembarque" rules={[
							{ required: (status === 2), message: "Informe a cidade de origem" }
						]}>
							<DatePicker format="DD/MM/YYYY HH:mm" style={{ width: '100%' }} showTime  />
						</FormItem>	
					</Col>

					<Col md={8}>
						<FormItem label="Número do Voo" name="numVoo" rules={[
							{ required: (status === 2), message: "Informe a cidade de origem" }
						]}>
							<Input />
						</FormItem>	
					</Col>



					<Col md={8}>
						<FormItem label="Tarifa Sistema" name="tarifaSistema">
							<InputFloat />
						</FormItem>	
					</Col>

					<Col md={8}>
						<FormItem label="Taxa de Embarque" name="taxaEmbarque">
							<InputFloat />
						</FormItem>	
					</Col>

					<Col md={8}>
						<FormItem label="Taxa de Bagagem" name="taxaBagagem">
							<InputFloat />
						</FormItem>	
					</Col>

					<Col md={8}>
						<FormItem label="Localizador" name="localizador">
							<Input />
						</FormItem>	

						<FormItem label="E-Ticket" name="etkt">
							<Input />
						</FormItem>	
					</Col>

					<Col md={16}>
						<FormItem label="Observações" name="observacao">
							<TextArea style={{ height: '118px' }} />
						</FormItem>	
					</Col>
				</Row>
			

				<div className="acoes">
					{onClose && (
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}
					<Button type='primary' className='button-success' icon={<FaCheck />} htmlType='submit' loading={loading}>Salvar</Button>
				</div>
				
			</Form>
		</>
	);
}

export default AereoEditar;