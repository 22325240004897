import { Button, Col, Form, Input, Row, Select } from 'antd';
import { notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa';
import FormProps from '../../../../interfaces/FormProps';
import { Result } from '../../../../interfaces/Result';
import InputCnpj from '../../../../masks/InputCnpj';
import InputCpf from '../../../../masks/InputCpf';
import InputTelefone from '../../../../masks/InputTelefone';
import { useApp } from '../../../../providers/AppProvider';


const CadastroEditarForm: React.FC<FormProps> = ({
	onClose,
	onSuccess,
	data
}) => {

	const { Option } = Select;
	const [form] = useForm();

	const app = useApp();
	
	const [tipo, setTipo] = useState();
	const [loading, setLoading] = useState(false);



	const salvar = useCallback(async(data: any) => {		
		setLoading(true);

		try {
			let dados = data;
			if (dados.tipo === 1)
				dados["documento"] = dados.cpf;

			if (dados.tipo === 2)
				dados["documento"] = dados.cnpj;

			let result: Result | null = await app.ajaxApi("PUT", `cadastro/${data.id}`, dados, true);
			if (result?.code === 200) {
				notification.success({
					message: "Cadastro atualizado",
					placement: 'bottomRight'
				});
			}

		} catch (e) {
			console.log(e);
		}

		setLoading(false);
	}, [app]);




	useEffect(() => {	
		if (data) {
			let dadosForm: any = data;

			if (data.tipo) {
				setTipo(data.tipo);
	
				if (data.tipo === 1)
					dadosForm["cpf"] = data.documento;
	
				if (data.tipo === 2)
					dadosForm["cnpj"] = data.documento;
			}
	
			form.setFieldsValue(dadosForm);
		}		
	}, [data, form]);


  return (
	<>
		<Form form={form} layout='vertical' initialValues={data} onFinish={salvar}>
			<Row gutter={25}>
				<Col md={12}>
					<Form.Item label="ID" name="id">
						<Input disabled />
					</Form.Item>
				</Col>

				<Col md={12}>
					<Form.Item label="Código" name="codigo">
						<Input disabled />
					</Form.Item>
				</Col>

				<Col md={8}>
					<Form.Item label="Nome" name="nome" rules={[
						{ required: true, message: "Informe o nome" }
					]}>
						<Input />
					</Form.Item>
				</Col>

				<Col md={8}>
					<Form.Item label="E-mail" name="email" rules={[
						{ required: true, message: "Informe o e-mail" }
					]}>
						<Input />
					</Form.Item>
				</Col>

				<Col md={8}>
					<Form.Item label="Tipo" name="tipo">
						<Select onChange={(value) => setTipo(value)}>
							<Option value={1}>Pessoa Física</Option>
							<Option value={2}>Pessoa Jurídica</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col md={8}>
					{tipo === 1 && (
						<Form.Item label="CPF" name="cpf">
							<InputCpf />
						</Form.Item>
					)}
					
					{tipo === 2 && (
						<Form.Item label="CNPJ" name="cnpj">
							<InputCnpj />
						</Form.Item>
					)}
				</Col>

				<Col md={8}>
					<Form.Item label="Telefone" name="telefone">
						<InputTelefone />
					</Form.Item>
				</Col>

				<Col md={8}>
					<Form.Item label="Celular" name="celular">
						<InputTelefone />
					</Form.Item>
				</Col>
			</Row>

			<div className="acoes">
				{onClose && (
					<Button  className='button-default' onClick={onClose} type="primary" icon={<FaBan />}>Cancelar</Button>
				)}
				<Button loading={loading} htmlType='submit' className='button-success' type="primary" icon={<FaCheck />}>Salvar</Button>
			</div>
			
		</Form>
	</>
  );
}

export default CadastroEditarForm;