import React from 'react';
import { BsFillArrowRightSquareFill } from 'react-icons/bs';
import { Aereo } from '../entities/aereo';


interface AereoOrigemDestinoProps {
	aereo: Aereo
}


const AereoOrigemDestino: React.FC<AereoOrigemDestinoProps> = ({
	aereo
}) => {
	return (
		<>
			{(aereo.origemCidade && aereo.origemAeroporto && aereo.destinoCidade && aereo.destinoAeroporto) ? (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<span style={{ display: 'flex', flexDirection: 'column', lineHeight: '16px', marginRight: '10px' }}>
						<strong>{aereo.origemAeroporto}</strong>
						<span style={{ fontSize: '12px' }}>{aereo.origemCidade}</span>
					</span>
					<BsFillArrowRightSquareFill />
					<span style={{ display: 'flex', flexDirection: 'column', lineHeight: '16px', marginLeft: '10px' }}>
						<strong>{aereo.destinoAeroporto}</strong>
						<span style={{ fontSize: '12px' }}>{aereo.destinoCidade}</span>
					</span>
				</div>
			) : (
				<i>Não definido</i>
			)}					
		</>		
	);
}

export default AereoOrigemDestino;