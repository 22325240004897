import { Tooltip } from 'antd';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

interface TooltipFormProps {
	descricao?: string
}

const TooltipForm: React.FC<TooltipFormProps> = ({
	descricao
}) => {
	return (
		<>
			{descricao && (
				<Tooltip color="blue" title={descricao}><FaInfoCircle style={{ marginBottom: '-1px', marginLeft: '5px' }} /></Tooltip>
			)}	
		</>
	);
}

export default TooltipForm;