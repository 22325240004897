import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import FormProps from '../../../../interfaces/FormProps';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';


const ArquivoAlteraNomeForm: React.FC<FormProps> = ({
	onSuccess,
	onClose,
	data
}) => {

	const app = useApp();
	const [loading, setLoading] = useState(false);

	const salvar = async (dados: any) => {
		setLoading(true);
		let result: Result | null = await app.ajaxApi("put", `arquivo/${data.id}`, dados);
		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);
			
			if (onClose)
				onClose();
		}
		setLoading(false);
	}

  return (
	  <>
	  	<h2>
			  <FaEdit />
			  Alterar Nome do Arquivo
		  </h2>

		  <Form layout='vertical' initialValues={data} onFinish={salvar}>
			  <Form.Item name="nome" label="Nome do arquivo" rules={[
				  { required: true, message: "Informe o nome do arquivo" }
			  ]}>
				  <Input />
			  </Form.Item>

			  <div className="acoes">
				  {onClose && <Button type='primary' className='button-default' onClick={onClose}>
					  <FaBan />
					  Cancelar
					</Button>}

			  	<Button loading={loading} type='primary' htmlType='submit' className='button-success'>
					  <FaCheck />
					  Salvar
				</Button>
			  </div>
		  </Form>
	  </>
  );
}

export default ArquivoAlteraNomeForm;