import { Alert, Button, Col, Empty, Form, Input, Popconfirm, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowLeft, FaEdit, FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Cadastro } from '../../../../entities/cadastro';
import CadastroEditarForm from '../../forms/Cadastro/CadastroEditarForm';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import moment from 'moment';
import AdminEditar from '../../components/AdminEditar';


const CadastroDetalhes: React.FC = () => {

	const { id } = useParams();
	const app = useApp();


	const [cadastro, setCadastro] = useState<Cadastro>();


	

	const selecionaCadastro = useCallback(async () => {
		try {
			let result: Result | null = await app.ajaxApi("GET", `cadastro/${id}`);						
			setCadastro(result?.dados);
		} catch (e) {

		}
	}, [app, id]);

	



	// ***** Exclui um cadastro
	const excluir = useCallback(async () => {
		try {

			// Exclui o cadastro
			await app.ajaxApi("DELETE", `cadastro/${cadastro?.id}`);

			// Recarrega cadastros
			app.goBack();

		} catch (e) {
			//console.log(e);
		}
	}, [app, cadastro]);



	
	useEffect(() => {
		selecionaCadastro();
	}, [selecionaCadastro]);


	return (
		<>
			<h1>
				  <FaEdit />
				  Detalhes do Cadastro
			</h1>
	
			<div className='submenu'>
				<nav>
					<Button className='button-default' type='primary' icon={<FaArrowLeft />} onClick={app.goBack}>Voltar</Button>	
				</nav>

				<div>
					<Popconfirm  placement="bottomLeft" title="Você confirma a EXCLUSÃO deste cadastro?" onConfirm={() => excluir()}>
						<Button danger type='primary'>
							<FaTrash />
							Excluir
						</Button>
					</Popconfirm>
				</div>
			</div>

			<div>
				<Row gutter={25}>
					<Col md={16}>
						<div className='box' style={{ marginBottom: '25px' }}>
							<h2>Dados Gerais</h2>
							<CadastroEditarForm data={cadastro} />
						</div>
					</Col>
					<Col md={8}>
						<div className="box">
							<h2>Login de Acesso</h2>

							{cadastro?.cadastroLogin ? (
								<>
									<Alert type='success' message="Login habilitado" showIcon style={{ marginBottom: '15px' }} />

									<Form layout='vertical'>
										<Form.Item label="Último Acesso">
											<Input title={moment(cadastro?.cadastroLogin.ultimoAcesso).format("DD/MM/YYYY - HH:mm:ss")} value={cadastro?.cadastroLogin.ultimoAcesso ? moment(cadastro?.cadastroLogin.ultimoAcesso).fromNow() : 'Nenhum acesso'} readOnly />
										</Form.Item>

										<Form.Item label="Número de Tentativas Inválidas">
											<Input value={cadastro?.cadastroLogin.tentativas} readOnly />
										</Form.Item>
									</Form>
								</>								
							) : (
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									<Empty style={{ marginBottom: '25px' }} />
									<Button type='primary'>Criar Login</Button>
								</div>
							)}
						</div>
					</Col>

					
					{cadastro?.admin && (
						<Col md={8}>
							<AdminEditar admin={cadastro.admin} onSuccess={selecionaCadastro} />
						</Col>
					)}
					
					
				</Row>

			</div>
			
		  </>
	  );
}

export default CadastroDetalhes;