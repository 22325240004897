import { Alert, Button, Form, Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import FormItem from 'antd/lib/form/FormItem'
import Password from 'antd/lib/input/Password'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { FaBan, FaCheck, FaTrash } from 'react-icons/fa'
import { IoWarningOutline } from 'react-icons/io5'
import { Result } from '../../../interfaces/Result'
import { useApp } from '../../../providers/AppProvider'

interface ModalSenhaProps {
    id?: string, //id que sera excluidoo
    url?: string, //action q sera acionada na API
    onClose?(): void,
    onSuccess(): void
}
const ModalSenha: React.FC<ModalSenhaProps> = ({
    id,
    url,
    onClose,
    onSuccess
}) => {

    const app = useApp();
    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    const confirmar = useCallback(async (formData: any) => {          
        console.log(formData.senha);  
        console.log(`${url}/${id}`);  

        setLoading(true);      
        //let result: Result | null = await app.ajaxApi("DELETE", `${url}/${id}`, { data: { Senha: formData.senha } });
        //let result: Result | null = await axios.delete(`${url}/${id}`, { data: { Senha: formData.senha } });
        let result: Result | null = await app.ajaxApi("POST", `${url}/${id}`, formData);
        if (result?.code === 200) {
            if (onSuccess)
                onSuccess();

            if (onClose)
                onClose();
        }
        setLoading(false);
    }, [app, onSuccess, onclose]);

    return (
        <>
            <h2>
                <IoWarningOutline />
                Confirmação
            </h2>

            <Form form={form} layout='vertical' onFinish={confirmar}>
                <FormItem label="Senha Usuário Logado" name="senha"
                    rules={[{ required: true, message: "Informe a senha" }]}>
                    <Password />
                </FormItem>

                <Alert type='error' message="Esta ação não poderá ser desfeita" showIcon />

                <div className="acoes">
                    {onClose && (
                        <Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
                    )}
                    <Button type='primary' className='button-success' icon={<FaCheck />} htmlType="submit" loading={loading}>Confirmar</Button>
                </div>
            </Form>
        </>
    )
}

export default ModalSenha