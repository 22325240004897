import { Button, Form, Modal, notification, Popconfirm } from 'antd';
import update from 'immutability-helper';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input/Input';
import TextArea from 'antd/lib/input/TextArea';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaBan, FaCheck, FaEdit, FaList, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import { Campo } from '../../../entities/campo';
import { CampoOpcao } from '../../../entities/campo-opcao';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';



interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	index: number;
	moveRow: (dragIndex: number, hoverIndex: number) => void;
}

// ***** Define constantes
const type = 'DraggableBodyRow';



interface CampoOpcoesProps {
	campo: Campo | null,
	onSuccess?(): void,
	onClose?(): void
}


const CampoOpcoes: React.FC<CampoOpcoesProps> = ({
	campo,
	onSuccess,
	onClose
}) => {

	const [form] = useForm();
	const app = useApp();
	const [campoState, setCampoState] = useState<Campo | null>(null);
	const [modalEditar, setModalEditar] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingPosicao, setLoadingPosicao] = useState(false);
	const [opcaoSelecionada, setOpcaoSelecionada] = useState<CampoOpcao | null>(null);


	
	// ***** Configura o arrasta e solta para a ordenação na tabela
	const DraggableBodyRow = ({
		index,
		moveRow,
		className,
		style,
		...restProps
	}: DraggableBodyRowProps) => {

		const ref = useRef<HTMLTableRowElement>(null);
		const [{ isOver, dropClassName }, drop] = useDrop({
		  accept: type,
		  collect: monitor => {
			const { index: dragIndex } = monitor.getItem() || {};
			if (dragIndex === index) {
			  return {};
			}
			return {
			  isOver: monitor.isOver(),
			  dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
			};
		  },
		  drop: (item: { index: number }) => {
			moveRow(item.index, index);
		  },
		});
		const [, drag] = useDrag({
		  type,
		  item: { index },
		  collect: monitor => ({
			isDragging: monitor.isDragging(),
		  }),
		});
		drop(drag(ref));
	  
		return (
		  <tr
			ref={ref}
			className={`${className}${isOver ? dropClassName : ''}`}		
			style={{ cursor: 'move' }}	
			{...restProps}
		  />
		);
	};



	// ***** Função executada quando é alterado a ordem de um item na tabela
	const moveRow = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			if (campoState) {
				const dragRow = campoState?.opcoes[dragIndex];
				setCampoState(
					update(campoState, { opcoes: {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragRow],
					],
					}}),
				);
			}
		  //salvaPosicao();
	}, [campoState]);





	const tabelaOpcoes: ColumnsType<CampoOpcao> = [
		{
			width: 50,
			render: (opcao: CampoOpcao, record, index) => (
				<div style={{ textAlign: 'center' }}>{index + 1}</div>
			)
		},
		{
			title: "Opção",
			dataIndex: "nome"
		},		
		{
			render: (opcao: CampoOpcao) => (
				<>
					<Popconfirm title={<span>Você confirma a EXCLUSÃO desta opção?<br /><strong>{opcao.nome}</strong></span>} placement='topRight' onConfirm={() => excluir(opcao)}>
						<Button type='primary' danger icon={<FaTrash />}>Excluir</Button>
					</Popconfirm>

					<Button type='primary' icon={<FaEdit />} onClick={() => editarOpcao(opcao)}>Editar</Button>
				</>
			)
		}
	];



	const novaOpcao = useCallback(() => {
		setModalEditar(true);
		setOpcaoSelecionada(null);
		form.resetFields();
	}, [form]);


	const editarOpcao = useCallback((opcao: CampoOpcao) => {
		setModalEditar(true);
		setOpcaoSelecionada(opcao);
		form.setFieldsValue(opcao)
	}, [form]);



	const salvar = useCallback(async (formData: any) => {
		setLoading(true);
		let result: Result | null;

		if (opcaoSelecionada)
		{
			// ALTERAR
			result = await app.ajaxApi("PUT", `campo/${campoState?.id}/opcao/${opcaoSelecionada.id}`, formData);
			if (result?.code === 200) {
				setCampoState(result.dados);
				setModalEditar(false);
			}
		}
		else 
		{
			// INSERIR
			let dados = {
				...formData,
				campoId: campo?.id
			};

			result = await app.ajaxApi("POST", "campo/opcao", dados);
			if (result?.code === 200) {
				setCampoState(result.dados);
				setModalEditar(false);
			}
		}
		
		setLoading(false);
	}, [app, opcaoSelecionada, campo, campoState]);



	const excluir = useCallback(async(opcao: CampoOpcao) => {
		let result: Result | null = await app.ajaxApi("DELETE", `campo/opcao/${opcao.id}`);
		if (result?.code === 200) {
			notification.success({
				message: "Opção excluída",
				placement: 'bottomRight',
				duration: 5
			});

			let indexExcluir = null;
			campoState?.opcoes.forEach((op: CampoOpcao, index: number) => {
				if (op.id === opcao.id)
					indexExcluir = index;				
			});

			if (indexExcluir) {
				setCampoState(
					update(campoState, {
						opcoes: { $splice: [[indexExcluir, 1]] }
				}));
			}
		}
	}, [app, campoState]);



	// ***** Função para salvar as posições das etapas definidas na tabela
	const salvarPosicao = useCallback(async () => {
		setLoadingPosicao(true);
		
		let ids: string[] = [];
		campoState?.opcoes.forEach((opcao: CampoOpcao) => {
			ids.push(opcao.id);
		});

		let result: Result | null = await app.ajaxApi("put", `campo/${campoState?.id}/opcao/salvar-posicao`, {			
			ids
		});
		
		if (result?.code === 200) {
			notification.success({
				message: "Posições salvas",
				duration: 5,
				placement: "bottomRight"
			});
		}

		setLoadingPosicao(false);		
	}, [campoState, app]);




	useEffect(() => {
		if (campo)
			setCampoState(campo);
	}, [campo]);




	return (
		<>
			<h2>
				<FaList />
				Opções do Campo
			</h2>

			<div className="submenu" style={{ marginBottom: '15px' }}>
				<Button type='primary' className='button-success' onClick={novaOpcao}>
					<FaPlus />
					Nova Opção
				</Button>
			</div>

			<DndProvider backend={HTML5Backend}>
				<Table pagination={false} size='small' columns={tabelaOpcoes} dataSource={campoState?.opcoes} 
					components={{
						body: {
						  row: DraggableBodyRow,
						},
					}} 
					onRow={(_, index) => {
						const attr = {
							index,
							moveRow,
						};
						return attr as React.HTMLAttributes<any>;
					}}
				/>	
			</DndProvider>
			
				
			<div style={{ marginTop: '25px', textAlign: 'right' }}>
				<Button type='primary' style={{ marginRight: '7px' }} className='button-default' icon={<FaBan />} onClick={onClose}>Fechar</Button>	
				<Button type='primary' className='button-success' icon={<FaSave />} loading={loadingPosicao} onClick={salvarPosicao}>Salvar Posições</Button>				
			</div>


			<Modal open={modalEditar} closable maskClosable={false} destroyOnClose footer={false} onCancel={() => setModalEditar(false)}>
				<h2>
					<FaEdit />
					Editar Opção
				</h2>

				<Form layout='vertical' form={form} onFinish={salvar}>
					<FormItem label="Nome" name="nome" rules={[
						{ required: true, message: "Informe o nome" }
					]}>
						<Input />
					</FormItem>

					<FormItem label="Descrição" name="descricao">
						<TextArea rows={4} />
					</FormItem>

					<div className="acoes">						
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={() => setModalEditar(false)}>Cancelar</Button>
						<Button type='primary' className='button-success' icon={<FaCheck />} htmlType='submit' loading={loading}>Salvar</Button>
					</div>
				</Form>
			</Modal>
		</>
	);
}

export default CampoOpcoes;