import { Button, Modal, Popconfirm, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { AiFillSignal } from 'react-icons/ai';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { HospedagemQuartoTipoEntity } from '../../../../entities/HospedagemQuartoTipoEntity';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import HospedagemQuartoForm from '../../forms/Hospedagem/HospedagemQuartoTipoForm';


const Hospedagem: React.FC = () => {
    const app = useApp();
    const [hospedagemQuartoLst, setHospedagemQuartoLst] = useState<HospedagemQuartoTipoEntity[]>([]);
    const [modal, setModal] = useState(false);
    const [hospedagemQuartoObj, setHospedagemQuartoObj] = useState<HospedagemQuartoTipoEntity | null>(null);

  type NewType = ColumnsType<HospedagemQuartoTipoEntity>;

    //DEFINIÇÃO DAS COLUNAS
    const tabelaHospedagemQuarto: NewType = [
        {
            title: "Nome",
            dataIndex: "nome"
        },        
        {
            title: "Nº de Pessoas",
            dataIndex: "numPessoas"            
        },        
        {
            render: (hospedagemQuarto: HospedagemQuartoTipoEntity) => (
                <>
                    <Popconfirm placement='topLeft'
                        title={<span>Você confirma a EXCLUSÃO do tipo de quarto<br /><strong>{hospedagemQuarto.nome}</strong></span>}
                        onConfirm={() => excluir(hospedagemQuarto)}>
                        <Button size='small' type='primary' danger icon={<FaTrash />}>Excluir</Button>
                    </Popconfirm>

                    <Button size='small' type='primary' icon={<FaEdit />} onClick={() => editarHospedagemQuarto(hospedagemQuarto)}>Editar</Button>
                </>
            )
        }
    ];

    const carregaHospedagemQuarto = useCallback(async () => {
        let result: Result | null = await app.ajaxApi("GET", `hospedagem/quarto/tipo`);
        if (result?.code === 200) {
            setHospedagemQuartoLst(result.dados);
        }
    }, [app]);

    const excluir = useCallback(async (hospedagemQuartoSel: HospedagemQuartoTipoEntity) => {
        let result: Result | null = await app.ajaxApi("DELETE", `hospedagem/quarto/tipo/${hospedagemQuartoSel.id}`);

        if (result?.code === 200) {
            carregaHospedagemQuarto();
        }
    }, [app, carregaHospedagemQuarto]);

    const editarHospedagemQuarto = useCallback(async (hospedagemStatusSel: HospedagemQuartoTipoEntity) => {
        setModal(true);
        setHospedagemQuartoObj(hospedagemStatusSel);
    }, []);

    const novoHospedagemQuarto = useCallback(() => {
        setModal(true);
        setHospedagemQuartoObj(null);

    }, []);

    useEffect(() => {
        carregaHospedagemQuarto();
    }, [carregaHospedagemQuarto]);

    return (
        <>
            <h1>
                <AiFillSignal />
                 Gerenciar Tipos de Quarto
            </h1>

            <div className="submenu">
                <Button type='primary' className='button-success' onClick={novoHospedagemQuarto}>
                    <FaPlus />Novo Tipo de Quarto
                </Button>
            </div>

            <Table size='small' pagination={{ pageSize: 100 }} columns={tabelaHospedagemQuarto} dataSource={hospedagemQuartoLst}></Table>

            <Modal open={modal} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModal(false)}>
                <HospedagemQuartoForm data={hospedagemQuartoObj} onClose={() => setModal(false)} onSuccess={carregaHospedagemQuarto} />
            </Modal>
        </>
    )
}

export default Hospedagem;