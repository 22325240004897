import React, { useEffect } from 'react';

import "./Areas.css";

import { FaArrowAltCircleRight, FaHandsHelping } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineSettings } from 'react-icons/md';
import { useApp } from '../../../../providers/AppProvider';

const Areas: React.FC = () => {

	const app = useApp();
	const navigate = useNavigate();
	//const [areas, setAreas] = useState<string[]>([]);

	useEffect(() => {		
		let areasDispiniveis: string[] = [];
		app.auth?.areas.forEach((area) => {
			if (area !== "Master")
				areasDispiniveis.push(area);
		});

		if (areasDispiniveis.length === 1) {
			if (areasDispiniveis[0] === "Admin")
				navigate("/admin/painel");
			
			if (areasDispiniveis[0] === "Parceiro")
				navigate("/parceiro/inicio");
		}

		//setAreas(areasDispiniveis);
	}, [app, navigate]);

return (
	<section className='area-inicial'>
		<div className='logo'>
			LOGO EMPRESA
		</div>

		<div className='areas'>
			<div className='item' onClick={() => app.toUrl("/auth/parceiro")}>
				<FaHandsHelping size={64} />
				<p>
					Acesso de Parceiro
					<FaArrowAltCircleRight size={12} />
				</p>
			</div>
		</div>

		<div className="link-admin">
			<Link to="/auth/admin">
				<MdOutlineSettings />
				Sistema TSG Tech
			</Link>
		</div>
	</section>
);

}
export default Areas;