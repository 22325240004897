import { Pie } from "@ant-design/charts";
import React, { useCallback, useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { Result } from "../../../../interfaces/Result";
import { useApp } from "../../../../providers/AppProvider";

const DashboardParticipante: React.FC = () => {

    const app = useApp();		
	const [config, setConfig] = useState<any | null>(null);	

	const CarregarDashboardParticipantes = useCallback(async () => {		
		let auxData: any = [];
		let result: Result | null = await app.ajaxApi("GET", "dashboard/participantes");
		if (result?.code === 200) {
            // console.log(result.dados);
			// result?.dados.forEach((item: any) => {
			// 	auxData.push({
			// 		type: item[0].followUpStatus.nome,								
			// 		value: item.length,					
			// 	});		
			// });
			// console.log(auxData);
		}

        auxData = [
			{
				type: 'Confirmados',								
				value: 70,		
			},
			{
				type: 'Pendentes',								
			 	value: 30,		
			}
		];
		
		const aux = {
			appendPadding: 10,
			data: auxData,																
			angleField: 'value',
			colorField: 'type',
			radius: 0.66,							
			interactions: [
				{
					type: 'element-active',
				},
			],
		};
		setConfig(aux);			
	}, [app]);

	useEffect(() => {
		if (!config) {
			CarregarDashboardParticipantes();											
		}					
	}, [CarregarDashboardParticipantes, config])

	return (
		<>
			<div className="box">
				<h2>
                    <FaUsers />
					Participantes Cadastrados
				</h2>
                { 
					config && 
					<Pie {...config} />
				}                
			</div>
		</>
	);
};

export default DashboardParticipante;
