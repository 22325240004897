import { Button } from 'antd';
import React from 'react';
import { FaArrowLeft, FaLock } from 'react-icons/fa';
import { useApp } from '../../../../providers/AppProvider';

import './AcessoNegado.css';


const AcessoNegado: React.FC = () => {

	const app = useApp();

  return (
	<>
		<div className='acesso-negado'>

			<div className='icone'>
				<FaLock size={64} />
			</div>

			<div className='info'>
				<h1>Acesso Negado</h1>
				<p>Você não tem as permissões de acesso necessárias para esta área do sistema.</p>
				<Button type='primary' onClick={() => app.goBack()}>
					<FaArrowLeft />
					Voltar
				</Button>
			</div>
			
		</div>
	</>
  );
}

export default AcessoNegado;