import { Button, Col, Form, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import AereoOrigemDestino from '../../../components/AereoOrigemDestino';
import AereoTipo from '../../../components/AereoTipo';
import { Aereo } from '../../../entities/aereo';
import { ParticipanteAereo } from '../../../entities/participante-aereo';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';


interface ParticipanteAereoEditarProps {
	participanteId: string | null,
	participanteAereo: ParticipanteAereo | null,
	onSuccess(participanteAereo: ParticipanteAereo): void,
	onClose?(): void
}


const ParticipanteAereoEditar: React.FC<ParticipanteAereoEditarProps> = ({
	participanteId,
	participanteAereo,
	onSuccess,
	onClose
}) => {

	const app = useApp();
	const [aereos, setAereos] = useState<Aereo[]>([]);
	const [aereoSelecionado, setAereoSelecionado] = useState<Aereo | null>(null);
	const [loading, setLoading] = useState(false);

	const { Option } = Select;
	const [form] = useForm();



	const tabelaAereos: ColumnsType<Aereo> = [
		{
			title: "Sentido",
			render: (aereo: Aereo) => <AereoTipo aereo={aereo} />
		},
		{
			title: "Origem / Destino",
			render: (aereo: Aereo) => <AereoOrigemDestino aereo={aereo} />
		},
		{
			title: "Nº do Voo",
			dataIndex: "numVoo"
		},
		{
			title: "Embarque",
			render: (aereo: Aereo) => (
				<>
					{aereo.dataHoraEmbarque ? moment(aereo.dataHoraEmbarque).format("DD/MM/YYYY - HH:mm") : 'Não definido'}
				</>
			)
		},
		{
			title: "Lugares",
			render: (aereo: Aereo) => (
				<strong>{aereo.lugaresDisponiveis} / {aereo.lugares}</strong>
			)
		},
		{
			render: (aereo: Aereo) => (
				<>
					{aereoSelecionado?.id !== aereo.id ? (
						<Button type='primary' onClick={() => setAereoSelecionado(aereo)}>Selecionar</Button>
					) : (
						<i>Selecionado</i>
					)}
				</>				
			)
		}
	];


	const carregaAereos = useCallback(async() => {
		let result: Result | null = await app.ajaxApi("GET", 'aereo/listar');
		if (result?.code === 200)
			setAereos(result.dados);
	}, [app]);


	const salvar = useCallback(async(formData: any) => {
		setLoading(true);

		let dados = {
			...formData,
			responId: participanteId,
			aereoId: aereoSelecionado?.id			
		};

		let result: Result | null = await app.ajaxApi("POST", "participante/aereo", dados);
		if (result?.code === 200) {
			onSuccess(result.dados);

			if (onClose)
				onClose();
		}

		setLoading(false);
	}, [app, onSuccess, onClose, aereoSelecionado, participanteId]);


	useEffect(() => {
		carregaAereos();
		form.setFieldsValue({ participanteAereoStatusId: 1 });

	}, [carregaAereos, form]);


	return (
		<>
			<h2>
				<FaEdit />
				Editar Aéreo do Participante
			</h2>

			<Table pagination={false} size='small' columns={tabelaAereos} dataSource={aereos} />

			<Row style={{ marginTop: '15px' }}>
				<Col md={14}>
				</Col>

				<Col md={10}>
					<Form form={form} layout='vertical' onFinish={salvar}>
						<FormItem label="Status" name="participanteAereoStatusId" rules={[
							{ required: true, message: "Informe o status" }
						]}>
							<Select style={{ width: '100%' }}>
								<Option value={1}>Pendente</Option>
								<Option value={2}>Confirmado</Option>
								<Option value={3}>Cancelado</Option>
								<Option value={4}>Reemitido</Option>
							</Select>
						</FormItem>

						<div className="acoes">
							{onClose && (
								<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>	
							)}
							<Button type='primary' className='button-success' icon={<FaCheck />} htmlType='submit' loading={loading}>Salvar</Button>
						</div>
					</Form>					
				</Col>
			</Row>
		</>
	);
}

export default ParticipanteAereoEditar;