import React from 'react';

interface LayoutDefaultProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const LayoutDefault: React.FC<LayoutDefaultProps> = ({ children }) => {
  return (
	  <>
	  		<h1>Layout DEFAULT</h1>
		  {children}
	  </>
  );
}

export default LayoutDefault;