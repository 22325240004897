import React, { createContext, useContext, useCallback, useState } from 'react';

import { api } from '../services/api';
import { Result } from '../interfaces/Result';
import ListError from '../components/ListError';
import { useNavigate } from 'react-router-dom';
import { AuthLogin } from '../interfaces/AuthLogin';
import notification from 'antd/lib/notification';
import { Modal } from 'antd';
import { FaLock } from 'react-icons/fa';
import { NotificationFollow } from '../interfaces/notification-follow';


interface AppProviderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

interface AppProps {		
	auth: AuthLogin | null;	
	login(id: string, nome: string, email: string, areas: string, acoes: string, token: string): void;	
	logout(): void;
	ajaxApi(method: string, url: string, data?: object, notify?: boolean): Promise<Result | null>;	
	recuperaSenha(novaSenha: string, novaSenhaConfirm: string, codigo: string, token?: string): Promise<Result | null | any>;
	toUrl(url: string): void;
	goBack(): void;
	verificaAcao(acao: string):boolean,
	calculaFollowUps(): void;
	notificationFollow: NotificationFollow | null;
}

const AppContext = createContext<AppProps>({} as AppProps);


export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {

	const navigate = useNavigate();

	const [notificationFollow, setNotificationFollow] = useState<NotificationFollow | null>(null);


	const [auth, setAuth] = useState<AuthLogin | null>(() => {
		let dadosLogin = sessionStorage.getItem("auth");
		if (dadosLogin) {
			let json: AuthLogin = JSON.parse(dadosLogin);
			return json;
		}
		return null;
	});



	const logout = useCallback(() => {
		sessionStorage.clear();
	}, []);



	const ajax = useCallback(async (method: string, url: string, data?: any, notify: boolean = true) => {
		try
		{
			let result: Result = {} as Result;
			let rs: any;
			method = method.toLowerCase();

			let headers: any = {};
			let token = sessionStorage.getItem("token");
			if (token)
				headers["Authorization"] = `Bearer ${token}`;

			if (process.env.REACT_APP_TENANT != null)
				headers["Tenant"] = process.env.REACT_APP_TENANT;

			if (method === "get")
				rs = await api.get(url, { headers: headers});
			else if (method === "post") 
				rs = await api.post(url, data, { headers: headers });
			else if (method === "put")
				rs = await api.put(url, data, { headers: headers });
			else if (method === "delete") 
				rs = await api.delete(url, { headers: headers });

			result = rs.data;
			return result;
		}
		catch (e: any) 
		{
			console.log(e);
			let errorResult: Result = e.response.data;
			
			switch(e.response.status) {
				case 401:
					notification.warning({ 
						message: "Acesso não autorizado",		
						description: "Realize o login novamente",				
						duration: 10
					});
					logout();
					navigate("/");
					break;

				case 403:									
					Modal.warn({
						icon: false,						
						content: (
							<>
								<h2>
									<FaLock />
									Acesso não autorizado
								</h2>
								<p>
									Você não tem permissão para acessar esta área ou funcionalidade do sistema.
								</p>
							</>
						),						
					});
					break;

				case 400:
					if (notify) {
						notification.warning({ 
							message: errorResult.mensagem,
							description: <ListError errors={errorResult.dados} />,
							duration: 10
						});
					}
					break;

				case 404:
					if (notify) {
						notification.warning({ 
							message: "Erro na requisição",
							description: "A URL do serviço acionada não foi encontrada, entre em contato com o administrador do sistema",
							duration: 10
						});
					}
					break;

				default:					
					notification.error({
						message: e.response.data.mensagem,
						description: e.response.data.dados.Message,
						duration: 10
					});									
			}
			
			return errorResult;
		}
		
	}, [logout, navigate]);





	const recuperaSenha = useCallback(async (novaSenha: string, novaSenhaConfirm: string, codigo: string, token: string) => {
		
			let result: Result = {} as Result;
			let rs: any;
			
			let headers: any = {};			
			if (token)
				headers["Authorization"] = `Bearer ${token}`;

			if (process.env.REACT_APP_TENANT != null)
				headers["Tenant"] = process.env.REACT_APP_TENANT;

			rs = await api.post("auth/recupera-senha", {
				novaSenha,
				novaSenhaConfirm,
				codigo
			}, { headers: headers });			

			result = rs.data;
			return result;		
	}, []);




	const ajaxApi = useCallback(async (method: string, url: string, data?:any, notify: boolean = true) => {
		let result:Result | null = await ajax(method, url, data, notify);		
		return result;
	}, [ajax]);


	const calculaFollowUps = useCallback(async () => {
		let result: Result | null = await ajaxApi("get", "follow-up/badge");
		if (result)
			setNotificationFollow(result.dados);
	}, []);


	
	const login = useCallback(async (id: string, nome: string, email: string, areas: string, acoes: string, token: string) => {
		sessionStorage.setItem("token", token);
		let arrayAcoes: string[] = [];
		let arrayAreas = areas.split(",");
		arrayAreas.forEach((value, index) => {
			arrayAreas[index] = value.trim();
		});

		if (acoes) {
			arrayAcoes = acoes.split(",");
		}
		
		let authLogin: AuthLogin = {
			id,
			nome,
			email,
			areas: arrayAreas,
			acoes: arrayAcoes
		};
		sessionStorage.setItem("auth", JSON.stringify(authLogin));
		setAuth(authLogin);		
	}, []);




	const toUrl = useCallback((url: string) => {		
		navigate(url);
	}, [navigate]);


	const goBack = useCallback(() => {		
		navigate(-1);
	}, [navigate]);



	const verificaAcao = useCallback((acao: string) => {		
		let permissao = false;

		if (auth) {
			auth.areas.forEach((area: string) => {
				if (area === "Master")
					permissao = true;
			});

			auth.acoes.forEach((rs: string) => {
				if (rs === acao)
					permissao = true;
			});
		}

		return permissao;
	}, [auth]);


	

	


	return (
		<AppContext.Provider value={{ auth, login, logout, ajaxApi, toUrl, goBack, recuperaSenha, verificaAcao, calculaFollowUps, notificationFollow }}>			
			{children}
		</AppContext.Provider>
	);
};

export function useApp(): AppProps {
	const context = useContext(AppContext);

	if (!context) {
		throw new Error('useApp deve ser usado em um AppProvider');
	}

	return context;
}
