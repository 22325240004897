import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import AcessoNegado from '../areas/global/pages/AcessoNegado/AcessoNegado';
import LayoutBlank from '../layouts/Blank/LayoutBlank';
import LayoutDefault from '../layouts/Default/LayoutDefault';
import { useApp } from '../providers/AppProvider';

interface RenderPageProps {
    isPrivate?: boolean;
	areas?: string[];
    component: React.ComponentType;
    layout?: React.ComponentType;
}

const RenderPage: React.FC<RenderPageProps> = ({
	isPrivate = false,
	areas = [],
	component: Component,
	layout: Layout = LayoutDefault
}) => {

	const app = useApp();


	let isAuth: boolean = (sessionStorage.getItem("token") !== null);
	const verificaArea: boolean = useMemo(() => {
		let areaPermitida = false;

		if (areas.length === 0)
			return true;

		areas.forEach((area) => {
			let check = app.auth?.areas.find((a: string) => {
				if (a === area)
					return true;

				return false;
			});

			if (check)
				areaPermitida = true;
		});

		return areaPermitida;
	}, [areas, app]);
	
	
	
	if (!isPrivate) {
		return (	  
			<Layout>
				<Component />
			</Layout>	 
		  );
	}
	

	if (isAuth && verificaArea) {
		return (	  
			<Layout>
				<Component />			
			</Layout>	 
		  );
	}

	if (isAuth && !verificaArea) {
		return (
			<LayoutBlank>
				<AcessoNegado />
			</LayoutBlank>
		);
	}


	return <Navigate to={{	pathname: '/' }} />;
}

export default RenderPage;