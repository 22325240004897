import { Alert, Button, Col, Form, Input, Popconfirm, Row, Select, Table, Tabs, Tag } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowRight, FaBan, FaCheck, FaSearch, FaTrash, FaUsers } from 'react-icons/fa';
import AereoTipo from '../../../components/AereoTipo';
import TextInfo from '../../../components/TextInfo';
import { Aereo } from '../../../entities/aereo';
import { Campo } from '../../../entities/campo';
import { Participante } from '../../../entities/participante';
import { ParticipanteCampo } from '../../../entities/participante-campo';
import { ParticipanteCampoOpcao } from '../../../entities/participante-campo-opcao';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';

import moment from 'moment';


let timeout: any = null;


interface AereoParticipantesProps {
	aereo: Aereo | null,
	onClose?(): void
}



const AereoParticipantes: React.FC<AereoParticipantesProps> = ({
	aereo,
	onClose
}) => {

	const app = useApp();
	const { TabPane } = Tabs;


	const [tab, setTab] = useState<string>("1");
	const [tabelaParticipantes, setTabelaParticipantes] = useState<any>();
	const [tabelaParticipantesAereo, setTabelaParticipantesAereo] = useState<any>();
	const [loading, setLoading] = useState(false);
	const [participantes, setParticipantes] = useState<any>();
	const [participantesInclusos, setParticipantesInclusos] = useState<any>();
	const [busca, setBusca] = useState<string>("");	
	const [numCampos, setNumCampos] = useState<number>(0);	
	const { Option } = Select;
	const [status, setStatus] = useState<string>();
	



	// ********** Carrega os todos os participantes e os presentes no aéreo
	const carregaParticipantes = useCallback(async () => {

		// ********** PARTICIPANTES JÁ INCLUSOS NO AÉREO
		let inclusos: Participante[];
		
		let resultInclusos: Result | null = await app.ajaxApi("GET", `aereo/${aereo?.id}/participantes`);
		inclusos = resultInclusos?.dados;		

		let dadosTabelaInclusos: any[] = [];
		inclusos.forEach((p: any) => {
			let item: any = {};
			
			item["key"] = p.id;
			item["nome"] = p.cadastro.nome;
			item["email"] = p.cadastro.email;
			item["participanteAereoStatusId"] = p.participanteAereos[0].participanteAereoStatusId;
			item["participanteAereoStatusNome"] = p.participanteAereos[0].participanteAereoStatusNome;
			p.participanteCampos.forEach((participanteCampo: ParticipanteCampo) => {
				if (participanteCampo.valor) {					
					item[participanteCampo.campoId] = participanteCampo.valor
				} else {
					let valor: string = "";
					participanteCampo.participanteCampoOpcoes.forEach((opcao: ParticipanteCampoOpcao, index: number) => {
						valor += opcao.campoOpcao.nome;
						if (participanteCampo.participanteCampoOpcoes.length > index + 1)
							valor += " | ";
					});
					item[participanteCampo.campoId] = valor;
				}
			});

			dadosTabelaInclusos.push(item);
		});
		
		setParticipantesInclusos(dadosTabelaInclusos);


		let result: Result | null = await app.ajaxApi("POST", "participante/listar", { busca });
		let part: Participante[] = result?.dados;
					
		let dadosTabela: any[] = [];
		part.forEach((p: Participante) => {
			let item: any = {};
			
			item["key"] = p.id;
			item["nome"] = p.cadastro.nome;
			item["email"] = p.cadastro.email;
			item["incluso"] = false;
			p.participanteCampos.forEach((participanteCampo: ParticipanteCampo) => {
				if (participanteCampo.valor) {					
					item[participanteCampo.campoId] = participanteCampo.valor
				} else {
					let valor: string = "";
					participanteCampo.participanteCampoOpcoes.forEach((opcao: ParticipanteCampoOpcao, index: number) => {
						valor += opcao.campoOpcao.nome;
						if (participanteCampo.participanteCampoOpcoes.length > index + 1)
							valor += " | ";
					});
					item[participanteCampo.campoId] = valor;
				}
			});
			
			if (inclusos) {
				let rsIncluso: Participante | undefined = inclusos.find((itemRs: Participante) => {
					if (itemRs.id === p.id){						
						return true;
					}
					else{
						return false;					
					}
				});			
				if (rsIncluso)
					item.incluso = true;
				else
					item.incluso = false;						

				dadosTabela.push(item);	
			} else {				
			 	dadosTabela.push(item);
			}
		});
		
		setParticipantes(dadosTabela);
		
	}, [app, aereo, busca]);
	


	// ********** Define variável para busca
	const buscar = useCallback((busca: string) => {				
		if (busca.length >= 3) {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				setBusca(busca);				
			}, 250);	
		}
		if (busca.length === 0) {
			setBusca("");			
		}
	}, []);




	// ********** remove participante do aéreo
	const excluirAereo = useCallback(async(participanteId: string) => {
		setLoading(true);
		let result: Result | null = await app.ajaxApi("DELETE", `participante/${participanteId}/aereo/${aereo?.id}`);
		if (result?.code === 200)
			carregaParticipantes();
		setLoading(false);

	}, [app, aereo, carregaParticipantes]);




	// ********** Inclui o participante no aéreo
	const incluir = useCallback(async (participante: any) => {
		setLoading(true);		
		let dados = {						
			participanteId: participante,
			aereoId: aereo?.id,
			ParticipanteAereoStatusId: status		
		};
				
		//console.log(dados)
		let result: Result | null = await app.ajaxApi("POST", "participante/aereo", dados, true);			
		if (result?.code === 200) {				
				carregaParticipantes();								
						
		} 
		setLoading(false);
	}, [aereo, app, carregaParticipantes, status]);




	// ********** Configura a estrutura das tabelas
	const configTabelas = useCallback(async () => {
		let resultCampos: Result | null = await app.ajaxApi("GET", 'campo/listar/participante');
	 	let campos: Campo[] = resultCampos?.dados;	
		
		// Configura a tabela de participantes
		let tabelaParticipantes: ColumnsType<any> = [
			{
				title: "Nome",
				dataIndex: "nome",
				fixed: 'left'
			},
			{
				title: "E-mail",
				dataIndex: "email"				
			}
		];

		let num = 0;
		campos.forEach((campo: Campo) => {
			if (campo.visivelAdmin) {
				num++;
				tabelaParticipantes.push({
					title: campo.nome,
					dataIndex: campo.id,					
				});
			}
		});

		tabelaParticipantes.push({			
			render: ((item: any) => (
				<>				
					{item.incluso === false ? (
						<Popconfirm placement='topLeft'
							title={<span>Você confirma a INCLUSÂO da participante no vôo<br /></span>} onConfirm={() => incluir(item.key )}>
							<Button size='small' type='primary' className='button-icon'>
								Incluir
								<FaArrowRight style={{ marginLeft: '10px' }} />
							</Button>
						</Popconfirm>						
					) : (
						<i className="vazio">Incluído</i>
					)}
				</>
			)),
			fixed: 'right',
			width: 100			
		});
		setTabelaParticipantes(tabelaParticipantes);
		setNumCampos(num);


		// Configura a tabela de participantes - Inclusos no Aéreo
		let tabelaParticipantesAereo: ColumnsType<any> = [
			{
				title: "Nome",
				dataIndex: "nome",
				fixed: 'left'
			},
			{
				title: "E-mail",
				dataIndex: "email"				
			}
		];
		campos.forEach((campo: Campo) => {
			if (campo.visivelAdmin) {
				tabelaParticipantesAereo.push({
					title: campo.nome,
					dataIndex: campo.id,						
				});
			}
		});
		tabelaParticipantesAereo.push({
			render: ((item: any) => (				
				<>
					{item.participanteAereoStatusId === 1 && <Tag color="warning">{item.participanteAereoStatusNome}</Tag>}
					{item.participanteAereoStatusId === 2 && <Tag color="success">{item.participanteAereoStatusNome}</Tag>}
				</>
			)),
			fixed: 'right',
			width: 110
		})
		tabelaParticipantesAereo.push({
			render: ((item: any) => (				
				<Popconfirm placement='topLeft' title={<span>Você confirma a remoção do participante <strong>{item.nome}</strong> deste aéreo?</span>} onConfirm={() => excluirAereo(item.key)}>
					<Button danger type='primary' className='button-icon'>
						<FaTrash />							
					</Button>
				</Popconfirm>
			)),
			fixed: 'right',
			width: 40
		})
		setTabelaParticipantesAereo(tabelaParticipantesAereo);

	}, [app, excluirAereo, incluir]);




	const setarStatus = useCallback((e: any) =>{
		setStatus(e);
	}, []);

	
	

	
	
	// ********** Carregamento da página
	const loadPage = useCallback(async() => {
		await configTabelas();		
		await carregaParticipantes();

	}, [carregaParticipantes, configTabelas]);

	useEffect(() => {		
		if (aereo) 
			loadPage();			
	}, [loadPage, aereo]);






	return (
		<>
			<h2>
				<FaUsers />
				Participantes no Voo
			</h2>

			<div style={{ marginBottom: '25px' }}>
				<Row>
					<Col md={6}>
						{aereo && <TextInfo label='Sentido' element={<AereoTipo aereo={aereo} />} />}
					</Col>
					<Col md={6}>
						{aereo && <TextInfo label='Origem' element={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<span style={{ display: 'flex', flexDirection: 'column', lineHeight: '16px' }}>
									<strong>{aereo.origemAeroporto}</strong>
									<span style={{ fontSize: '12px' }}>{aereo.origemCidade}</span>
									<span style={{ fontSize: '12px', fontStyle: 'italic' }}>{moment(aereo.dataHoraEmbarque).format("DD/MM/YYYY - HH:mm")}</span>
								</span>							
							</div>
						} />}
					</Col>

					<Col md={6}>
						{aereo && <TextInfo label='Destino' element={
							<div style={{ display: 'flex', alignItems: 'center' }}>							
								<span style={{ display: 'flex', flexDirection: 'column', lineHeight: '16px' }}>
									<strong>{aereo.destinoAeroporto}</strong>
									<span style={{ fontSize: '12px' }}>{aereo.destinoCidade}</span>
									<span style={{ fontSize: '12px', fontStyle: 'italic' }}>{moment(aereo.dataHoraDesembarque).format("DD/MM/YYYY - HH:mm")}</span>
								</span>
							</div>
						} />}
					</Col>

					<Col md={6}>
						<TextInfo label='Número do Voo' text={aereo?.numVoo} />
					</Col>
				</Row>

				{aereo?.lugaresDisponiveis === 0 && (
					<Alert style={{ marginTop: '25px' }} type='warning' description="Este voo não possui mais lugares disponíveis" showIcon />
				)}
			</div>

			<Tabs activeKey={tab} size='large' onChange={(key) => setTab(key)}>
				<TabPane tab={<span><FaCheck /> Confirmados neste voo</span>} key="1">
					<Table size='small' columns={tabelaParticipantesAereo} dataSource={participantesInclusos} scroll={{ x: (numCampos * 400) }} loading={loading}/>
				</TabPane>

				<TabPane tab={<span><FaSearch /> Procurar participantes</span>} key="2">
					<Input style={{ marginBottom: '5px' }} size='large' prefix={<FaSearch />} placeholder='Pesquisar participante' onChange={(e) => buscar(e.target.value)} />

					<Table className='no-action' size='small' columns={tabelaParticipantes} dataSource={participantes} scroll={{ x: (numCampos * 400) }} loading={loading}
							rowClassName={(item: any) => {				
								switch (item.incluso) {
									case true:								
										return "operacao-inserir";
									default:
										return "operacao-default";
								}				
							}} />					
					<div className='legenda-table'>
						<div className='item'>
							<div className="legenda operacao-inserir"></div>
							<p>Participante Incluído</p>
						</div>
						<div className='item'>
							<div className="legenda operacao-default"></div>
							<p>Disponível</p>
						</div>
					</div>

					<Form style={{ marginTop: '25px', display: 'flex', alignItems: 'flex-start', justifyContent:'flex-end' }} onFinish={incluir}>						
						<FormItem label="Status" name="participanteAereoStatusId" rules={[
							{ required: true, message: "Selecione o status" }
						]}>
							<Select style={{ width: '100%', minWidth: '300px' }} onChange={(e) => setarStatus(e)}>
								<Option value={1}>Pendente</Option>
								<Option value={2}>Confirmado</Option>								
							</Select>
						</FormItem>
					</Form>
				</TabPane>
			</Tabs>

			{onClose && (
				<div className="acoes">
					<Button icon={<FaBan />} type='primary' className='button-default' onClick={onClose}>Fechar</Button>
				</div>
			)}
		</>
	);
}

export default AereoParticipantes;