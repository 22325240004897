import Table, { ColumnsType } from "antd/lib/table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useApp } from "../../../../providers/AppProvider";
import { HospedagemEntity } from "../../../../entities/HospedagemEntity";
import { Hotel } from "../../../../entities/Hotel";
import { Result } from "../../../../interfaces/Result";
import { Button, Col, message, Modal, Popconfirm, Row, Space, Tag, Tooltip } from "antd";
import { FaEdit, FaFileCsv, FaFilePdf, FaInfo, FaPlus, FaTrash, FaUsers } from "react-icons/fa";
import HospedagemStatusForm from "../../forms/Hospedagem/HospedagemStatusForm";
import HospedagemStatus from "./HospedagemStatus";
import HospedagemQuartoTipo from "./HospedagemQuartoTipo";
import { MdLocalHotel } from "react-icons/md";
import HotelEditar from "../../components/HotelEditar";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { HiOutlineInformationCircle } from "react-icons/hi";
import HospedagemForm from "../../forms/Hospedagem/HospedagemForm";
import HospedagemDetalhes from "../../forms/Hospedagem/HospedagemDetalhesForm";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";

const Hospedagem: React.FC = () => {
	const app = useApp();
    const [modalStatus, setModalStatus] = useState(false);
    const [modalTipoQuarto, setModalTipoQuarto] = useState(false);
    const [modalDadosHotel, setModalDadosHotel] = useState(false);
	const [modalCadastro, setModalCadastro] = useState(false);
	const [modalDetalhes, setModalDetalhes] = useState(false);
	const [hospedagem, setHospedagem] = useState<HospedagemEntity | null>(null);
	const [hotel, setHotel] = useState<Hotel | null>(null);
	const [hospedagens, setHospedagens] = useState<HospedagemEntity[]>([]);
	const [hospedagensExp, setHospedagensExp] = useState<HospedagemEntity[]>([]);
	const [desabilitaBotao, setDesabilitaBotao] = useState(true);

	const TabelaHospedagem: ColumnsType<HospedagemEntity> = [		
		{
			title:"Nome",
			dataIndex: "nomeHospedagem"
		},		
		{
			title: "Hotel",
			render: (hospedagem: HospedagemEntity) => (
				<>
					{hospedagem.hotel ? (
						hospedagem.hotel.nome + "   "
						) : (
							<i className="vazio">Hotel não informado</i>
						)}							
						<Button size="small" shape="circle" onClick={() => dadosHotel(hospedagem.hotel)}><FaInfo /></Button>						
				</>
			),
		},
		{
			title: "Status",
			render: (hospedagem: HospedagemEntity) => (
				<>
					{hospedagem.hospedagemStatus ? (
						<Tag color={hospedagem.hospedagemStatus?.cor}>{hospedagem.hospedagemStatus.nome}</Tag>
						
					) : (
						<i className="vazio">Status não informado</i>
					)}
				</>
			),
		},
		{
			title:"Nº de Quartos",
			dataIndex: "numQuartos"
		},
		{
			title:"Nº de Pessoas",
			dataIndex: "numPessoas"
		},
		{
			title:"Vagas Dispóníveis",
			dataIndex: "numVagasDisponiveis"
		},
		{
			title:"Valor Hospedagem",
			dataIndex: "valor",			
    		align: 'right',			
		},
		{
			render: ((hospedagem: HospedagemEntity) => (
				<>
					<Popconfirm placement='topLeft'
						title={<span>Você confirma a EXCLUSÃO da hospedagem no hotel<br /><strong>{hospedagem.hotel.nome}</strong></span>}
						onConfirm={() => excluirHospedagem(hospedagem)}>
						<Button size='small' type='primary' danger className='button-icon'><FaTrash />Excluir</Button>
					</Popconfirm>
					
					<Button size='small' type='default' title="Detalhes da hospedagem com seus respectivos participantes" icon={<FaInfo />} onClick={() => detalhesHospedagem(hospedagem)}>Detalhes</Button>
				</>
			))
		}
	];

    const carregaHospedagem = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `hospedagem`);
		if (result?.code === 200) {	
			//setDesabilitaBotao(false);							
			setHospedagens(result.dados);
		}		

	}, [app]);

    const excluirHospedagem = useCallback(async (hospedagem: HospedagemEntity) => {
		let result: Result | null = await app.ajaxApi("DELETE", `hospedagem/${hospedagem.id}`);
		if (result?.code === 200) {
			carregaHospedagem();			
		}
	}, [app, carregaHospedagem]);


	const novoHospedagem = useCallback(() => {
		setHospedagem(null);
		setModalCadastro(true);
	}, []);


	const detalhesHospedagem = useCallback((hospedagem: HospedagemEntity) => {		
		setHospedagem(hospedagem);
		setModalDetalhes(true);
	}, []);
	
	const dadosHotel = useCallback((hotel: Hotel) => {		
		setHotel(hotel);
		setModalDadosHotel(true);
	}, []);

	// FUNÇÕES PARA EXPORTAR CSV E PDF     
    useMemo(() => {
		// let result: Result | null = await app.ajaxApi("GET", "hospedagem");			
		// let hospedagens: HospedagemEntity[] = result?.dados;						
		let lstHospedagens: any = [];		
				
		hospedagens?.forEach((a: HospedagemEntity) => {			
			let item: any = {};			
			item["Nome"] = a.nomeHospedagem;
			//DADOS HOTEL
			item["Hotel"] = a.hotel.nome;			
			item["Logradouro:"] =  `${a.hotel.logradouro} Nª ${a.hotel.numero}`;          
			item["Bairro"] =  a.hotel.bairro;
			item["Cidade"] =  a.hotel.cidade;
			item["UF"] =  a.hotel.estado;
			item["País"] =  a.hotel.pais;
			item["CEP"] =  a.hotel.cep;
			item["Status"] = a.hospedagemStatus.nome;
			item["Nº de Quartos"] = a.numQuartos;
			item["Nº de Pessoas"] = a.numPessoas;
			item["Nº Vagas Disponíveis"] = a.numVagasDisponiveis;
			item["Valor Hospedagem"] = a.valor;			
										
			lstHospedagens.push(item);				
		});
		
		if (lstHospedagens.length > 0) {
			setDesabilitaBotao(false);
		} else {
			setDesabilitaBotao(true);			
		}
		setHospedagensExp(lstHospedagens);			
    }, [hospedagens]);


    const componentRef = useRef<any>();         

    const handlePrint = useReactToPrint({    
        content: () => componentRef.current        
    });

    const preparaPDF = useCallback(() => {        
        handlePrint();
    }, [handlePrint]);
    // FIM FUNÇÕES

	useEffect(() => {		
		carregaHospedagem();
	}, [carregaHospedagem]);

	return (
		<>
			<h2>
				<MdLocalHotel />
				Hospedagens
			</h2>

            <div className="submenu">	
				<div style={{margin: '0 15px'}}>
					<Tooltip placement="rightBottom" zIndex={0} open={desabilitaBotao}	title={"Habilita quando houver hotéis cadastrados"}>
						<Space style={{float:"right"}}>        
							<Button type='primary' className='button-success' onClick={novoHospedagem}><FaPlus />Novo</Button>	
								<Button type="primary" disabled={desabilitaBotao}>                        
									<CSVLink separator=";" filename={"Hospedagens.csv"} data={hospedagensExp} className="btn btn-primary" onClick={()=>{ message.success("Baixando arquivo")}}>
										<FaFileCsv/>Exportar CSV 
									</CSVLink>                                 
								</Button>
								{/* <Button onClick={handlePrint} type="primary" danger><FaFilePdf /> Exportar PDF </Button> */}
						</Space>
					</Tooltip>
				</div>

				<div style={{margin: '0 15px'}}>
					<Button type='primary' className='button-default' onClick={() => setModalStatus(true)}><FaPlus />Gerenciar Status</Button>						
					<Button type='primary' className='button-default' onClick={() => setModalTipoQuarto(true)}><FaPlus />Gerenciar Tipo Quarto </Button>				
				</div>			
			</div>

			<div style={{width:"100%"}} >
				<div ref={componentRef}>
					{
						hospedagensExp &&																	
						 <Table size='small' pagination={{ pageSize: 100 }} columns={TabelaHospedagem} dataSource={hospedagens} defaultExpandAllRows={true}></Table>
					}
				</div>
			</div>


			<Modal width={1000} open={modalCadastro} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalCadastro(false)}>
				<HospedagemForm data={hospedagem} onClose={() => setModalCadastro(false)} onSuccess={carregaHospedagem} />
			</Modal>

			<Modal width={1000} open={modalStatus} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalStatus(false)}>
				<HospedagemStatus  />
			</Modal>

			<Modal width={1000} open={modalTipoQuarto} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalTipoQuarto(false)}>
				<HospedagemQuartoTipo />
			</Modal>

			<Modal width={1000} open={modalDadosHotel} footer={false} closable destroyOnClose onCancel={() => setModalDadosHotel(false)}>
                <HotelEditar data={hotel} onClose={() => setModalDadosHotel(false)} onSuccess={carregaHospedagem}/>
            </Modal>

			<Modal width={1000} open={modalDetalhes} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalDetalhes(false)}>
				<HospedagemDetalhes data={hospedagem} onClose={() => setModalDetalhes(false)} onSuccess={carregaHospedagem} />
			</Modal>			
		</>
	);
};

export default Hospedagem;
