import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Password from 'antd/lib/input/Password';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { AereoCompanhia } from '../../../../entities/aereo-companhia';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';


interface AereoCompanhiaEditarFormProps {
	data: AereoCompanhia | null,
	onSuccess?(companhia: AereoCompanhia): void,
	onClose?(): void
}



const AereoCompanhiaEditarForm: React.FC<AereoCompanhiaEditarFormProps> = ({
	data,
	onSuccess,
	onClose
}) => {

	const app = useApp();
	const [loading, setLoading] = useState(false);
	const [form] = useForm();


	const salvar = useCallback(async (formData: any) => {
		setLoading(true);
		let result: Result | null;
		if (data) {
			// ALTERAR
			result = await app.ajaxApi("PUT", `aereo/companhia/${data.id}`, formData);
		} else {
			// INSERIR
			result = await app.ajaxApi("POST", `aereo/companhia`, formData);
		}
		
		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);

			if (onClose)
				onClose();
		}

		setLoading(false);
	}, [app, onSuccess, onClose, data]);



	useEffect(() => {
		if (data)
			form.setFieldsValue(data);			
	}, [data, form]);


	return (
		<>
			<h2>
				<FaEdit />
				Editar Companhia Aérea
			</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<FormItem label="Nome da companhia aérea" name="nome" rules={[
						{ required: true, message: "Informe o nome" }
					]}>
					<Input />
				</FormItem>

				<div className="acoes">
					{onClose && (
						<Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>	
					)}
					<Button type='primary' className='button-success' icon={<FaCheck />} htmlType="submit" loading={loading}>Salvar</Button>
				</div>
			</Form>
		</>		
	);
}

export default AereoCompanhiaEditarForm;