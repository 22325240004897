import { Alert } from 'antd';
import React from 'react';
import { Result } from '../interfaces/Result';
import ListError from './ListError';


interface BoxErrorProps {
	result: Result | null
}

const BoxError: React.FC<BoxErrorProps> = ({
	result
}) => {
  return (
	  <>
	  	{result != null && (
			  <Alert type='warning' style={{marginBottom: '20px'}} showIcon message={(
				  <>
				  	{result.mensagem}
				  </>
			  )}
			  description={<ListError errors={result.dados} />} />
		  )}
	  </>
  );
}
export default BoxError;