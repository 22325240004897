import { Input } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { InputCustomProps } from '../interfaces/InputCustomProps';

import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

const InputFloat: React.FC<InputCustomProps> = ({
	value,
	onChange
}) => {

	useEffect(() => {		
		$(".areaFloat input").addClass("maskFloat");
		$(".maskFloat").mask("000.000.000.000,00", { reverse: true });
	}, []);


	const atualiza = useCallback((e: any) => {
		if (onChange)
			onChange(e.target.value);
			
	}, [onChange]);



	return (
		<>
			<Input placeholder='0,00' className='areaFloat' addonBefore="R$" onChange={(e) => atualiza(e)} onBlur={(e) => atualiza(e)} value={value} onKeyUp={(e) => atualiza(e)} />
		</>
	);
}

export default InputFloat;