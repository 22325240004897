import { Button, Modal, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { MdAirplaneTicket } from 'react-icons/md';
import AereoOrigemDestino from '../../../components/AereoOrigemDestino';
import { Participante } from '../../../entities/participante';
import { ParticipanteAereo } from '../../../entities/participante-aereo';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import ParticipanteAereoEditar from './ParticipanteAereoEditar';

import moment from 'moment';
import AereoStatus from '../../../components/AereoStatus';
import ParticipanteAereoStatus from '../../../components/ParticipanteAereoStatus';
import AereoTipo from '../../../components/AereoTipo';

interface ParticipanteAereosProps {
	participanteId: string | null,
	onClose?(): void
}



const ParticipanteAereos: React.FC<ParticipanteAereosProps> = ({
	participanteId,
	onClose
}) => {

	const app = useApp();

	const [aereosParticipante, setAereosParticipante] = useState<ParticipanteAereo[]>([]);
	const [aereoParticipanteSelecionado, setAereoParticipanteSelecionado] = useState<ParticipanteAereo | null>(null);
	const [modalAereoEditar, setModalAereoEditar] = useState(false);



	const tabelaParticipanteAereo: ColumnsType<ParticipanteAereo> = [
		{
			title: 'Sentido',
			render: (participanteAereo: ParticipanteAereo) => (
				<AereoTipo aereo={participanteAereo.aereo} />
			)
		},
		{
			title: 'Origem / Destino',
			render: (participanteAereo: ParticipanteAereo) => (
				<AereoOrigemDestino aereo={participanteAereo.aereo} />
			)
		},
		{
			title: 'Nº do Voo',
			render: (participanteAereo: ParticipanteAereo) => (
				<span>{participanteAereo.aereo.numVoo}</span>
			)
		},
		{
			title: 'Embarque',
			render: (participanteAereo: ParticipanteAereo) => (
				<>
					{participanteAereo.aereo.dataHoraEmbarque ? moment(participanteAereo.aereo.dataHoraEmbarque).format("DD/MM/YYYY - HH:mm") : 'Não definido'}
				</>
			)
		},
		{
			title: 'Status',
			render: (participanteAereo: ParticipanteAereo) => <ParticipanteAereoStatus participanteAereo={participanteAereo} />
		},
		{
			render: (participanteAereo: ParticipanteAereo) => (
				<>
					<Button type='primary' icon={<FaEdit />}>Editar</Button>
					<Popconfirm title={<span>Você confirma a EXCLUSÃO deste aéreo?</span>} onConfirm={() => excluirAereo(participanteAereo)}>
						<Button type='primary' danger className='button-icon'><FaTrash /></Button>
					</Popconfirm>					
				</>
			)
		}
	];


	const carregaParticipanteAereo = useCallback(async() => {
		let result: Result | null = await app.ajaxApi("GET", `participante/aereo/listar/${participanteId}`);
		console.log(result?.dados);
		if (result?.code === 200)
			setAereosParticipante(result.dados);
	}, [app, participanteId]);


	const excluirAereo = useCallback(async(participanteAereo: ParticipanteAereo) => {
		let result: Result | null = await app.ajaxApi("DELETE", `participante/${participanteAereo.participanteId}/aereo/${participanteAereo.aereoId}`);
		if (result?.code === 200)
			carregaParticipanteAereo();

	}, [app, carregaParticipanteAereo]);



	



	useEffect(() => {
		carregaParticipanteAereo();
	}, [carregaParticipanteAereo]);


	return (
		<>
			<h2>
				<MdAirplaneTicket />
				Aéreo do Participante
			</h2>	

			<div className="submenu" style={{ marginBottom: '15px' }}>
				<Button type='primary' className='button-success' onClick={() => setModalAereoEditar(true)}>
					<FaPlus />
					Novo Voo	
				</Button>				
			</div>

			<Table pagination={false} size='small' columns={tabelaParticipanteAereo} dataSource={aereosParticipante} />
			
			{onClose && (
				<div className="acoes">
					<Button className='button-default' type='primary' icon={<FaBan />} onClick={onClose}>Fechar</Button>
				</div>
			)}



			<Modal width={1000} closable maskClosable={false} destroyOnClose footer={false} open={modalAereoEditar} onCancel={() => setModalAereoEditar(false)}>
				<ParticipanteAereoEditar participanteId={participanteId} participanteAereo={aereoParticipanteSelecionado} onClose={() => setModalAereoEditar(false)} onSuccess={carregaParticipanteAereo} />
			</Modal>
			
		</>
	);
}

export default ParticipanteAereos;