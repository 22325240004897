import React, { ReactElement } from 'react';


interface TextInfoProps {
	label: string,
	text?: string,
	element?: ReactElement
}


const TextInfo: React.FC<TextInfoProps> = ({
	label,
	text,
	element: Elemento
}) => {
  return (
	<div style={{ display: 'flex', flexDirection: 'column' }}>
		<p style={{ margin: 0 }}>{label}</p>
		{text && <strong>{text}</strong>}
		{Elemento && Elemento }
	</div>
  );
}

export default TextInfo;