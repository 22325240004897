import React, { useCallback } from 'react';
import { MdOutlineSettings } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import AuthAdminForm from '../../forms/LoginForm';

import './Login.css';


const Login: React.FC = () => {

	const navigate = useNavigate();
	
	
	const loginSuccess = useCallback(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('area') === "admin") {
			navigate("/admin/painel");
		} else if (params.get('area') === "parceiro") {
			navigate("/parceiro/inicio");
		} else {
			navigate("/auth/areas");
		}
	}, [navigate]);


	return (
		<>
			<h1>
				<MdOutlineSettings />
				Acesso ao Sistema
			</h1>

			<AuthAdminForm onSuccess={loginSuccess} />
		</>
	);
}

export default Login;