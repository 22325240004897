import { Button, Col, Form, Input, notification, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Result } from '../../../interfaces/Result';
import InputTelefone from '../../../masks/InputTelefone';
import { useApp } from '../../../providers/AppProvider';


interface MeusDadosFormProps {
	data?: any
}


const MeusDadosForm: React.FC<MeusDadosFormProps> = ({
	data
}) => {

	const [form] = useForm();
	const app = useApp();
	const [loading, setLoading] = useState(false);


	const salvar = useCallback(async (dataForm: any) => {
		setLoading(true);

		let result: Result | null = await app.ajaxApi('put', `cadastro/meus-dados`, dataForm);
		if (result?.code === 200) {
			notification.success({
				message: "Dados atualizados",
				duration: 5,
				placement: 'bottomRight'
			});
		}

		setLoading(false);
	}, [app]);


	useEffect(() => {
		if (data) {
			let dadosForm = {
				...data,
				pessoa: data.tipoNome,
			}
			if (data.tipo === 1)
				dadosForm["cpf"] = data.documento;

			if (data.tipo === 2)
				dadosForm["cnpj"] = data.documento;
			
			form.setFieldsValue(dadosForm);
		}

	}, [data, form]);

	return (
		<>
			<Form layout='vertical' form={form} onFinish={salvar}>
				<Row gutter={25}>
					<Col md={12}>
						<Form.Item label="ID" name="id">
							<Input disabled />
						</Form.Item>
					</Col>

					<Col md={12}>
						<Form.Item label="Código" name="codigo">
							<Input disabled />
						</Form.Item>
					</Col>

					<Col md={12}>
						<Form.Item label="Pessoa" name="pessoa">
							<Input disabled />
						</Form.Item>
					</Col>

					<Col md={12}>
						{data?.tipo === 1 && (
							<Form.Item label="CPF" name="cpf">
								<Input disabled />
							</Form.Item>
						)}

						{data?.tipo === 2 && (
							<Form.Item label="CNPJ" name="cnpj">
								<Input disabled />
							</Form.Item>
						)}						
					</Col>

					<Col md={12}>
						<Form.Item label="Nome" name="nome" rules={[
							{ required: true, message: "Informe o nome" }
						]}>
							<Input />
						</Form.Item>
					</Col>

					<Col md={12}>
						<Form.Item label="E-mail" name="email" rules={[
							{ required: true, message: "Informe o e-mail" }
						]}>
							<Input />
						</Form.Item>
					</Col>

					<Col md={12}>
						<Form.Item label="Telefone" name="telefone">
							<InputTelefone />
						</Form.Item>
					</Col>

					<Col md={12}>
						<Form.Item label="Celular" name="celular" rules={[
							{ required: true, message: "Informe o celular" }
						]}>
							<InputTelefone />
						</Form.Item>
					</Col>

					<Col md={12}>
						<Form.Item label="Data de Cadastro" name="created">
							<Input disabled />
						</Form.Item>
					</Col>

					<Col md={12}>
						<Form.Item label="Última Alteração" name="modified">
							<Input disabled />
						</Form.Item>
					</Col>
				</Row>

				<div className="acoes">
					<Button type='primary' className='button-success' icon={<FaCheck />} htmlType="submit" loading={loading}>Salvar</Button>
				</div>
				
			</Form>
		</>
	);
}

export default MeusDadosForm;