import React, { useCallback, useEffect, useState } from 'react';
import { useApp } from '../../../../providers/AppProvider';
import Table, { ColumnsType } from 'antd/lib/table';
import { Button, Modal, Popconfirm, Tag } from 'antd';
import { Result } from '../../../../interfaces/Result';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import FollowUpStatusForm from '../../forms/FollowUp/FollowUpStatusForm';
import { AiFillSignal, AiOutlineCheckCircle } from 'react-icons/ai';
import { BsXCircle } from 'react-icons/bs';
import { FollowUpStatus as FollowUpStatusEntity } from '../../../../entities/follow-up-status';

const FollowUpStatus: React.FC = () => {

    const app = useApp();
    const [folloUpStatusLst, setFollowUpStatusLst] = useState<FollowUpStatusEntity[]>([]);
    const [modal, setModal] = useState(false);
    const [folloUpStatus, setFollowUpStatus] = useState<FollowUpStatusEntity | null>(null);

    //DEFINIÇÃO DAS COLUNAS
    const tabelaFollowUpStatus: ColumnsType<FollowUpStatusEntity> = [
        {
            title: "Nome",
            dataIndex: "nome"
        },        
        {
            title: "Cor",
            dataIndex: "cor",
            render: (_, { cor }) => (
                <>                    
                    <Tag color={cor}>
                        {cor}
                    </Tag>
                </>
            ),
        },

        {
            title: "Inserir",
            render: (status: FollowUpStatusEntity) => (
				<>
					{status?.exibirInserir ? (
                        <AiOutlineCheckCircle color="green"/>						
					) : (
                        <BsXCircle color="red"/>						
					)}
				</>
			),
            align: 'center'            
        },
        {
            title: "Alterar",
            render: (status: FollowUpStatusEntity) => (
				<>
					{status?.exibirAlterar ? (
                        <AiOutlineCheckCircle color="green"/>						
					) : (
                        <BsXCircle color="red"/>						
					)}
				</>
			),
            align: 'center'           
        },
        {
            title: "Interação",
            render: (status: FollowUpStatusEntity) => (
				<>
					{status?.exibirInteracao ? (
                        <AiOutlineCheckCircle color="green"/>						
					) : (
                        <BsXCircle color="red"/>						
					)}
				</>
			),
            align: 'center'            
        },
        {
            title: "Notificar",
            render: (status: FollowUpStatusEntity) => (
				<>
					{status?.notificar ? (
                        <AiOutlineCheckCircle color="green"/>						
					) : (
                        <BsXCircle color="red"/>						
					)}
				</>
			),
            align: 'center'            
        },
        {
            title: "Concluir",
            render: (status: FollowUpStatusEntity) => (
				<>
					{status?.concluir ? (
                        <AiOutlineCheckCircle color="green"/>						
					) : (
                        <BsXCircle color="red"/>						
					)}
				</>
			),
            align: 'center'
        },
        {
            title: "Ordem",
            render: (status: FollowUpStatusEntity) => (
				<>
					{status?.ordem ? (
                        <AiOutlineCheckCircle color="green"/>						
					) : (
                        <BsXCircle color="red"/>						
					)}
				</>
			),
            align: 'center'
        },

        {
            render: (folloUpStatus: FollowUpStatusEntity) => (
                <>
                    <Popconfirm placement='topLeft'
                        title={<span>Você confirma a EXCLUSÃO do status<br /><strong>{folloUpStatus.nome}</strong></span>}
                        onConfirm={() => excluir(folloUpStatus)}>
                        <Button size='small' type='primary' danger icon={<FaTrash />}>Excluir</Button>
                    </Popconfirm>

                    <Button size='small' type='primary' icon={<FaEdit />} onClick={() => editarFollowUpStatus(folloUpStatus)}>Editar</Button>
                </>
            )
        }
    ];

    const carregaFollowUpStatus = useCallback(async () => {
        let result: Result | null = await app.ajaxApi("GET", `follow-up/status`);
        if (result?.code === 200) {
            setFollowUpStatusLst(result.dados);
        }
    }, [app]);

    const excluir = useCallback(async (folloUpStatusSel: FollowUpStatusEntity) => {
        let result: Result | null = await app.ajaxApi("DELETE", `follow-up/status/${folloUpStatusSel.id}`);

        if (result?.code === 200) {
            carregaFollowUpStatus();
        }
    }, [app, carregaFollowUpStatus]);

    const editarFollowUpStatus = useCallback(async (folloUpStatus: FollowUpStatusEntity) => {
        setModal(true);
        setFollowUpStatus(folloUpStatus);
    }, []);

    const novoFollowUpStatus = useCallback(() => {
        setModal(true);
        setFollowUpStatus(null);

    }, []);

    useEffect(() => {
        carregaFollowUpStatus();
    }, [carregaFollowUpStatus]);

    return (
        <>
            <h1>
                <AiFillSignal />
                Gerenciar Status
            </h1>

            <div className="submenu">
                <Button type='primary' className='button-success' onClick={novoFollowUpStatus}>
                    <FaPlus />Novo Status
                </Button>
            </div>

            <Table size='small' pagination={{ pageSize: 100 }} columns={tabelaFollowUpStatus} dataSource={folloUpStatusLst}></Table>

            <Modal open={modal} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModal(false)}>
                <FollowUpStatusForm data={folloUpStatus} onClose={() => setModal(false)} onSuccess={carregaFollowUpStatus} />
            </Modal>
        </>
    )
}

export default FollowUpStatus;
