import { Button, Input, InputNumber } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useCallback, useState, useEffect } from 'react';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { HospedagemQuartoTipoEntity } from '../../../../entities/HospedagemQuartoTipoEntity';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';

interface HospedagemQuartoProps {
    data: HospedagemQuartoTipoEntity | null,
    onSuccess?(hospedagemStatus: HospedagemQuartoTipoEntity): void,
    onClose?(): void
}

const HospedagemQuartoForm: React.FC<HospedagemQuartoProps> = ({
    data, onClose, onSuccess
}) => {

    const app = useApp();
    const [loading, setLoading] = useState(false);
    const [form] = useForm();    

    const salvar = useCallback(async (formData: any) => {
        setLoading(true);
        let result: Result | null;        

        if (data)
            result = await app.ajaxApi("PUT", `hospedagem/quarto/tipo/${data.id}`, formData);
        else
            result = await app.ajaxApi("POST", `hospedagem/quarto/tipo`, formData);


        if (result?.code === 200) {
            if (onSuccess)
                onSuccess(result.dados);

            if (onClose)
                onClose();
        }
        setLoading(false);
    }, [app, onClose, onSuccess, data]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [data, form]);
    
    return (
        <>
            <h2>
                <FaEdit />
                Editar Tipo de Quarto
            </h2>
            
            <Form form={form} layout="vertical" onFinish={salvar}>
                <FormItem label="Nome do Tipo de Quarto" name="nome"
                    rules={[{ required: true, message: "Informe o nome" }]}>
                    <Input maxLength={100} />
                </FormItem>

                <FormItem label="Nº Pessoas" name="numPessoas"
                    rules={[{ required: true, message: "Informe o número de pessoas comportadas pelo quarto" }]}>
                    <InputNumber  style={{width: '100%'}}/>
                </FormItem>
                
                <div className="acoes">
                    {onClose && (
                        <Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
                    )}
                    <Button htmlType='submit' type='primary' className='button-success' icon={<FaCheck />} loading={loading}>Salvar</Button>
                </div>
            </Form>
            
        </>
    )
}

export default HospedagemQuartoForm;