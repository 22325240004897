import { Button, Form, Select, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBan, FaCheck, FaPlus } from 'react-icons/fa';
import { FollowUpStatus } from '../../../../entities/follow-up-status';
import FormProps from '../../../../interfaces/FormProps';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';



const FollowUpNovaInteracaoForm: React.FC<FormProps> = ({
	data,
	onSuccess,
	onClose
}) => {

	const app = useApp();
	const { Option } = Select;
	const [form] = useForm();

	const [loading, setLoading] = useState(false);
	const [followUpStatus, setFollowUpStatus] = useState<FollowUpStatus[]>([]);


	const carregaStatus = useCallback(async() => {
		let result: Result | null = await app.ajaxApi("get", 'follow-up/status');
		if (result) {
			setFollowUpStatus(result.dados);
			form.setFieldsValue({ followUpStatusId: data.followUpStatusId });
		}
	}, [app, form, data]);


	const salvar = useCallback(async (formData: any) => {
		setLoading(true);
		let result: Result | null = await app.ajaxApi('POST', `follow-up/${data.id}/interacao`, formData);
		if (result) {
			app.calculaFollowUps();

			if (onSuccess)				
				onSuccess(result.dados);
			
			setLoading(false);
			if (onClose)
				onClose();
		}

		setLoading(false);
	}, [app, data, onSuccess, onClose]);


	useEffect(() => {
		carregaStatus();
	}, [carregaStatus]);


	return (
		<>
			<h2>
				<FaPlus />
				Nova Interação
			</h2>

			<Form form={form} layout='vertical' onFinish={salvar}>
				<FormItem label="Status" name="followUpStatusId">
					<Select>
						{followUpStatus.map((status: FollowUpStatus) => { 
							if (status.exibirAlterar) {
								return (
									<Option key={status.id} value={status.id}>
										<Tag color={status.cor}>{status.nome}</Tag>
									</Option>
								);
							}
							
							return null;
						})}
					</Select>
				</FormItem>

				<FormItem label="Descrição" name="descricao" rules={[
					{ required: true, message: "Informe a descrição" }
				]}>
					<TextArea rows={10} />
				</FormItem>

				<div className="acoes">
					{onClose && (
						<Button icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}
					<Button loading={loading} className='button-success' icon={<FaCheck />} htmlType="submit" type='primary'>Salvar</Button>
				</div>
			</Form>
		</>
	);
}

export default FollowUpNovaInteracaoForm;