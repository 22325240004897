import React, { useCallback, useEffect, useState } from 'react'
import { useApp } from '../../../../providers/AppProvider';
//FORM
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { Result } from '../../../../interfaces/Result';
import SelectCor from '../../../../masks/SelectCor';
import { FollowUpStatus } from '../../../../entities/follow-up-status';

interface FollowUpStatusFormProps {
    data: FollowUpStatus | null,
    onSuccess?(followUpStatus: FollowUpStatus): void,
    onClose?(): void
}

const FollowUpStatusForm: React.FC<FollowUpStatusFormProps> = ({
    data, onClose, onSuccess
}) => {
    const app = useApp();
    const [loading, setLoading] = useState(false);
    const [form] = useForm();    

    const salvar = useCallback(async (formData: any) => {
        setLoading(true);
        let result: Result | null;        

        if (data)
            result = await app.ajaxApi("PUT", `follow-up/status/${data.id}`, formData);
        else
            result = await app.ajaxApi("POST", `follow-up/status`, formData);


        if (result?.code === 200) {
            if (onSuccess)
                onSuccess(result.dados);

            if (onClose)
                onClose();
        }
        setLoading(false);
    }, [app, onClose, onSuccess, data]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [data, form]);

    return (
        <>
            <h2>
                <FaEdit />
                Editar Status
            </h2>

            <Form form={form} layout="vertical" onFinish={salvar}>
                <FormItem label="Nome status" name="nome"
                    rules={[{ required: true, message: "Informe o nome" }]}>
                    <Input maxLength={100} />
                </FormItem>

                <FormItem label="Cor" name="cor"
                    rules={[{ required: true, message: "Informe a cor" }]}>
                    <SelectCor />
                </FormItem>

                <Row gutter={25}>
                    <Col md={8}>
                        <FormItem name="exibirInserir" valuePropName='checked'>
                            <Checkbox>Exibir Inserir</Checkbox>
                        </FormItem>                        
                    </Col>

                    <Col md={8}>
                        <FormItem name="exibirAlterar" valuePropName='checked'>
                            <Checkbox>Exibir Alterar</Checkbox>
                        </FormItem>                        
                    </Col>

                    <Col md={8}>
                        <FormItem name="exibirInteracao" valuePropName='checked'>
                            <Checkbox>Exibir Interação</Checkbox>
                        </FormItem>                        
                    </Col>
                </Row>

                <Row gutter={25}>
                    <Col md={8}>
                        <FormItem name="notificar" valuePropName='checked'>
                            <Checkbox>Notificar</Checkbox>
                        </FormItem>                        
                    </Col>

                    <Col md={8}>
                        <FormItem name="concluir" valuePropName='checked'>
                            <Checkbox>Concluir</Checkbox>
                        </FormItem>                        
                    </Col>

                    <Col md={8}>
                        <FormItem name="ordem" valuePropName='checked'>
                            <Checkbox>Ordem</Checkbox>
                        </FormItem>                        
                    </Col>
                </Row>

                <div className="acoes">
                    {onClose && (
                        <Button type='primary' className='button-default' icon={<FaBan />} onClick={onClose}>Cancelar</Button>
                    )}
                    <Button htmlType='submit' type='primary' className='button-success' icon={<FaCheck />} loading={loading}>Salvar</Button>
                </div>
            </Form>
        </>
    )
}

export default FollowUpStatusForm