import { Alert, Button, Col, DatePicker, Form, Input, Row, Select, Tag } from 'antd';
import React, { useCallback, useState, useEffect } from 'react'
import { FaBan, FaCheck, FaEdit } from 'react-icons/fa';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';

// ENTIDADES
import { FollowUp } from '../../../../entities/follow-up';
import { useApp } from '../../../../providers/AppProvider';
import { Result } from '../../../../interfaces/Result';
import { AdminCategoria } from '../../../../entities/admin-categoria';
import { FollowUpStatus } from '../../../../entities/follow-up-status';
import { Admin } from '../../../../entities/admin';
import TooltipForm from '../../../../components/TooltipForm';
import moment from 'moment';

interface FollowUpProps {
	data: FollowUp | null,
	onSuccess?(followUp: FollowUp): void,
	onClose?(): void
}

const FollowUpForm: React.FC<FollowUpProps> = ({
	data, onSuccess, onClose
}) => {

	const app = useApp();
	const [loading, setLoading] = useState(false);
	const [form] = useForm();
	const { Option, OptGroup } = Select;
	const { TextArea } = Input;
	const [componentDisabled, setComponentDisabled] = useState<boolean>(false);

	const [categorias, setCategorias] = useState<AdminCategoria[]>([]);
	const [status, setStatus] = useState<FollowUpStatus[]>([]);
	const [admins, setAdmins] = useState<Admin[]>([]);
	const [mensagem, setMensagem] = useState(true);

	const salvar = useCallback(async (formData: any) => {
		setLoading(true);
		let result: Result | null;

		// Trata a opção do encaminhamento
		if (formData.encaminhar) {
			let aux = formData.encaminhar.split("|");
			if (aux[0] === "categoria")
				formData["adminCategoriaId"] = aux[1];

			if (aux[0] === "usuario")
				formData["cadastroIdResponsavel"] = aux[1];
		}

		if (data)
			result = await app.ajaxApi("PUT", `follow-up/${data.id}`, formData);
		else
			result = await app.ajaxApi("POST", `follow-up`, formData);

		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);

			if (onClose)
				onClose();
		}
		setLoading(false);
	}, [app, onClose, onSuccess, data]);

	const carregarCategorias = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `admin/categoria/listar`);
		if (result?.code === 200) {
			setCategorias(result.dados);
		}
	}, [app]);

	const carregarStatus = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `follow-up/status`);
		if (result?.code === 200) {
			if (result.dados.length > 0) {			 
				setMensagem(true);                
            } else {
				setMensagem(false);
            }
			setStatus(result.dados);

			if (!data) {
				let statusList: FollowUpStatus[] = result.dados;
				let opcoes: string[] = [];
				statusList.forEach((item: FollowUpStatus) => {
					if (item.exibirInserir)
						opcoes.push(item.id);
				});
				if (opcoes.length > 0)
					form.setFieldsValue({ followUpStatusId: opcoes[0] });
			}
		}
	}, [app, data, form]);

	const carregarAdmins = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `admin`);
		if (result?.code === 200) {
			setAdmins(result.dados);
		}
	}, [app]);


	const desbilitaForm = (async (disabled: boolean) => {
		setComponentDisabled(disabled);
	});

	useEffect(() => {
		carregarCategorias();
		carregarStatus();
		carregarAdmins();

		if (data) {
			let dados: any = data;

			if (data.adminCategoria)
				dados["encaminhar"] = `categoria|${data.adminCategoriaId}`;

			if (data.cadastroResponsavel)
				dados["encaminhar"] = `usuario|${data.cadastroIdResponsavel}`;

			if (data.dataPrevisao) {
				dados["dataPrevisao"] = moment(data.dataPrevisao);
			}

			if (data.dataConclusao) {
				dados["dataConclusao"] = moment(data.dataConclusao);				
				desbilitaForm(true);
			}

			form.setFieldsValue(dados);
		}


	}, [data, form, carregarCategorias, carregarStatus, carregarAdmins]);

	return (
		<>
			<h2>
				<FaEdit />
				Editar Follow Up
			</h2>

			<Form form={form} layout="vertical" onFinish={salvar} disabled={componentDisabled} >
				{
					!mensagem &&
					<Alert type='warning' message="Para realizar o cadastro é necessário ter status de follow-up cadastrados. " showIcon />
				}

				<Row gutter={25}>
					<Col md={12}>
						<FormItem label="Encaminhar para" name="encaminhar"
							rules={[{ required: false, message: "Informe a categoria" }]}>
							<Select style={{ width: '100%' }}>
								<OptGroup label="Categoria de Usuário">
									{categorias.map((adminCategoria: AdminCategoria) => (
										<Option key={adminCategoria.id} value={`categoria|${adminCategoria.id}`}>{adminCategoria.nome}</Option>
									))}
								</OptGroup>
								<OptGroup label="Usuário do Sistema">
									{admins.map((admin: Admin) => (
										<Option key={admin.id} value={`usuario|${admin.cadastroId}`}>
											<strong style={{ marginRight: '10px' }}>{admin.cadastro.nome}</strong>
										</Option>
									))}
								</OptGroup>
							</Select>
						</FormItem>

						<FormItem label="Título" name="titulo"
							rules={[{ required: true, message: "Informe o título" }]}>
							<Input maxLength={100} />
						</FormItem>

						<FormItem label="Status" name="followUpStatusId"
							rules={[{ required: true, message: "Informe o Status" }]}>
							<Select defaultActiveFirstOption optionFilterProp="children" style={{ width: '100%' }}>
								{status.map((followUpStatus: FollowUpStatus) => {
									if (!data && followUpStatus.exibirInserir) {
										return (
											<Option key={followUpStatus.id} value={followUpStatus.id}>
												<Tag color={followUpStatus.cor}>
													{followUpStatus.nome}
												</Tag>
											</Option>
										);
									}

									if (data && followUpStatus.exibirAlterar) {
										return (
											<Option key={followUpStatus.id} value={followUpStatus.id}>
												<Tag color={followUpStatus.cor}>
													{followUpStatus.nome}
												</Tag>
											</Option>
										);
									}
									return null;
								})}
							</Select>
						</FormItem>

						<FormItem label={<span>Data Previsão <TooltipForm descricao="Data prevista para conclusão" /></span>} name="dataPrevisao"
							rules={[{ required: false }]}>
							<DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
						</FormItem>
					</Col>

					<Col md={12}>
						<FormItem label="Descrição" name="descricao"
							rules={[{ required: true, message: "Informe o descrição" }]}>
							<TextArea style={{ height: '205px' }} />
						</FormItem>
						
						{data && (
							<FormItem label={<span>Data Conclusão <TooltipForm descricao="Data da conclusão" /></span>} name="dataConclusao"
								rules={[{ required: false }]}>
								<DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} disabled />
							</FormItem>
						)}						
					</Col>
				</Row>



				{!componentDisabled && (
					<div className="acoes">
						{onClose && (
							<Button id="teste" type='primary' className='button-default' icon={<FaBan />} onClick={onClose} >Cancelar</Button>
						)}
						<Button htmlType='submit' type='primary' className='button-success' icon={<FaCheck />} loading={loading}>Salvar</Button>
					</div>
				)}
			</Form>

			{componentDisabled && (
				<div className="acoes">
					{onClose && (
						<Button id="teste" type='primary' className='button-default' icon={<FaBan />} onClick={onClose} >Cancelar</Button>
					)}
				</div>
			)}
		</>
	)
}

export default FollowUpForm