import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Tag } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/lib/form/Form";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useCallback, useEffect, useState } from "react";
import { FaBan, FaCheck, FaPlus, FaTrash, FaUsers } from "react-icons/fa";
import { MdLocalHotel } from "react-icons/md";
import { HospedagemEntity } from "../../../../entities/HospedagemEntity";
import { HospedagemQuartoEntity } from "../../../../entities/HospedagemQuartoEntity";
import { HospedagemStatusEntity } from "../../../../entities/HospedagemStatusEntity";
import { Hotel } from "../../../../entities/Hotel";
import FormProps from "../../../../interfaces/FormProps";
import { HospedagemQuartoCommand } from "../../../../interfaces/hospedagem-quarto-command";
import { Result } from "../../../../interfaces/Result";
import InputFloat from "../../../../masks/InputFloat";
import { useApp } from "../../../../providers/AppProvider";
import HospedagemParticipante from "./HospedagemParticipanteForm";
import HospedagemQuartoInserirForm from "./HospedagemQuartoInserirForm";

const HospedagemDetalhes: React.FC<FormProps> = ({
    data, onSuccess, onClose    
}) => {

    const app = useApp();
    const [loading, setLoading] = useState(false);
    const [loadingQuarto, setLoadingQuarto] = useState(false);
    const [form] = useForm();
    //const [formDetalhes] = useForm();
    const { Option, OptGroup } = Select;
	const { TextArea } = Input;
    const [hoteis, setHoteis] = useState<Hotel[]>([]);
	const [status, setStatus] = useState<HospedagemStatusEntity[]>([]);
    const [quarto, setQuarto] = useState<HospedagemQuartoEntity | null>(null);
    const [modalQuarto, setModalQuarto] = useState(false);
    const [modalHospParticipante, setModaHospParticipante] = useState(false);    
    const [hospedagemQuartos, setHospedagemQuartos] = useState<HospedagemQuartoEntity[]>([]);
    const [quartosTbl, setQuartosTbl] = useState<HospedagemQuartoCommand[]>([]);

    const TabelaQuartos: ColumnsType<HospedagemQuartoEntity> = [				
		{
			title:"Nº do Quarto",
			dataIndex: "numero"
		},
		{
			title:"Tipo de Quarto",
			dataIndex: ["hospedagemQuartoTipo", "nome"]
		},		
		{
			render: ((quarto: HospedagemQuartoEntity) => (
				<>
					<Popconfirm placement='topLeft'
						title={<span>Você confirma a EXCLUSÃO do quarto?<br /><strong>{quarto.hospedagemQuartoTipo.nome}</strong></span>}
						onConfirm={() => excluirQuarto(quarto)}>
						<Button size='small' type='primary' danger className='button-icon'><FaTrash />Excluir</Button>
					</Popconfirm>		
                    <Button className='button-warning' icon={<FaUsers />} onClick={() => hospedarPrticipante(quarto) }>Participantes</Button>			
				</>
			))
		}
	];

    const carregarHospedagem = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `hospedagem/${data.id}`);
		if (result?.code === 200) {	                         
            form.setFieldsValue(result.dados);   
			setHospedagemQuartos(result.dados.hospedagemQuartos);   
		}
	}, [app, data.id, form]);

    const adicionarQuartos = useCallback(async () => {                
        setLoadingQuarto(true);  
        quartosTbl.forEach(async function(item){            
            for (let i = 0; i < item.quantidade; i++) {
                let auxQuarto = {
                    HospedagemId: data.id,
                    HospedagemQuartoTipoId: item.hospedagemQuartoTipo.id
                };     
    
                let result: Result | null = await app.ajaxApi("POST", "hospedagem/quarto/NovoQuarto", auxQuarto);			
                if (result?.code === 200) {                
                        setLoadingQuarto(false);                			
                } else {
                    setLoadingQuarto(false);
                }
                
                carregarHospedagem();
                setModalQuarto(false);
            }
        });          
    } , [app, carregarHospedagem, data.id, quartosTbl]);

    const atualizaQuartosTbl = useCallback((itens: HospedagemQuartoCommand[]) => {        
       setQuartosTbl(itens);      
    } , []);

    const excluirQuarto = useCallback(async (quarto: HospedagemQuartoEntity) => {           
        setLoading(true);
        let result: Result | null = await app.ajaxApi("DELETE", `hospedagem/quarto/${quarto.id}`);
        if (result?.code === 200) {
            carregarHospedagem();
            if (onSuccess)
				onSuccess(result.dados);
        } 
        setLoading(false);
    }, [app, carregarHospedagem, onSuccess])   

    const hospedarPrticipante = useCallback((quarto: HospedagemQuartoEntity) => {        
        setQuarto(quarto);
        setModaHospParticipante(true);
    }, [])

    const salvar = useCallback(async (formData: any) => {
		setLoading(true);
		let result: Result | null;        

    	result = await app.ajaxApi("PUT", `hospedagem/${data.id}`, formData);
		if (result?.code === 200) {
			if (onSuccess)
				onSuccess(result.dados);

			if (onClose)
				onClose();
		}
		setLoading(false);
	}, [app, onClose, onSuccess, data]);

    const carregarHospedagemStatus = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `hospedagem/status`);
		if (result?.code === 200) {
			setStatus(result.dados);

			if (!data) {
				let statusList: HospedagemStatusEntity[] = result.dados;
				let opcoes: string[] = [];				
				if (opcoes.length > 0)
					form.setFieldsValue({ hospedagemStatusId: opcoes[0] });
			}
		}
	}, [app, data, form]);

	const carregarHoteis = useCallback(async () => {
		let result: Result | null = await app.ajaxApi("GET", `hotel/listar`);
		if (result?.code === 200) {
			setHoteis(result.dados);
		}
	}, [app]);

   
    
    // const carregaTblQuarto = useCallback((itens: HospedagemQuartoCommand[]) => {           
    //     let numPessoas : number = 0;
    //     let numQuartos : number = 0;
        
    //     itens.forEach(function(quarto) {            
    //         numQuartos += quarto.quantidade;
    //         numPessoas += (quarto.quantidade * quarto.hospedagemQuartoTipo.numPessoas);
    //     });
        
    //     var auxData = {
    //         numPessoas: numPessoas,
    //         numQuartos: numQuartos
    //     }

    //     form.setFieldsValue(auxData);    
    // }, [form]);
    
    // const atualizaQuartosTbl = useCallback((itens: HospedagemQuartoCommand[]) => {        
    //     carregaTblQuarto(itens);
    //     setQuartosTbl(itens);            
    // } , [carregaTblQuarto]);

    const submit = useCallback(() => {
        form.submit();
    }, [form]);

    useEffect(() => {
		carregarHospedagemStatus();
		carregarHoteis();        
        if (data) 
        {
            setHospedagemQuartos(data.hospedagemQuartos)
			form.setFieldsValue(data);        
        }
	}, [data, form, carregarHoteis, carregarHospedagemStatus]);


	return (
		<>
        <h2>
            <MdLocalHotel />
            Detalhes da Hospedagem
        </h2>     

        <Form form={form} layout="vertical" onFinish={salvar} >
            <Row gutter={25}>
                <Col md={12}>
                    <FormItem label="Nome Hospedagem" name="nomeHospedagem"
                        rules={[{ required: true, message: "Informe o nome da hospedagem" }]}>
                        <Input />
                    </FormItem>
                    <FormItem label="Hotel" name="hotelId"
                        rules={[{ required: true, message: "Informe o hotel" }]}>
                        <Select style={{ width: '100%' }}>
                            <OptGroup label="Hotel">
                                {hoteis.map((hotel: Hotel) => (
                                    <Option key={hotel.id} value={hotel.id}>{hotel.nome}</Option>
                                ))}
                            </OptGroup>                            
                        </Select>
                    </FormItem>

                    <FormItem label="Status" name="hospedagemStatusId"
                        rules={[{ required: true, message: "Informe o Status da hospedagem" }]}>
                        <Select style={{ width: '100%' }}>                        
                            <OptGroup label="Hotel">
                                {status.map((status: HospedagemStatusEntity) => (
                                    <Option key={status.id} value={status.id}>
                                        <Tag color={status.cor}>
                                            {status.nome}                                                
                                        </Tag>
                                    </Option>
                                ))}
                            </OptGroup>                            
                        </Select>
                    </FormItem>
                </Col>

                <Col md={12}>
                    <FormItem label="Observação" name="observacao"
                        rules={[{ required: true, message: "Informe o observação" }]}>
                        <TextArea style={{ height: '205px' }} />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={25}>
                <Col md={8}>
                <FormItem label="Valor" name="valor">
                    <InputFloat />
                </FormItem>
                </Col>
                <Col md={8}>
                    <FormItem label="Nº Pessoas" name="numPessoas">
                        <InputNumber style={{width: '100%'}} disabled />
                    </FormItem>
                </Col>
                <Col md={8}>
                    <FormItem label="Nº Quartos" name="numQuartos">
                        <InputNumber style={{width: '100%'}} disabled />
                    </FormItem>
                </Col>
            </Row>

            <h2>
                <MdLocalHotel />
                Lista de Quartos da Hospedagem
            </h2>
            <Table size='small' pagination={{ pageSize: 100 }} columns={TabelaQuartos} dataSource={hospedagemQuartos}></Table>

            <div className="submenu">	            
                <Button type='primary' className='button-primary' icon={<FaPlus />} onClick={() => setModalQuarto(true)}>Adicionar Quarto</Button>                      							                                                                        
                <Button onClick={submit} type='primary' className='button-success' icon={<FaCheck />} loading={loading}>Salvar</Button>							                    
            </div>
        </Form>   

        <Modal width={1000} open={modalQuarto} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModalQuarto(false)}>
            <h2>
                <FaPlus />
                Inserir novos quartos na hospedagem
            </h2>
            <Form layout="vertical">             
                <HospedagemQuartoInserirForm onSuccess={atualizaQuartosTbl}/>
                <div className="acoes">
                    <Button type='primary' className='button-success' icon={<FaCheck />} loading={loadingQuarto} onClick={adicionarQuartos}>Adicionar</Button>							                    
                </div>
            </Form>
        </Modal>	

        <Modal width={1000} open={modalHospParticipante} footer={false} closable destroyOnClose maskClosable={false} onCancel={() => setModaHospParticipante(false)}>
            <HospedagemParticipante data={quarto} onClose={() => setModaHospParticipante(false)} onSuccess={carregarHospedagem} />
        </Modal>
			
    </>
	);
};

export default HospedagemDetalhes;
