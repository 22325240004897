import { Alert, Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useCallback, useState } from 'react';
import { FaCheck, FaLock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BoxError from '../../../../components/BoxError';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';



const EsqueciMinhaSenha: React.FC = () => {


	const app = useApp();
	const [form] = useForm();
	const [loading, setLoading] = useState(false);
	const [enviado, setEnviado] = useState(false);
	const [error, setError] = useState<Result | null>(null);

	const submit = useCallback(async (formData: any) =>	 {
		setError(null);
		setLoading(true);
		setEnviado(false);
		
		let result: Result | null = await app.ajaxApi("post", "auth/solicita-senha", formData, false);
		if (result?.code !== 200) 
			setError(result);
		else {
			setEnviado(true);
			form.resetFields();
		}

		setLoading(false);
	}, [app, form]);


	return (
		<>
			<h1>
				<FaLock />
				Esqueci minha senha
			</h1>

			<BoxError result={error} />
			
			{enviado && (
				<Alert style={{ marginBottom: '20px' }} message="Email de recuperação de senha enviado" description="Acesse seu e-mail e siga os passos para a criação de uma nova senha" type='success' showIcon  />
			)}
			
			<Form form={form} layout='vertical' onFinish={submit}>
				<FormItem label="E-mail de acesso" name="email" rules={[
					{ required: true, message: "Informe seu e-mail de acesso" }
				]}>
					<Input />
				</FormItem>

				<div className="acoes" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Link to="/">Voltar para login</Link>
					<Button type='primary' icon={<FaCheck />} htmlType="submit" loading={loading}>Enviar</Button>
				</div>
			</Form>
		</>
	);
}

export default EsqueciMinhaSenha;