import React from 'react';

interface ListErrorProps {
	errors: any[] | string
}

const ListError: React.FC<ListErrorProps> = ({
	errors
}) => {
	
	if (typeof errors === "string") {
		return (	  
			<ul style={{ margin:0, paddingLeft: '10px' }}>	 
			  <li>{errors}</li>
			</ul>
		);		
	}

  return (	  
	  <ul style={{ margin:0, paddingLeft: '10px' }}>	  
		{errors.map((err: any, i: number) => (
			<li key={i}>{err.texto}</li>
		))}
	  </ul>
  );
}
export default ListError;