import { Tag } from 'antd';
import React from 'react';
import { Aereo } from '../entities/aereo';

interface AereoStatusProps {
	aereo: Aereo
}

const AereoStatus: React.FC<AereoStatusProps> = ({
	aereo
}) => {
	return (
		<>
			{aereo.aereoStatusId === 1 && <Tag color="warning">{aereo.aereoStatusNome}</Tag>}
			{aereo.aereoStatusId === 2 && <Tag color="green">{aereo.aereoStatusNome}</Tag>}
			{aereo.aereoStatusId === 3 && <Tag color="red">{aereo.aereoStatusNome}</Tag>}
			{aereo.aereoStatusId === 10 && <Tag>{aereo.aereoStatusNome}</Tag>}
		</>
	);
}

export default AereoStatus;