import { Input } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { InputCustomProps } from '../interfaces/InputCustomProps';

import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

const InputCpf: React.FC<InputCustomProps> = ({
	value,
	onChange,
	disabled = false
}) => {

	useEffect(() => {		
		$(".maskCpf").mask("000.000.000-00");
	}, []);


	const atualiza = useCallback((e: any) => {
		if (onChange)
			onChange(e.target.value);
			
	}, [onChange]);



	return (
		<>
			<Input disabled={disabled} className='maskCpf' onChange={(e) => atualiza(e)} onBlur={(e) => atualiza(e)} value={value} onKeyUp={(e) => atualiza(e)} />
		</>
	);
}

export default InputCpf;