import React from 'react';


const Proibido: React.FC = () => {
	return (
		<>
			<h1>Acesso Negado</h1>
		</>
	);
}

export default Proibido;