import { Select, Tag } from 'antd';
import React, { useCallback } from 'react';
import { InputCustomProps } from '../interfaces/InputCustomProps';

const SelectCor: React.FC<InputCustomProps> = ({
    value,
    onChange,
    disabled = false
}) => {
    const { Option } = Select;

    const atualiza = useCallback((e: any) => {        
        if (onChange)
            onChange(e);

    }, [onChange]);

    return (
        <>
            <Select style={{ width: '100%' }} disabled={disabled} onChange={(e) => atualiza(e)} value={value}>
				<Option value={""}><Tag>Default</Tag></Option>
                <Option value={"magenta"}><Tag color="magenta">Magenta</Tag></Option>
                <Option value={"red"}><Tag color="red">Red</Tag></Option>
                <Option value={"volcano"}><Tag color="volcano">Volcano</Tag></Option>
                <Option value={"orange"}><Tag color="orange">Orange</Tag></Option>
                <Option value={"gold"}><Tag color="gold">Gold</Tag></Option>
                <Option value={"lime"}><Tag color="lime">Lime</Tag></Option>
                <Option value={"green"}><Tag color="green">Green</Tag></Option>
                <Option value={"cyan"}><Tag color="cyan">Cyan</Tag></Option>
                <Option value={"blue"}><Tag color="blue">Blue</Tag></Option>
                <Option value={"geekblue"}><Tag color="geekblue">Geekblue</Tag></Option>
                <Option value={"purple"}><Tag color="purple">Purple</Tag></Option>
            </Select>
        </>
    );
}

export default SelectCor;