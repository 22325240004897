
import { Button, Modal } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import Table, { ColumnsType, ColumnType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa';
import { Campo } from '../../../entities/campo';
import { Participante } from '../../../entities/participante';
import { ParticipanteCampo } from '../../../entities/participante-campo';
import { ParticipanteCampoOpcao } from '../../../entities/participante-campo-opcao';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';
import ModalSenha from './ModalSenha';

interface ParticipanteExcluidoProps {
    onSuccess(): void,
    onClose?(): void
}

const ParticipanteExcluido: React.FC<ParticipanteExcluidoProps> = ({
    onSuccess,
    onClose
}) => {

    const app = useApp();
    const [modalExcluir, setModalExcluir] = useState(false);
    const [participantes, SetParticipantes] = useState<any[]>([]);
    const [participanteId, setParticipanteId] = useState<string>();
    const [url, setUrl] = useState<string>();
    const [tabelaParticipantes, setTabelaParticipantes] = useState<ColumnsType<Participante>>([]);

    let dadosBusca = {
        Busca: "",
        Ativo: "True"
    }

    const configuraTabela = useCallback(async () => {
        let result: Result | null = await app.ajaxApi("GET", 'campo/listar/participante', dadosBusca);
        let campos: Campo[] = result?.dados;

        let tabela: ColumnsType<any> = [
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "E-mail",
                dataIndex: "email"
            }
        ];

        campos.forEach((campo: Campo) => {
            if (campo.visivelAdmin && !campo.oculto) {
                tabela.push({
                    title: campo.nome,
                    dataIndex: campo.id
                })
            }
        });

        tabela.push({
            render: ((item: any) => (
                <>
                    <Button type='primary' danger className='button-icon' onClick={() => confirmaExclusao(item.key)}><FaTrash /></Button>
                </>
            )
            )
        });

        setTabelaParticipantes(tabela);

    }, [app])

    const carregaParticipantes = useCallback(async () => {        
        let result: Result | null = await app.ajaxApi("POST", "participante/listar", dadosBusca);
        let part: Participante[] = result?.dados;

        let dadosTabela: any[] = [];
        part.forEach((p: Participante) => {
            let item: any = {};

            item["key"] = p.id;
            item["nome"] = p.cadastro.nome;
            item["email"] = p.cadastro.email;
            p.participanteCampos.forEach((participanteCampo: ParticipanteCampo) => {
                if (participanteCampo.valor) {
                    item[participanteCampo.campoId] = participanteCampo.valor
                } else {
                    let valor: string = "";
                    participanteCampo.participanteCampoOpcoes.forEach((opcao: ParticipanteCampoOpcao, index: number) => {
                        valor += opcao.campoOpcao.nome;
                        if (participanteCampo.participanteCampoOpcoes.length > index + 1)
                            valor += " | ";
                    });
                    item[participanteCampo.campoId] = valor;
                }
            });

            dadosTabela.push(item);
        });

        SetParticipantes(dadosTabela);
    }, [app]);

    const confirmaExclusao = ((id: string) => {        
        setParticipanteId(id);
        setUrl("participante/excluirPermanente");
        setModalExcluir(true);
    })

    const loadPage = useCallback(async () => {
        // Define configuração da tabela
        await configuraTabela();
        carregaParticipantes();
    }, [carregaParticipantes, configuraTabela]);

    useEffect(() => {
        loadPage();
    }, [loadPage]);

    return (
        <>
            <h2>
                <FaTrash />
                Excluír Participante Permanentemente
            </h2>

            <Table size='small' columns={tabelaParticipantes} dataSource={participantes} />

            <Modal width={500} closable maskClosable={false} footer={false} destroyOnClose open={modalExcluir} onCancel={() => setModalExcluir(false)}>
                <ModalSenha id={participanteId} url={url} onClose={() => setModalExcluir(false)} onSuccess={loadPage}></ModalSenha>
            </Modal>
        </>
    )

}

export default ParticipanteExcluido