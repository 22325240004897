import { Button, Checkbox, Col, Form, notification, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useCallback, useEffect, useState } from 'react';
import { FaCheck, FaLock } from 'react-icons/fa';
import { Acao } from '../../../entities/acao';
import { Admin } from '../../../entities/admin';
import { AdminAcao } from '../../../entities/admin-acao';
import { Modulo } from '../../../entities/modulo';
import { Sessao } from '../../../entities/sessao';
import { Result } from '../../../interfaces/Result';
import { useApp } from '../../../providers/AppProvider';

interface AdminPermissaoAcessoEditarProps {
	admin: Admin | null,
	onClose?(): void,
	onSuccess?(admin: Admin): void
}

const AdminPermissaoAcessoEditar: React.FC<AdminPermissaoAcessoEditarProps> = ({
	admin,
	onSuccess,
	onClose
}) => {

	const app = useApp();
	const [form] = useForm();

	const [modulos, setModulos] = useState<Modulo[]>([]);
	const [loading, setLoading] = useState(false);

	


	
	const carregaModulos = useCallback(async() => {
		let result: Result | null = await app.ajaxApi("GET", "modulo");
		let modulos: Modulo[] = result?.dados;
		
		if (result?.code === 200)
			setModulos(result.dados);
		
		let listModulos: any[] = [];

		modulos.forEach((modulo: Modulo) => {
			modulo.sessoes.forEach((sessao: Sessao) => {
				sessao.acoes.forEach((acao: any) => {
					let rs: AdminAcao | undefined = admin?.adminAcoes.find((acaoUser: AdminAcao) => {
						if (acaoUser.acaoId === acao.id)
							return true;

						return false;
					})
					
					listModulos[acao.id] = rs !== undefined; 
				});
			})
		});
		form.setFieldsValue(listModulos);

		console.log("EXECUTA AQUI");
	}, [app, form, admin]);



	



	const salvar = useCallback(async(formData: any) => {
		setLoading(true);

		let permissoes: number[] = [];		
		Object.keys(formData).forEach((item: any) => {
			if (formData[item])
				permissoes.push(item);
		});
		let dadosForm = {
			acoes: permissoes
		};

		let result: Result | null = await app.ajaxApi("PUT", `admin/${admin?.id}/acao`, dadosForm);
		if (result?.code === 200) {
			notification.success({
				message: "Permissões atualizadas com sucesso",
				placement: 'bottomRight'				
			});

			if (onSuccess)
				onSuccess(result.dados);
		}

		setLoading(false);
		
	}, [app, admin, onSuccess]);




	useEffect(() => {		
		carregaModulos();
	}, [carregaModulos]);



	return (
		<>
			<h2>
				<FaLock />
				Permissões de Acesso
			</h2>

			<Form form={form} onFinish={salvar}>
				
				{modulos.map((modulo: Modulo) => (
					<div key={modulo.key} className="box">
						<h2>{modulo.nome}</h2>
						<Row gutter={25}>
							{modulo.sessoes.map((sessao: Sessao) => (
								<Col key={sessao.key} md={8}>
									<div style={{ marginBottom: '15px' }}>
										<h3>{sessao.nome}</h3>
										
										{sessao.acoes.map((acao: Acao) => (
											<FormItem key={acao.key} name={acao.id} valuePropName="checked" style={{ margin: 0 }}>
												<Checkbox>{acao.nome}</Checkbox>
											</FormItem>
										))}
									</div>
								</Col>
							))}	
						</Row>						
					</div>
				))}

				<div className="acoes">
					<Button className='button-success' type='primary' icon={<FaCheck />} htmlType='submit' loading={loading}>						
						Salvar Permissões
					</Button>
				</div>
				
			</Form>
		</>
	);
}

export default AdminPermissaoAcessoEditar;