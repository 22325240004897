import { Button, Col, Input, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import Form, { useForm } from "antd/lib/form/Form";
import React, { useCallback, useEffect, useState } from "react";
import { FaBan, FaCheck, FaEdit } from "react-icons/fa";
import { Hotel } from "../../../entities/Hotel";
import { Result } from "../../../interfaces/Result";
import InputCep from "../../../masks/InputCep";
import SelectEstados from "../../../masks/SelectEstados";
import { useApp } from "../../../providers/AppProvider";

interface HotelFormProps {
	data: Hotel | null;
	onSuccess?(hotel: Hotel): void;
	onClose?(): void;
}

const HotelForm: React.FC<HotelFormProps> = ({ data, onSuccess, onClose }) => {
	const app = useApp();
	const [form] = useForm();
	const [loading, setLoading] = useState(false);	

	const salvar = useCallback(
		async (formData: any) => {
			setLoading(true);
			let result: Result | null;

			// console.log(formData);
			if (data)
				result = await app.ajaxApi("PUT", `hotel/${data.id}`, formData);
			else result = await app.ajaxApi("POST", `hotel`, formData);

			if (result?.code === 200) {
				if (onSuccess) onSuccess(result.dados);

				if (onClose) onClose();
			}
			setLoading(false);
		},
		[app, onClose, onSuccess, data]
	);

	const dadosCep = useCallback(
		async (cep: any) => {
			if (cep) 
				form.setFieldsValue(cep);
		},
		[form]
	);

	useEffect(() => {
		if (data) {			
			form.setFieldsValue(data);
		}
	}, [form, data]);

	return (
		<>
			<h2>
				<FaEdit />
				Editar Hotel
			</h2>

			<Form form={form} layout="vertical" onFinish={salvar}>
				<Row gutter={25}>
					<Col md={24}>
						<FormItem
							label="Nome do Hotel"
							name="nome"
							rules={[
								{
									required: true,
									message: "Informe o nome do hotel",
								},
							]}
						>
							<Input maxLength={150} />
						</FormItem>
					</Col>

					{/* DADOS DO ENDEREÇO */}
					<Col md={4}>
						<FormItem label="CEP" name="cep">
							<InputCep callbackCep={dadosCep} />
						</FormItem>
					</Col>
					<Col md={16}>
						<FormItem label="Logradouro" name="logradouro">
							<Input maxLength={255} />
						</FormItem>
					</Col>
					<Col md={4}>
						<FormItem label="Nº" name="numero">
							<Input maxLength={50} />
						</FormItem>
					</Col>
					<Col md={24}>
						<FormItem label="Complemento" name="complemento">
							<Input maxLength={80} />
						</FormItem>
					</Col>

					<Col md={6}>
						<FormItem label="Bairro" name="bairro">
							<Input maxLength={255} />
						</FormItem>
					</Col>
					<Col md={6}>
						<FormItem label="Cidade" name="cidade">
							<Input maxLength={100} />
						</FormItem>
					</Col>
					<Col md={6}>
						<FormItem label="Estado" name="estado">
							<SelectEstados />
						</FormItem>
					</Col>
					<Col md={6}>
						<FormItem label="País" name="pais">
							<Input maxLength={50} />
						</FormItem>
					</Col>
				</Row>

				<div className="acoes">
					{onClose && (
						<Button type="primary" className="button-default" icon={<FaBan />} onClick={onClose}>Cancelar</Button>
					)}
					<Button htmlType="submit" type="primary" className="button-success" icon={<FaCheck />} loading={loading}>Salvar</Button>
				</div>
			</Form>
		</>
	);
};

export default HotelForm;
