import { Alert, Button, Col, Form, Input, Modal, Row, Tag } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React, { useCallback, useMemo, useState } from 'react';
import { FaArrowLeft, FaPlus, FaSearch } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { FollowUp } from '../../../../entities/follow-up';
import { Result } from '../../../../interfaces/Result';
import { useApp } from '../../../../providers/AppProvider';
import { GiTalk } from 'react-icons/gi';

import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { FollowUpInteracao } from '../../../../entities/follow-up-interacao';

import "./FollowUpDetalhes.css";
import FollowUpNovaInteracaoForm from '../../forms/FollowUp/FollowUpNovaInteracaoForm';
import { IoArchiveSharp } from 'react-icons/io5';
import FollowUpConcluirForm from '../../forms/FollowUp/FollowUpConcluirForm';

const FollowUpDetalhes: React.FC = () => {

	const app = useApp();
	const { id } = useParams();

	const [follow, setFollow] = useState<FollowUp | null>(null);
	const [modalInteracao, setModalInteracao] = useState(false);
	const [modalConcluir, setModalConcluir] = useState(false);


	
	useMemo(async() => {
		let result: Result | null = await app.ajaxApi("get", `follow-up/${id}`);
		if (result)
			setFollow(result.dados);

		console.log(result);
	}, [app, id]);


	const interacaoSuccess = useCallback(async (followUp: FollowUp) => {
		let result: Result | null = await app.ajaxApi("get", `follow-up/${id}`);
		if (result)
			setFollow(result.dados);
	}, [app, id]);

	const concluirSuccess = useCallback(async (followUp: FollowUp) => {
		let result: Result | null = await app.ajaxApi("get", `follow-up/${id}`);
		if (result)
			setFollow(result.dados);
	}, [app, id]);


	return (
		<>
			<h1>
				<FaSearch />
				Detalhes do Follow Up
			</h1>

			<div className="submenu">
				<Button onClick={() => app.goBack()} icon={<FaArrowLeft />}>Voltar</Button>

				<div>
					{!follow?.followUpStatus.concluir && (
						<Button onClick={() => setModalConcluir(true)} className='button-success' type='primary' icon={<IoArchiveSharp />}>Concluir</Button>
					)}
				</div>
			</div>

			<div className="box">
				<Form layout='vertical'>
					<Row gutter={25}>
						<Col md={12}>
							<FormItem label="Título">
								<Input value={follow?.titulo} readOnly />
							</FormItem>

							<FormItem label="Solicitante">
								<Input value={follow?.solicitante.nome} readOnly />
							</FormItem>

							<FormItem label="Responsável">
								<Input value={follow?.cadastroResponsavel.nome} readOnly />
							</FormItem>

							<FormItem label="Dia / Hora da soliticação">
								<Input value={follow?.created ? moment(follow.created).format("DD/MM/YYYY - HH:mm:ss") : ``} readOnly />
							</FormItem>

							<Row>
								<Col md={12}>
									<FormItem label="Status">
										<Tag style={{ fontSize: 18, padding: '7px 20px' }} color={follow?.followUpStatus.cor}>{follow?.followUpStatus.nome}</Tag>
									</FormItem>
								</Col>

								{follow?.dataConclusao && (
									<Col md={12}>
										<FormItem label="Data de Conclusão">
											<Input value={moment(follow?.dataConclusao).format("DD/MM/YYYY - HH:mm:ss")} />
										</FormItem>
									</Col>
								)}
							</Row>
							
						</Col>

						<Col md={12}>
							<FormItem label="Descrição">
								<TextArea style={{ height: 267 }} value={follow?.descricao} readOnly />
							</FormItem>

							<FormItem label="Previsão de Entrega">
								{follow?.dataPrevisao ? (
									<Alert showIcon type='warning' message={(
										<>
											<strong>{moment(follow.dataPrevisao).format("DD/MM/YYYY")}</strong>
											<i style={{ marginLeft: 7 }}>{moment(follow.dataPrevisao).fromNow()}</i>
										</>
									)} />
								) : (
									<Alert showIcon type='info' message="Não informada" />
								)}
								
							</FormItem>
						</Col>
					</Row>					
				</Form>
			</div>
			
			<div className="box">
				<h1>
					<GiTalk />
					Interações
				</h1>

				{!follow?.followUpStatus.concluir && (
					<div className="submenu">
						<Button onClick={() => setModalInteracao(true)} className='button-success' type='primary' icon={<FaPlus />}>Nova Interação</Button>
					</div>
				)}				

				{(follow?.interacoes && follow.interacoes.length > 0) ? (
					<>
						{follow.interacoes.map((interacao: FollowUpInteracao) => (
							<div className='interacao' key={interacao.id}>
								<div className="info">
									<h3>{interacao.remetente.nome}</h3>
									<p>
										{moment(interacao.created).format("DD/MM/YYYY - HH:mm:ss")}
										<i>{moment(interacao.created).fromNow()}</i>
									</p>
								</div>
								
								<p>{interacao.descricao}</p>
							</div>
						))}
					</>
				) : (
					<div className="box-vazio">Nenhuma interação cadastrada</div>
				)}
			</div>
			

			<Modal open={modalInteracao} destroyOnClose closable footer={false} onCancel={() => setModalInteracao(false)}>
				<FollowUpNovaInteracaoForm data={follow} onSuccess={interacaoSuccess} onClose={() => setModalInteracao(false)} />
			</Modal>

			<Modal open={modalConcluir} destroyOnClose closable footer={false} onCancel={() => setModalConcluir(false)}>
				<FollowUpConcluirForm data={follow} onSuccess={concluirSuccess} onClose={() => setModalConcluir(false)} />
			</Modal>
		</>
	);
}

export default FollowUpDetalhes;